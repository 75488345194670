<template>
  <div class="home">
    <div
      v-if="roleId == 3 || roleId == 8"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <v-container fluid class="bg-white mt-65" v-else >
      <v-row class="ml-2 mt-2">
        <h2>Customer Title Status</h2>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0 ml-1 pt-7">
          <div>
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                  dense
                  outlined
                  :items="[20, 50, 75, 100]"
                  v-model="pagination.per_page"
                  :label="`Showing ${pagination.per_page} Items`"
                  @change="getData(1)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="filters.report_global_search"
                  @keyup.enter.native="search"
                  label="CUSTOMER ID, CUSTOMER NAME, USER EMAIL, COMPANY NAME, CITY, STATE, COUNTRY, TAX"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn :loading="filtering" color="primary" @click="search">
                  Search
                </v-btn>
                <v-btn
                  :loading="filtering || downloading"
                  color="primary"
                  @click="exportData"
                  class="ml-5"
                >
                  Export
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" class="mt-2 text-right">
                <h5>
                  Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                  {{ this.showingTo ? this.showingTo : "0" }} of
                  {{ this.count ? this.count : "0" }}
                </h5>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-simple-table class="mt-10" v-if="!loading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">CUSTOMER</th>
              <th class="text-left">CUSTOMER User ID</th>
              <th class="text-left">ON THE WAY</th>
              <th class="text-left">ON HAND</th>
              <th class="text-left">NO TITLE</th>
              <th class="text-left">EXPORTABLE</th>
              <th class="text-left">PENDING</th>
              <th class="text-left">BOS</th>
              <th class="text-left">LIEN</th>
              <th class="text-left">REJECTED</th>
              <th class="text-left">MV907</th>
              <th class="text-left">LOAD STATUS</th>
              <th class="text-left">LOADING INSTRUCTION</th>
              <th class="text-left">ALL VEHICLES</th>
              <th v-if="roleId !== 3" class="text-left">ALL CONTAINERS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report" :key="index">
              <td>{{ item.customer_name }}</td>
              <td>{{ item.legacy_customer_id }}</td>
              <td>{{ item.on_the_way }}</td>
              <td>{{ item.on_hand }}</td>
              <td>{{ item.no_title }}</td>
              <td>{{ item.exportable }}</td>
              <td>{{ item.pending }}</td>
              <td>{{ item.bos }}</td>
              <td>{{ item.lien }}</td>
              <td>{{ item.rejected }}</td>
              <td>{{ item.mv907 }}</td>
              <td>{{ item.load_status }}</td>
              <td>{{ item.loading_instructions }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Vehicles',
                    query: { customer_name: item.user_id },
                  }"
                >
                  Visit
                </router-link>
              </td>
              <td v-if="roleId !== 3">
                <router-link
                  :to="{
                    name: 'exports',
                    query: { customer_name: item.user_id },
                  }"
                >
                  Visit
                </router-link>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
import downloadFile from "@/helpers/downloadFile";
export default {
  components: {
    VueContentLoading,
  },
  data() {
    return {
      roleId: null,
      report: {},
      loadingCustomar: false,
      customarUserId: "",
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      filters: {
        report_global_search: "",
      },
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      userId: JSON.parse(localStorage.getItem("user")).id,
      downloading : false,
    };
  },
  methods: {
    getData(page) {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }

      this.filters.page = page;
      this.filters.limit = this.pagination.per_page;

      var filter = new URLSearchParams(this.filters).toString();

      let finalUrl = this.$route.path + `?` + filter;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/reports/customer-title-status?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    getDataWithFilter(filter) {
      Api.get(`/reports/customer-title-status?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      this.getData(1);
    },
    loadingManage (value) {
      this.downloading = value;
    },
    exportData() {
      this.loadingManage(true);
      // window.location =
      //   Api.defaults.baseURL +
      //   `/reports/customer-title-status/export?report_global_search=${this.filters.report_global_search}&auth_user_id=${this.userId}`;

      const url = Api.defaults.baseURL + `/reports/customer-title-status/export?report_global_search=${this.filters.report_global_search}&auth_user_id=${this.userId}`;

      downloadFile(url,
          'excel_export',
          '.xls',
          this.loadingManage
      );
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
  },
  mounted() {
    this.roleId = parseInt(JSON.parse(localStorage.getItem("user")).role);
    // this.getData();

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.$route.query.customer_user_id) {
        this.customarUserId = this.$route.query.customer_user_id;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
