import Customers from '@/views/customers/Customers';
import Customer from '@/views/customers/Customer';
import ShowCustomer from '@/views/customers/ShowCustomer';
import Profile from '@/views/customers/Profile'
export default [
    {
        path: '/customers',
        gate: 'get customers',
        name: 'Customers',
        component: Customers,
        meta: {
            title: "Customers",
            requiresAuth: true,
            permissionName:'customers.index'
        }
    },
    {
        path: "/customers/:id",
        name: "customer.overview",
        component: Customer,
        meta: {
            title: "Customer Details",
            requiresAuth: true,
            permissionName:'customers.view'
        }
    },
    {
        path: "/profile",
        name: "customer.profils",
        component: Profile,
        meta: {
            title: "Profile Details",
            requiresAuth: true
        }
    },
    {
        path: "/show-customers",
        name: "show-customer.overview",
        component: ShowCustomer,
        meta: {
            title: "Customer Details",
            requiresAuth: true,
            permissionName:'customers.view'
        }
    },
]