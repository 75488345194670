<template>
  <div>
    <div
      v-if="isAllow == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div class="vehicle pb-10" v-else>
      <breadcrumbs />
      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Customer Details</h3>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Customer ID
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.legacy_customer_id }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Customer Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.customer_name ? customer.customer_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Company Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.company_name ? customer.company_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Email
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.email ? customer.email : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Status
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <v-chip
                  color="green"
                  text-color="white"
                  small
                  v-if="customer.status_name == 'Active'"
                >
                  Active
                </v-chip>
                <v-chip color="red" text-color="white" small v-else>
                  Inactive
                </v-chip>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Address 1
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.address_line_1 ? customer.address_line_1 : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Address 2
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.address_line_2 ? customer.address_line_2 : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Phone USA
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.phone ? customer.phone : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Phone UAE
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.phone_two ? customer.phone_two : "N/A" }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                City
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.city ? customer.city : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                State
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.state ? customer.state : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Zip Code
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.zip_code ? customer.zip_code : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Country
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.country ? customer.country : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                TRN
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.trn_uae ? customer.trn : "N/A" }}
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                FAX
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.fax ? customer.fax : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Other emails
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.other_emails ? customer.other_emails : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Note
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ customer.note ? customer.note : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col
                cols="12"
                sm="6"
                class="pa-3 font-weight-bold"
                style="color: blue"
              >
                Loading Type
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pa-3 second-column"
                style="color: blue"
              >
                {{
                  customer.loading_type_title
                    ? customer.loading_type_title
                    : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="px-0">
        <consign-details
          v-if="customer.consignees"
          :consignee="customer.consignees"
          :vehicles="customer.vehicles_count"
          :exports="customer.exports_count"
          :customer="customer.user_id"
          :invoice="invoice_overview"
        />
      </v-container>
      <div class="vehicle mt-10" v-if="!loading">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row align-center"
        >
          <h3 class="primetime">Customer Documents</h3>
          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              outlined
              v-if="
                customer.customer_documents &&
                customer.customer_documents.length
              "
              @click="downloadAttachment"
              class="mr-3 download-btn"
              color="blue"
              :loading="downloading"
              ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download Attachments
            </v-btn>
          </div>
        </v-container>
        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-for="(item, index) in customer.customer_documents"
              :key="index"
            >
              <v-img
                v-if="item.type === 'pdf'"
                class="img-border grey lighten-2"
                :src="`${baseurls}/images/pdf_thumbnail.jpg`"
                aspect-ratio="2"
              ></v-img>
              <v-img
                v-else
                class="img-border grey lighten-2"
                :src="`${item.url}`"
                aspect-ratio="2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <a
                style="text-decoration: underline"
                target="_blank"
                :href="item.url"
                >download</a
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import Breadcrumbs from "@/views/customers/Breadcrumbs.vue";
import ConsignDetails from "../../components/customer/ConsignDetails.vue";
const _token = "Bearer " + localStorage.getItem("authtoken");
import downloadFile from "@/helpers/downloadFile";
export default {
  name: "Customer",
  components: {
    Breadcrumbs,
    ConsignDetails,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      userId: JSON.parse(localStorage.getItem("user")).id,
      customerId: null,
      isAllow: JSON.parse(localStorage.getItem("user")).role === 3,
      customer: {},
      status_overview: {},
      invoice_overview: {},
      loading: false,
      formShow: false,
      images: [],
      currentPage: 1,
      report: {},
      locations: [],
      locationsLoading: false,
      location_id: null,
      locationColors: [
        "#ff00ff",
        "#808080",
        "#800000",
        "#808000",
        "#00FF00",
        "#000000",
        "#00FFFF",
        "#008080",
        "#800080",
      ],
      dropzoneOptions: {
        // url: null,
        url: Api.defaults.baseURL + "/file-upload",
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },
      dialog: false,
      form: {
        fileUrls: [],
      },
      uploading_document: false,
      downloading : false,
    };
  },
  methods: {
    show() {
      this.loading = true;
      this.errors = {};
      Api.get(`/customers/${this.userId}`)
        .then((res) => {
          this.customer = res.data.data;
          this.report = res.data.data;

          this.images = res.data.data.customer_documents;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.loading = false;
            this.$toastr.e("Failed to load data! " + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1 || 1;
    },
    showVehiclesPage(status) {
      this.$router.push({
        name: "Vehicles",
        query: {
          location: this.location_id,
          status: status,
          customer_user_id: this.customer.user_id,
        },
      });
    },
    filter(location_id) {
      this.location_id = location_id;
      this.getStatusOverview();
      this.getInvoiceOverview();
    },

    edit(id) {
      this.loading = true;
      this.$refs.manage.edit(id);
      this.formShow = true;
      this.loading = false;
    },

    getStatusOverview() {
      this.locationsLoading = true;
      let url = `/status-overview?customer_id=${this.customerId}`;
      if (this.location_id) {
        url += `&location_id=${this.location_id}`;
      }
      Api.get(url)
        .then((res) => {
          this.status_overview = res.data;
          this.locationsLoading = false;
        })
        .catch((err) => {
          this.locationsLoading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },

    getInvoiceOverview() {
      this.locationsLoading = true;
      this.invoice_overview = {};
      let url = `/invoice-overview?customer_id=${this.customerId}`;
      if (this.location_id) {
        url += `&location_id=${this.location_id}`;
      }
      Api.get(url)
        .then((res) => {
          this.invoice_overview = res.data;
          this.locationsLoading = false;
        })
        .catch((err) => {
          this.locationsLoading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },

    getData() {
      let url = `/customers/${this.customerId}`;
      if (this.location_id) {
        url = `/customers/${this.customerId}?location_id=${this.location_id}`;
      }
      this.loading = true;
      Api.get(url)
        .then((res) => {
          this.customer = res.data.data;
          this.report = res.data;

          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },

    loadingManage (value) {
      this.downloading = value;
    },
    downloadAttachment() {
      this.loadingManage(true);
       const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      // window.open(
      //   `${baseUrl}/api/v1/customers/${this.$route.params.id}/download-documents`,
      //   "_blank"
      // );
      downloadFile(
          `${baseUrl}/api/v1/customers/${this.$route.params.id}/download-documents`,
          'customer_document',
          '.zip',
          this.loadingManage
      );
    },

    removeAllFiles() {
      this.form.fileUrls = [];
      if (this.$refs.vueDropzone) {
        this.$refs.vueDropzone.removeAllFiles();
      }
    },
    sendingEvent(file, xhr, formData) {
      this.uploading_document = true;
    },
    storeSuccess(file, response) {
      this.loading = false;
      this.uploading_document = false;
      this.form.fileUrls.push(response.data);
    },
    storeError(file, message, xhr) {
      this.uploading_document = false;
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }
      this.$toastr.e("Failed to create data! " + message.message);
    },
    removeFile(file, error, xhr) {},

    saveImage() {
      this.loading = true;
      this.errors = {};
      Api.post(
        `/customers/${this.$route.params.id}/add-more-document`,
        this.form
      )
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.removeAllFiles();
          this.getData();
        })
        .catch((err) => {
          this.removeAllFiles();
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to create data! " + err);
        });
    },
  },

  computed: {
    toBeShown() {
      return this.images.slice(0, this.currentPage * 4);
    },
    totalPages() {
      return Math.ceil(this.images.length / 4);
    },
    baseurls() {
      return process.env.VUE_APP_API_ENDPOINT;
    },
  },

  mounted() {
    this.getInvoiceOverview();
    this.getStatusOverview();
    this.getData();

    this.locations = this.$store.state.auth.locations;
  },

  created() {
    this.customerId = JSON.parse(localStorage.getItem("user")).id;
    this.form.customerId = JSON.parse(localStorage.getItem("user")).id;
  },
};
</script>

<style scoped>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
</style>
