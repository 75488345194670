<template>
  <div>
    <div
      v-if="permissions['cities.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex">
          <h1 class="primetime">City</h1>
          <div class="ml-auto mr-4">
            <city-manage
              @dataUpdated="getData()"
              :id="editId"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </div>

          <!-- <v-col  cols="12" md="2">
           </v-col> -->
        </div>
        <v-row>
          <v-col cols="12" md="12" class="text-right mt-2">
            <h5>
              Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
              {{ this.showingTo ? this.showingTo : "0" }} of
              {{ this.count ? this.count : "0" }}
            </h5>
          </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />
        <v-simple-table class="mt-4" v-if="locations.data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Name</th>
                <th class="text-left">Short code</th>
                <th class="text-left">State</th>
                <th class="text-left">Status</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <v-text-field
                    v-model="filters.name"
                    @keypress.enter="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.short_code"
                    @keypress.enter="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td class="py-2">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="filters.state_id"
                    :items="stateName.data"
                    item-text="name"
                    item-value="id"
                    autocomplete="none"
                    hide-no-data
                    hide-selected
                    style="margin-bottom: -25px"
                    :search-input.sync="customarUserId"
                    :loading="loading"
                  ></v-autocomplete>
                </td>
                <td>
                  <v-select
                    :items="statusFilter"
                    item-text="text"
                    item-value="id"
                    v-model="filters.status"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="item in locations.data" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.short_code }}</td>
                <td>{{ item.state_name }}</td>
                <td>
                  <v-chip v-if="item.status" color="success" small
                    >Active</v-chip
                  >
                  <v-chip v-else color="warning" small>Inactive</v-chip>
                </td>
                <td>
                  <div v-if="permissions['cities.view'] == false"></div>
                  <v-btn v-else text :to="`/settings/citys/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <div v-if="permissions['cities.update'] == false"></div>
                  <v-btn v-else text @click="edit(item.id)" color="blue">
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <div v-if="permissions['cities.destroy'] == false"></div>
                  <v-btn v-else text @click="destroy(item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <vue-content-loading :width="300" :height="100" v-else>
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import cityManage from "@/components/settings/cityManage.vue";
import Api from "@/services/Api";
import axios from "axios";
import { VueContentLoading } from "vue-content-loading";

export default {
  name: "locations",
  components: {
    Confirmation,
    cityManage,
    VueContentLoading,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      customarUserId: "",
      deleteConfirmationShow: false,
      locations: {},
      filters: {},
      stateName: [],
      pagination: {
        current: 1,
        total: 0,
      },
      statusFilter: [
        { id: 0, text: "Inactive" },
        { id: 1, text: "Active" },
      ],
      filtering: false,
      loading: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      AuthStr: "Bearer " + localStorage.getItem("authtoken"),
      // eslint-disable-next-line no-undef
      baseDomain: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      let current_page = page;
      if (!current_page) {
        current_page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/settings/cities?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.locations = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });

      this.editId = null;
      this.deletingId = null;
    },

    getDataWithFilter(finalSearchQuery) {
      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/settings/cities?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.locations = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });
    },
    remove() {
      Api.delete(`/settings/cities/${this.deletingId}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },
    manualSearch() {
      this.filters.page = 1;
      this.search();
    },

    clearFilter() {
      this.filters = {};
      this.getData(1);
    },
  },
  mounted() {
    // this.stateNameData()
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    // eslint-disable-next-line no-empty
    if (roleId === 0 || roleId === 1) {
    } else {
      this.$router.go(-1);
    }

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.filters.status) {
        this.filters.status = parseInt(this.filters.status);
      }
      if (this.filters.role) {
        this.filters.role = parseInt(this.filters.role);
      }
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
  watch: {
    customarUserId(newVal) {
      if (newVal) {
        this.loading = true;
        Api.get(`settings/states?name=${newVal}`)
          .then((res) => {
            this.loading = false;
            this.stateName = res.data;
            this.stateName.data.unshift({ name: "Select State" });
          })
          .catch((err) => {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
th {
  width: 227px !important;
}
</style>
