<template>
  <v-row class="">
    <v-dialog scrollable v-model="dialog" max-width="900px" persistent >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="permissions['customers.store'] == false"></div>
        <div class="" v-else>
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="newCustomer"
            v-if="!detailspage"
          >
            New customer
          </v-btn>
        </div>

      </template>
      <v-card>
        <v-card-title style="border-bottom:1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} customer</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="form.legacy_customer_id"
                    label="Customer ID"
                    outlined
                    dense
                    @keypress="onlyNumber"
                    :error-messages="errors.legacy_customer_id"
                ></v-text-field>

                <v-text-field
                  v-model="form.username"
                  label="User Name"
                  outlined
                  dense
                  :error-messages="errors.username"
                ></v-text-field>

                <v-text-field
                  v-model="form.phone"
                  label="Phone"
                  outlined
                  dense
                  type="number"
                  :error-messages="errors.phone"
                ></v-text-field>

                <v-text-field
                  v-model="form.trn"
                  label="TRN"
                  outlined
                  dense
                  :error-messages="errors.trn"
                ></v-text-field>

                <v-autocomplete
                  dense
                  outlined
                  hide-no-data
                  label="Country"
                  autocomplete="none"
                  @change="changeCountry"
                  v-model="form.country_id"
                  :search-input.sync="countryName"
                  :items="countryItem"
                  item-text="name"
                  item-value="id"
                  :loading="countryNameLoading"
                  :error-messages="errors.country_id"
                ></v-autocomplete>

                <v-select
                  item-text="name"
                  item-value="id"
                  @change="changeState"
                  :items="stateItem"
                  v-model="form.state_id"
                  outlined
                  dense
                  label="State"
                  :error-messages="errors.state_id"
                ></v-select>

                <v-select
                  item-text="name"
                  item-value="id"
                  :items="cityItem"
                  v-model="form.city_id"
                  outlined
                  dense
                  label="City"
                  :error-messages="errors.city_id"
                ></v-select>

                <v-text-field
                  v-model="form.other_emails"
                  label="Other emails"
                  outlined
                  dense
                  :error-messages="errors.other_emails"
                ></v-text-field>

                <v-select
                  item-text="title"
                  item-value="id"
                  :items="loading_types"
                  v-model="form.loading_type"
                  label="Loading type"
                  outlined
                  dense
                  :error-messages="errors.loading_type"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="form.customer_name"
                    label="Customer Name"
                    outlined
                    dense
                    :error-messages="errors.customer_name"
                ></v-text-field>

                <v-text-field
                  v-model="form.email"
                  label="Email"
                  outlined
                  dense
                  :error-messages="errors.email"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  label="Password"
                  :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  dense
                  :error-messages="errors.password"
                ></v-text-field>

                <v-text-field
                  v-model="form.company_name"
                  label="Company Name"
                  outlined
                  dense
                  :error-messages="errors.company_name"
                ></v-text-field>

                <v-text-field
                  v-model="form.phone_two"
                  label="Phone UAE"
                  outlined
                  dense
                  type="number"
                  :error-messages="errors.phone_two"
                ></v-text-field>

                <v-text-field
                  v-model="form.address_line_1"
                  outlined
                  dense
                  label="Address 1"
                  :error-messages="errors.address_line_1"
                ></v-text-field>

                <v-text-field
                  v-model="form.fax"
                  label="TAX"
                  outlined
                  dense
                  :error-messages="errors.fax"
                ></v-text-field>

                <v-text-field
                  v-model="form.address_line_2"
                  label="Address 2"
                  outlined
                  dense
                  :error-messages="errors.address_line_2"
                ></v-text-field>

                <v-text-field
                  v-model="form.zip_code"
                  label="ZIP Code"
                  outlined
                  dense
                  :error-messages="errors.zip_code"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea
                  v-model="form.note"
                  label="Note"
                  outlined
                  dense
                  :error-messages="errors.note"
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="12">
                <div>Customer documents</div>

                <vue-dropzone
                  ref="customerDocuments"
                  id="customerDocuments"
                  @vdropzone-removed-file="removeCustomerDocument"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-success="storeArrivedSuccess"
                  @vdropzone-error="storeError"
                  :options="customerDocumentDropzoneOptions"
                ></vue-dropzone>
                <button @click="removeAllCustomerDocuments">
                  Remove All Files
                </button>
              </v-col>

              <template>
                <v-file-input
                  outlined
                  dense
                  accept="image/*"
                  label="Profile Picture"
                  @change="customerPhoto"
                ></v-file-input>
              </template>

              <div v-if="previewShow">
                <img
                  :src="form.photo"
                  style="width:100px;height:100px;margin-left: 52px;"
                  alt=""
                />
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            class="primary"
            :disabled="loading"
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const _token = "Bearer " + localStorage.getItem("authtoken");
export default {
  props: ["show", "detailspage"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      id: null,
      photos: "",
      editing: false,
      showPassword: false,
      errors: {},
      dialog: this.show,
      loading: false,
      removeFilesFromDropzoneOnly: false,
      countryName: "",
      countryNameLoading: false,
      previewShow: false,
      form: {
        fileUrls: [],
      },
      loading_types: [
        { title: "Select one", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      customerDocumentDropzoneOptions: {
        url: Api.defaults.baseURL + "/file-upload",
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },
      countryItem: [],
      stateItem: [],
      cityItem: [],
    };
  },
  methods: {
    newCustomer() {
      this.removeAllCustomerDocuments();
      this.errors = {};
      this.editing = false;
      this.emptyForm();
      this.dialog = true;
      this.getCustomerId();
    },

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },

    removeAllCustomerDocuments() {
      this.form.customer_documents = [];
      if(this.$refs.customerDocuments) {
        this.$refs.customerDocuments.removeAllFiles();
      }
    },
    removeCustomerDocument(file, error, xhr) {
      let index = this.form.fileUrls.findIndex(
        (x) => x.name === file.name
      );
      this.form.fileUrls.splice(index, 1);

      let docIndex = this.form.customer_documents
        ? this.form.customer_documents.findIndex((x) => x.name === file.name)
        : -1;
      this.form.customer_documents.splice(docIndex, 1);

    },
    storeArrivedSuccess(file, response) {
      this.form.fileUrls.push(response.data);
    },
    storeSuccess(type, data) {
      this.loading = false;
      if (!this.form.customer_documents.hasOwnProperty(type)) {
        this.form.customer_documents[type] = [];
      }
      this.form.customer_documents[type].push(data);
    },
    sendingEvent() {},
    storeError(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    emptyForm() {
      this.form = {
        legacy_customer_id: "",
        email: "",
        password: "",
        customer_name: "",
        company_name: "",
        phone: "",
        phone_two: "",
        trn_usa: "",
        trn_uae: "",
        status: true,
        fax: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        city: "",
        state: "",
        zip_code: "",
        other_emails: "",
        note: "",
        loading_type: "",
        documents: "",
        fileUrls:[],
        customer_documents: [],
        removableFiles: [],
        photo: {},
      };
      this.previewShow = false;
      this.removeAllCustomerDocuments();
    },
    customerPhoto(event) {
      let formData = new FormData();

      let id;
      if (this.editing) {
        id = this.form.user_id;
      } else {
        id = 0;
      }
      formData.append("file", event);

      if (event === null) {
        this.previewShow = false;
        this.form.photo_url = "";
        return;
      }
      Api.post(`/customers/${id}/upload-file`, formData).then((res) => {
        this.previewShow = true;
        this.form.photo_url = res.data.data;
        this.form.photo = res.data.data;
      });
    },
    async store() {
      this.loading = true;
      this.errors = {};
      await Api.post(`/customers`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
          }
        });

      this.loading = false;
      this.editing = false;
    },

    async update() {
      this.loading = true;
      this.errors = {};

      await Api.put(`/customers/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
          this.editing = false;
          this.$emit("cancelForm");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e("Failed to update data! One or more required field missing.");
          } else {
            this.$toastr.e( err.response?.data?.message ?? "Failed to update data! One or more required field missing.");
          }
          this.loading = false;
        });
    },
    async edit(id) {
      if (!id) {
        return;
      }
      this.emptyForm();
      this.editing = true;
      this.id = id;
      this.loading = true;
      this.errors = {};
      await Api.get(`/customers/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.previewShow = true;
          if (this.form.country_id) {
            this.changeCountry(this.form.country_id);
          }

          this.form.fileUrls=[]
          if (this.form.state_id) {
            this.changeState(this.form.state_id);
          }

          this.form.customer_documents.map((value, index, array) => {
            let file = { size: value.size, name: value.name, type: value.type };
            this.$refs.customerDocuments.manuallyAddFile(file, value.url);
          });

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
      if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data! " + err);
      }
        });
    },
    cancel() {
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },
    changeCustomer(obj) {
      this.form.customer_user_id = obj.user_id;
      this.form.company_name = obj.company_name;
      console.log(obj);
    },
    changeCountry() {
      this.cityItem = [];
      this.loading = true;
      Api.get(
        `/settings/states?status=1&limit=-1&country_id=${this.form.country_id}`
      )
        .then((res) => {
          this.stateItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });
    },
    changeState(id) {
      this.loading = true;
      Api.get(
        `/settings/cities?status=1&limit=-1&state_id=${this.form.state_id}`
      )
        .then((res) => {
          this.cityItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    handleFileUpload: function() {
      this.form.photo = this.$refs.file.files[0];
    },
    getCountryItem () {
      this.loading = true;
      Api.get(`/settings/countries?status=1&limit=-1`).then(res => {
          this.countryItem = res.data.data;
          this.loading = false;
      }).catch(err => {
          if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loading = false;
          }
      })
    },
    getCustomerId () {
      this.loading = true;
      Api.get(`/customers/next-customer-id`).then(res => {
          this.form.legacy_customer_id = res.data.legacy_customer_id;
          this.loading = false;
      }).catch(err => {
          if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loading = false;
          }
      })
    },
  },
  created() {
    this.emptyForm();
  },
  watch: {
    show: function(newVal, oldVal) {
      this.dialog = newVal;
    },
    countryName: function(newVal) {
      if (newVal) {
        this.countryNameLoading = true;
        Api.get(`settings/countries?name=${newVal}&status=1`)
          .then((res) => {
            this.countryNameLoading = false;
            this.countryItem = res.data.data;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getCountryItem();
  },
};
</script>
