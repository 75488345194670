<template>
  <v-row class="mt-1 float-right">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Handed Over Date ({{ container_no }})</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!--              <v-text-field dense outlined label="Location name" v-model="form.handed_over_date" required></v-text-field>-->
              <v-menu
                ref="datePicker13"
                v-model="datePicker13"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :error-messages="errors.handed_over_date"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="form.handed_over_date"
                    label="Handed Over date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors.handed_over_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  style="height: 350px"
                  v-model="form.handed_over_date"
                  @input="datePicker13 = false"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="update()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
export default {
  props: ["id", "show", "container_no"],
  name: "Handover",
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      dataId: this.id,
      errors: {},
      datePicker13: "",
      dialog: this.show,
      loading: false,
      form: {
        handed_over_date: "",
      },
    };
  },
  methods: {
    async update() {
      this.loading = true;
      this.errors = {};
      // exports/{id}/handed-over
      await Api.post(`exports/${this.id}/handed-over`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.form = {
            handed_over_date: "",
          };
          this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e("Failed to update data! " + err);
        });
    },
    cancel() {
      this.dataId = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.dialog = newVal;
    },
  },
};
</script>
