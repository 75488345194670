<template>
  <div class="vehicle" style="margin: 70px 0px">
    <v-container>
      <div class="d-flex grey--text">
        <v-btn small to="/" color="grey" text>Home</v-btn>
        <v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small to="/acl/users" color="grey" text>User Management</v-btn>
        <v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
    </v-container>
    <v-container class="permision">
      <h2 class="ml-3">Username: {{ user.username }}</h2>
      <vue-content-loading :width="300" :height="100" v-if="loadings">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>
      <v-row class="d-flex" v-else style="flex-wrap: wrap; padding-bottom: 50px">
        <v-col
          cols="12"
          md="4"
          class="pa-4"
          v-for="(permission, p) in selected_permissions"
          :key="p"
        >
          <v-card max-width="100%" class="pa-3" elevation="2">
            <strong>
              <v-checkbox
                v-model="permission.enabled"
                :label="`Module: ${permission.name}`"
                :value="true"
                @click="allCheckHandle(permission)"
                hide-details
              ></v-checkbox>
            </strong>
            <div class="module-checkbox pl-6">
              <v-checkbox
                v-for="(p, i) in permission.permissions"
                :key="i"
                v-model="p.has_access"
                :label="p.name"
                :value="true"
                @click="checkHandle(p.id, permission)"
                hide-details
              ></v-checkbox>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div class="" style="display: flex; flex-flow: row-reverse; margin-bottom: 20px">
        <v-btn :loading="loading" color="primary" @click="updateRole" >
          Update
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
export default {
  name: "RoleUser",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      selected_permissions: [],
      permissions: [],
      hasPermissions: [],
      user: {},
      loading: false,
      moduleName: "",
      sub: "",
      loadings: false,
    };
  },
  methods: {
    getAccessId() {
      Object.values(this.selected_permissions).forEach((item) => {
        item.permissions.forEach((permission) => {
          if (permission.has_access) {
            this.hasPermissions.push(permission.id);
          }
        });
      });
    },
    updateRole() {
      Api.post(`/acl/users/${this.$route.params.id}`, {
        permissions: this.hasPermissions,
      })
        .then((res) => {
          this.$toastr.s("Role successfully updated");
        })
        .catch((err) => {
          // if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          // }
        });

      this.editId = null;
      this.deletingId = null;
    },
    async getPermissions() {
      await Api.get(`/acl/permissions`)
        .then((res) => {
          this.permissions = res.data;
          this.getRole();
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });

      this.editId = null;
      this.deletingId = null;
    },
    getRole() {
      this.loadings = true;
      Api.get(`/acl/users/${this.$route.params.id}`)
        .then((res) => {
          this.user = res.data.user;
          this.selected_permissions = res.data.permissions;
          this.getAccessId();
          this.loadings = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });

      this.editId = null;
      this.deletingId = null;
    },

    allCheckHandle(permission) {
      if (permission.enabled) {
        permission.permissions.forEach((e) => {
          e.has_access = true;
          if (!this.hasPermissions.includes(e.id)) {
            this.hasPermissions.push(e.id);
          }
        });
      } else {
        permission.enabled = false;
        permission.permissions.forEach((e) => {
          e.has_access = false;
          this.hasPermissions.splice(
            this.hasPermissions.findIndex((k) => k == e.id),
            1
          );
        });
      }
    },
    checkHandle(id, permission) {
      if (!Array.isArray(this.hasPermissions) || !this.hasPermissions.length) {
        this.hasPermissions.push(id);
      } else if (this.hasPermissions.includes(id)) {
        this.hasPermissions.splice(
          this.hasPermissions.findIndex((k) => k == id),
          1
        );
      } else {
        this.hasPermissions.push(id);
      }
      permission.permissions.forEach((e) => {
        if (e.has_access) {
          permission.enabled = true;
        }
      });
      // ====================
      permission.permissions.forEach((e) => {
        if (!e.has_access) {
          permission.enabled = false;
        }
      });
    },
  },
  mounted() {
    // this.getPermissions();
    this.getRole();
  },
  created() {},
};
</script>

<style scoped>
.permision .v-card {
  height: 100% !important;
  /* margin-bottom: 10px; */
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: all;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: all;
  font-size: 0.75rem;
  height: 48px;
}
</style>
>
