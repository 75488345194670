<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props:['chartData', 'getTotal'],
  data() {
      return {
        options: {
          hoverBorderWidth: 5,
          responsive: true,
          cutoutPercentage: 70,
          maintainAspectRatio: false,
          legend: { display: false },
          plugins: {
            datalabels: {
              color: "black",
              font: {
                weight: "bold",
                size: 0
              }
            },
            doughnutlabel: {
              labels: [
                {
                  text: '',
                  font: {
                    size: "30"
                  }
                },
                {
                  text: 'Total',
                  font: {
                    size: "15"
                  },
                  color: "grey"
                }
              ]
            }
          },
          animation: {
            animateRotate: true
          }
        },
      }
  },
  methods: {
    update() {
      this.$data._chart.update()
    },
    render() {
      this.renderChart(this.chartData, this.options)
    }
  },
  watch: {
    getTotal: function (val) {
      this.options.plugins.doughnutlabel.labels[0].text = val;
    }
  },
  mounted () {
    this.render();
  }
};
</script>