<template>
    <v-row class="mt-1 float-right">
        <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-if="permissions['cities.store'] == false"></div>
                <v-btn  
                        v-else
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                >
                    New Port
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                        <span class="headline">{{ editing ? 'Edit' : 'New' }} Port</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                          <v-text-field dense outlined label="Name" class="mr-3" v-model="form.name" required
                                        :error-messages="errors.name"
                          ></v-text-field>
                          </v-row>

                      <v-row>
                          <v-autocomplete
                              dense
                              outlined
                              hide-no-data
                              label="Country"
                              autocomplete="none"
                              @change="changeCountry"
                              v-model="form.country_id"
                              :items="countryItem"
                              item-text="name"
                              item-value="id"
                              :loading="countryNameLoading"
                              :error-messages="errors.country_id"
                          ></v-autocomplete>
                        </v-row>
                        <v-row>

                          <v-select
                              item-text="name"
                              item-value="id"
                              :items="stateItem"
                              v-model="form.state_id"
                              outlined
                              dense
                              label="State"
                              :error-messages="errors.state_id"
                          ></v-select>

                        </v-row>

                        <v-row>
                          <v-radio-group v-model="form.type" name="title" row>
                            <v-radio label="Port of Loading" value="1"></v-radio>
                            <v-radio label="Port of Discharge" value="2"></v-radio>
                          </v-radio-group>

                            <v-checkbox label="Status" v-model="form.status" required
                                        :error-messages="errors.status"
                            ></v-checkbox>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="cancel()"
                    >
                        Close
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="editing ? update() : store()"
                    >
                        {{ editing ? 'Update' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import Api from "@/services/Api";
    export default {
        props: ['id', 'show'],
        name: 'ManageLocation',
        data() {
            return {
                permissions: JSON.parse(localStorage.getItem('permissions')),
                dataId: this.id,
                editing: false,
                customarUserId:'',
                errors: {},
                dialog: this.show,
                loading: false,
                customersItem:[],
                form: {
                    name: '',
                    country_id:'',
                    status: true,
                    state_id:'',
                    type: '1',
                },
                countryNameLoading: false,
                countryItem: [],
                stateItem: [],
            }
        },
        methods: {
            store() {
                this.loading = true;
                this.errors = {};
                Api.post(`/settings/ports`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        name: '',
                        country_id:'',
                        state_id:'',
                        type:'1',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to create data! '+ err);
                })
            },
            async update() {
                this.loading = true;
                this.errors = {};
                await Api.put(`/settings/ports/${this.id}`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                      name: '',
                      country_id:'',
                      state_id:'',
                      type:'1',
                      status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to update data! '+ err);
                })

                this.editing = false;
            },
            edit() {
                if (!this.dataId) {
                    return;
                }
                this.loading = true;
                this.errors = {};
                Api.get(`/settings/ports/${this.dataId}`).then(res => {
                    this.form = res.data.data;
                    this.form.type = res.data.data.type.toString();
                    this.dialog = true;
                    this.loading = false;
                    this.autoCompleteData();
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }
                    if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! '+ err);}
                })
            },
            cancel() {
                this.dataId = false;
                this.editing = false;
                this.dialog = false;
                this.form = {
                  name : '',
                  country_id:  '',
                  state_id:  '',
                  status: true,
                  type : '1'
                }
                this.$emit("cancelForm");
            },
            autoCompleteData(){
                  this.loading = true;
                Api.get(`/settings/states?status=1`).then(res => {
                    this.stateItem = res.data.data;
                    this.loading = false;
                }).catch(err => {
                    this.$toastr.e("Failed to load data!" + err);
                    this.loading = false;
                })
            },
          changeCountry() {
            this.cityItem = [];
            this.loading = true;
            Api.get(
                `/settings/states?status=1&limit=-1&country_id=${this.form.country_id}`
            )
                .then((res) => {
                  this.stateItem = res.data.data;
                  this.loading = false;
                })
                .catch((err) => {
                  if (err.response.status !== 401) {
                    this.$toastr.e("Failed to load data!" + err);
                    this.loading = false;
                  }
                });
          },

          getCountryItem () {
            this.loading = true;
            Api.get(`/settings/countries?status=1&limit=-1`).then(res => {
              this.countryItem = res.data.data;
              this.loading = false;
            }).catch(err => {
              if (err.response.status !== 401) {
                this.$toastr.e("Failed to load data!" + err);
                this.loading = false;
              }
            })
          },
        },
        created(){
            // this.autoCompleteData()
        },
      mounted() {
        this.getCountryItem();
      },
        
        watch: {
            id: function(newVal, oldVal) {
                if (newVal || oldVal) {
                    this.dataId = newVal;
                    this.edit();
                    this.editing = true;
                } else {
                    this.dataId = false;
                    this.editing = false;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            }, 

        }
    }
</script>