<template>
    <div class="d-flex " style="justify-content:center;flex-wrap: wrap;">
        <div v-for="(overView,i) in overViews.data" :key="i" style="width: 16.6%;min-width:10rem">
            <v-card color="mx-2 mb-5 pt-5" elevation="0">
                <div class="d-flex mx-4 justify-space-between">
                    <div class="d-flex flex-column justify-center">
                        <p> {{overView.label}}</p>
                        <p class="font-weight-black headline"> {{overView.amount}} </p>
                    </div>
                </div>
            </v-card>
        </div>

    </div>
</template>
<script>
    import Api from "@/services/Api";
    import axios from "axios";

    export default {
        props: ['id'],
        data: () => ({
            overViews: [],
            AuthStr : 'Bearer ' + localStorage.getItem('authtoken'),
            baseDomain : process.env.VUE_APP_API_ENDPOINT,
        }),
        mounted() {
            var apiLink = `/invoices/overview`
            if (this.id) {
                apiLink = `/invoices/overview?customer_user_id=${this.id}`
            }
            // Api.get(apiLink, {id: '5'}).then(res => {
            //     this.overViews = res.data;
            // }).catch(err => {
            //     this.$toastr.e("Failed to load data!" + err);
            // })

            const AuthStrWithToken = this.AuthStr;
            const baseDomain = this.baseDomain;
            axios.get(`${baseDomain}/api/v1${apiLink}`, { 'headers': { 'Authorization': AuthStrWithToken }, 'id' : 5 })
            .then(res => {
                this.overViews = res.data;
            }).catch(err => {
                this.$toastr.e("Failed to load data!" + err);
            });


        }

    }
</script>