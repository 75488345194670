import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


import auth from "./modules/auth";
import counter from "./modules/counter";
Vue.use(Vuex);

const dataState = createPersistedState({
	paths: ['counter']
  })

export default new Vuex.Store({
	modules: {
		auth,
		counter
	},
	plugins: [dataState]

});


  
  
