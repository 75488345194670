<template>
    <div>
        <div class="col-md-8 offset-md-2">
            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            <button @click="removeAllFiles">Remove All Files</button>
        </div>
    </div>
</template>


<script>

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'Upload',
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                token: null,
                dropzoneOptions: {
                    url: process.env.VUE_APP_API_ENDPOINT+'/api/v1/file-upload',
                    thumbnailWidth: 150,
                    maxFilesize: 10,
                    headers: { "Authorization": "Bearer "+ this.token },
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Choose file (at least 192x192px)',
                    acceptedFiles: '.jpg,.jpeg,.png',
                    maxFiles: 1,
                    init: function() {
                        this.on('addedfile', function(file) {
                            if (this.files.length > 1) {
                                this.removeFile(this.files[0]);
                            }
                        });
                    }
                }
            }
        },
        methods : {
            removeAllFiles() {
                this.$refs.dropzone.removeAllFiles();
            }
        },
        mounted() {
            this.token =  localStorage.getItem("token");
        }
    }

</script>