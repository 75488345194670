<template>
  <div class="vehicle home">
       <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/settings/locations" color="grey" text>city</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
  </v-container>
          <v-container   class="white mt-5 pl-6 d-flex justify-space-between align-center">
          <h3 class="primetime">City Details</h3>
      </v-container>
      <v-container  class="white mt-1 pt-5 px-5">
        <v-row >
          <v-col cols="12" sm="12" md="6" > 
            <v-row
              no-gutters
              
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
               {{vehicle.name}}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Status
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <v-chip
                    color="green"
                    text-color="white"
                    small
                    v-if="vehicle.status===1"
                >
                  Active
                </v-chip>
                <v-chip
                    color="red"
                    text-color="white"
                    small
                    v-else
                >
                  Inactive
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" >
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                State Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ vehicle.state ? vehicle.state.name : ''}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Country Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ ( vehicle.state && vehicle.state.country ) ? vehicle.state.country.name : '' }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

  </div>
</template>

<script>
 import Api from "@/services/Api";
 import axios from "axios";

export default {
    name: "State",
    components: {
    },
    data() {
        return {
            vehicle: {},
            loading: false,
            errors:{},
            AuthStr : 'Bearer ' + localStorage.getItem('authtoken'),
            baseDomain : process.env.VUE_APP_API_ENDPOINT,
        };
    },
    methods: {
       getData(){
                this.errors = {};

           const AuthStrWithToken = this.AuthStr;
           const baseDomain = this.baseDomain;
           axios.get(`${baseDomain}/api/v1/settings/cities/${this.$route.params.id}`,  { 'headers': { 'Authorization': AuthStrWithToken } })
               .then(res => {
                    this.vehicle = res.data.data;
                }).catch(err => {
                  if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! ' + err);
                  }
                })
       }
    },
    mounted() {
     this.getData()
    }
};
</script>