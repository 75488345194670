<template>
   <div class="vehicle mt-5">
      <v-container class="white mt-5 pl-6 d-flex justify-space-between align-center">
          <h3 class="primetime">Towing Request Information</h3>
<!--          <v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
      </v-container>
      <v-container class="white mt-1 pt-5 px-5">
          <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Condition
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div v-if="details.condition==='1'">Operable</div>
                <div v-else>Non operable</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Damaged
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div v-if="details.damaged==='1'">Yes</div>
                <div v-else>No</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Pictures
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div v-if="details.pictures==='1'">Yes</div>
                <div v-else>No</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Towed
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div v-if="details.towed==='1'">Yes</div>
                <div v-else>No</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Towed From
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.towed_from }}
              </v-col>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Towed Amount
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.towed_amount }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Towing Request Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.towing_request_date }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Pickup Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.pickup_date }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Deliver Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{details.deliver_date}}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Note
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.note }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
   </div>
</template>

<script>
export default {
  props:['details'],
  data() {
    return {
      towingInfo: [
        {
          name: "Condition",
          value: "Non-op",
        },
        {
          name: "Damaged",
          value: "Yes",
        },
        {
          name: "Pictures",
          value: "Yes",
        },
        {
          name: "Towed",
          value: "Yes",
        },
        {
          name: "Towed From",
          value: "Cleveland"
        }
      ],

      towingInfo2: [
        {
          name: "Towed Amount",
          value: 440,
        },
        {
          name: "Towing Request Date",
          value: "2021-03-03",
        },
        {
          name: "Pickup Date",
          value: "N/A",
        },
        {
          name: "Deliver Date",
          value: "2021-03-06",
        },
        {
          name: "Note",
          value: "N/A"
        }
      ]
    }
  }
}
</script>

<style scoped>

.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd
}
</style>