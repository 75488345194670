<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <p 
          v-on="on"
          v-bind="attrs"
          @click="getData()"
          
        >
        {{ text}}
        </p>
      </template>
      <v-card>
        <div class=" pa-4 d-flex">
             <span class="headline">Customer Info</span>
         <v-btn
            class="ml-auto"
            style="float:right"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0" style="border-bottom:1px solid #eeee">
            
        </v-card-title>

        <v-card-text>
           <div class="container mt-2">
               <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.customer_user_id"
                  :items="customer"
                  item-text="customer_name"
                  item-value="user_id"
                  :error-messages="errors.customer_user_id"
                  label="CUSTOMER"
                  autocomplete="none"
                  disabled
                  placeholder="Customer"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  disabled
                  v-model="customarCompanyName"
                  label="CUSTOMER COMPANY"
                  outlined
                  dense
                  :error-messages="errors.customerCompany"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field v-model="form.customer_user_id" disabled label="CUSTOMER" outlined dense :error-messages="errors.customer"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                <v-menu
                  ref="datePickerw"
                  v-model="datePickerw"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :error-messages="errors.eta"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.eta"
                      label="ETA"
                      prepend-icon="mdi-calendar"
                      clearable
                      :error-messages="errors.eta"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px;"
                    v-model="form.eta"
                    @input="datePickerw = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <br />

           </div>
        </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="update()"
          >
            {{ "Update" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";

export default {
  props: ["id", "show", "text", "lot"],
  name: "Manage",
  components: {
      Api
  },
  data() {
    return {
      dialog: this.show,
      editing: false,
      form : {
        customer_user_id: "",
        eta: "",
        consignee:0,
      },
      date:"",
      customer: [],
      customarCompanyName:"",
      datePickerw:false,
      errors: {},
      currentUrl: null,
    };
  },
  methods: {
    cancel() {
      this.editing = false;
      this.dialog = false;
    },
    getData() {
      // this.loading = true;
      Api.get(`/exports/${this.id}`)
        .then((res) => {
          this.form = res.data.data.export_details;
          this.form.consignee = parseInt(res.data.data.export_details.houstan_custom_cover_letter.consignee)
           Api.get(`customers-item?user_id=${this.form.customer_user_id}`)
            .then((res) => {
            this.customer = res.data.data
            });
            this.customarCompanyName = res.data.data.export_details.customer.company_name
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);}
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/exports/${this.id}`, this.form)
         .then((res) => {
          this.dialog = false;
          this.loading = true;
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
          //  this.$router.push(`/exports`);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e("Failed to update data! One or more required field missing. ");
        });
    },
  },
  created() {
    // this.getData()
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    if (roleId === 3) {
      this.isCustomer = false;
    } else {
      this.isCustomer = true;
    }
  },
  watch: {},
  mounted() {
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
};
</script>
<style scoped>
.lot-number,
.lot-number:hover {
  color: #1976d2 !important;
  background-color: transparent !important;
  margin-top: -16px;
  outline: none;
}
</style>
