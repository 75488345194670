<template>
  <div>
    <v-container>
      <div class="d-flex grey--text">
        <v-btn small to="/" color="grey" text>Home</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small to="/notes" color="grey" text>Notes</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
    </v-container>
    <v-container
      class="white mt-5 pl-6 d-flex justify-space-between align-center"
    >
      <h3 class="primetime">Notes Details</h3>
    </v-container>
     <v-container class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="4">
             <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Customer Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.customer_name }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Company Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.company_name }}
              </v-col>
            </v-row>
              <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                VCR
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.vcr }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                HAT
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.hat_number }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Make
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.make }}
              </v-col>
            </v-row>
            <!-- <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Model
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.model }}
              </v-col>
            </v-row> -->
            
          </v-col>


          <v-col cols="12" sm="12" md="4">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                VIN
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.vin }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                LOT Number
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.lot_number }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Model
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.model }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Color
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.color }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Keys
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.keys_name }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Key Note
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.key_note }}
              </v-col>
            </v-row>
            <!-- <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Value
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.value }}
              </v-col>
            </v-row> -->
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Buyer No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.buyer_id }}
              </v-col>
            </v-row>
           
          </v-col>


          <v-col cols="12" sm="12" md="4">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Storage Amount
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.storage_amount }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Check No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.check_number }}
              </v-col>
            </v-row>
             <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Value
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.value }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Add. CHGS
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.additional_charges }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Location
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.location }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Weight
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.weight }}
              </v-col>
            </v-row>

             <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Year
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{   details.year }}
              </v-col>
            </v-row>
           
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>
import Api from "@/services/Api";
export default {
  data: () => ({
    details: {},
  }),
  methods: {
    getData() {
      Api.get(`/vehicles/notes/${this.$route.params.id}`)
        .then((res) => {
          this.details = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }else{
            this.$toastr.e(err.response.data.status)
          }
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
