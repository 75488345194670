<template>
  <div class="footer">
    <v-card
      outlined
      class="d-flex justify-space-between flex-column flex-sm-row px-10 pt-4"
    >
      <p>ASL 2020</p>
      <p class="blue-grey--text">
        Contact us:
        <span class="font-weight-bold">info@aslshippingline.com</span>
      </p>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
</style>
