import VehicleReport from '@/views/reports/VehicleReport';
import CustomerReport from '@/views/reports/CustomerReport';
import CustomerRecordReport from '@/views/reports/CustomerRecordReport';
import CustomerManagementReport from '@/views/reports/CustomerManagementReport';
import ContainerReport from '@/views/reports/ContainerReport';
import CustomerTitleStatusReport from '@/views/reports/CustomerTitleStatusReport';
import CustomerInvoiceRecordReport from '@/views/reports/CustomerInvoiceRecordReport';

export default [
    {
        path: '/reports/vehicle',
        name: 'reports.vehicle',
        component: VehicleReport,
        meta: {
            title: "Vehicle Report",
            requiresAuth: true
        }
    },
    {
        path: '/reports/customer',
        name: 'reports.customer',
        component: CustomerReport,
        meta: {
            title: "Customer Report",
            requiresAuth: true
        }
    },
    {
        path: '/reports/container',
        name: 'reports.container',
        component: ContainerReport,
        meta: {
            title: "Container Report",
            requiresAuth: true
        }
    },
    {
        path: '/reports/customer-record',
        name: 'reports.customer-record',
        component: CustomerRecordReport,
        meta: {
            title: "Customer Record",
            requiresAuth: true
        }
    },
    {
        path: '/reports/customer-management',
        name: 'reports.customer-management',
        component: CustomerManagementReport,
        meta: {
            title: "Customer Management Report",
            requiresAuth: true
        }
    },
    {
        path: '/reports/customer-title-status',
        name: 'reports.customer-title-status',
        component: CustomerTitleStatusReport,
        meta: {
            title: "Customer Title Status Report",
            requiresAuth: true
        }
    },
    {
        path: '/reports/customer-invoice-record',
        name: 'reports.customer-invoice-record',
        component: CustomerInvoiceRecordReport,
        meta: {
            title: "Customer Invoice Record Report",
            requiresAuth: true
        }
    },
]