export const isEmptyDeep = (data) => {
    if(typeof data === 'object' && !Array.isArray(data) && data !== null) {
      if(Object.keys(data).length === 0) {
        return true;
      }else{
        return false;
      }
    } else if(typeof data === 'string' || Array.isArray(data)) {
      return !data.length
    }else if(data === null || data === undefined) {
        return true;
    }
    return false
  }
  
