<template>

    <div class="vehicle mt-65 pb-10" >
        <breadcrumbs/>

        <v-container fluid>

            <v-container class="white mt-5 pl-6 d-flex justify-space-between align-center">
                <h3 class="primetime">Price Details</h3>
                <!--<v-btn text @click="edit(cosignee.id)" color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
            </v-container>
            <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
                <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
            </v-container>
            <v-container v-else class="white mt-1 pt-5 px-5">
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Pricing Type
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.pricing_type_name }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Month
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.month }}
                            </v-col>
                        </v-row>


                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Status
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.status_name }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Description
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.description ? prices.description : 'N/A'}}
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from "@/views/prices/Breadcrumbs.vue";
    import Api from "@/services/Api";

    export default {
        name: "Price",
        components: {
            Breadcrumbs,
        },
        data() {
            return {
                prices: {},
                loading: false
            };
        },
        methods: {
            show() {
                this.loading = true;
                this.errors = {};
                Api.get(`/pricing/${this.$route.params.id}`).then(res => {
                    this.prices = res.data.data;
                    this.loading = false;
                }).catch(err => {
                    if (err.response.status !== 401) {
                    this.loading = false;
                    this.$toastr.e('Failed to load data! ' + err);
                    }else{
                        this.$toastr.e(err.response.data.status)
                    }
                })
            }
        },
        created() {
            this.show();
        },
        mounted() {

        }
    };
</script>
<style>
    .vehicle {
        background-color: #f8f8fb;
    }

    .primetime {
        color: #1b455f !important;
    }

    .breaking {
        overflow-wrap: break-word;
    }

    @media screen and (max-width: 600px) {
        .small-row {
            width: 50% !important;
        }

        .show-all {
            display: none;
        }
    }

    .table-wrapper {
        border: 1px solid #dddddd;
    }

    .second-column {
        border-left: 1px solid #dddddd;
    }
</style>

