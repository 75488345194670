import Api from "@/services/Api.js";
import { isEmptyDeep } from "../../helpers/utility";
import { setHttpToken } from "@/helpers/index.js";

let cartCount = window.localStorage.getItem('cartCount');
const state = {
  user: {
    authenticated: false,
    data: null,
    permissions: null,
    cart:[],
    cartCount: cartCount ? parseInt(cartCount) : 0,
  },
  vehicle_colors : [],
  vehicle_condition_items : [],
  locations : [],
  vehicle_checkbox_items : [],
};

const getters = {
  user: (state) => state.user,
};

const actions = {
  // Password Reset
  SEND_PASSWORD_RESET_LINK({}, data) {
    return new Promise((resolve, reject) => {
      Api.post("password/email", data)
        .then((response) => resolve({ success: true }))
        .catch((error) =>
          reject({ success: false, data: error.response.data.errors })
        );
    });
  },
  RESET_PASSWORD({}, data) {
    return new Promise((resolve, reject) => {
      Api.post("password/reset", data)
        .then((response) => {
          if (response.status === 202) {
            reject({
              success: false,
              data: { token: "This password reset token is invalid." },
            });
          }
          resolve({ success: true });
        })
        .catch((error) =>
          reject({ success: false, data: error.response.data.errors })
        );
    });
  },
  // Login
  login({ dispatch, commit }, { payload, context }) {
    return Api.post("/auth/login?with_additional_data=true", payload)
      .then((response) => {

        localStorage.removeItem('alert')
        dispatch("setToken", response.data.access_token).then(() => {
          dispatch("setUser", response.data.user);
          commit("setPermissionData", response.data.permissions);
          commit("setVehicleColors", response.data.vehicle_colors);
          commit("setVehicleConditionItems", response.data.vehicle_condition_items);
          commit("setLocations", response.data.locations.data);
          commit("setVehicleCheckboxItems", response.data.vehicle_checkbox_items);

          return Promise.resolve("LOGGED_IN");
        });
        return Promise.resolve({ token: response.data.access_token });
      })
      .catch((error) => {
        context.errors = error.response.data.errors;
        return Promise.reject("LOGIN_FAILED");
      });
  },
  async setUser({ commit }, user) {
    commit("setAuthenticated", true);
    commit("setUserData", user);
  },
  async setPermission({ commit }, user) {
    commit("setPermissionData", user);
  },
  // Logout
  logout({ dispatch }, data = {}) {
    Api.post("/auth/logout")
      .then((response) => {
        if (data.path || data.type) {
          if (data.path) {
            localStorage.setItem("intended", data.path);
          }
          localStorage.setItem("alert", { type: data.type, msg: data.msg });
        }
        dispatch("clearAuth");
        return Promise.resolve("LOGGED_OUT");
      })
      .catch((err) => Promise.reject("LOGGED_OUT_ERROR"));
  },
  async setToken({ commit, dispatch }, token) {
    if (isEmptyDeep(token)) {
      return await dispatch("checkTokenExists").then((token) => {
        setHttpToken(token);
      });
    }
    commit("setToken", token);
    setHttpToken(token);
  },
  checkTokenExists({ commit, dispatch }, token) {
    if (isEmptyDeep(localStorage.getItem("authtoken"))) {
      return Promise.reject("NO_STORAGE_TOKEN");
    }

    return Promise.resolve(`${localStorage.getItem("authtoken")}`);
  },
  clearAuth({ commit }, token) {
    commit("setAuthenticated", false);
    commit("setUserData", null);
    commit("setPermissionData", null);
    commit("setToken", null);
    commit("addToCart",null);
    setHttpToken(null);
  },
};

const mutations = {
  setToken(state, token) {
    if (isEmptyDeep(token)) {
      // localStorage.removeItem("authtoken", token);
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      return;
    }
    localStorage.setItem("authtoken", token);
  },
  setAuthenticated(state, trueOrFalse) {
    state.user.authenticated = trueOrFalse;
  },
  setUserData(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.removeItem("user");
    }
    state.user.data = data;
    localStorage.setItem("user", JSON.stringify(data));
  },
  setPermissionData(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.removeItem("permissions");
    }
    state.user.permissions = Object.assign({}, data);
    localStorage.setItem("permissions", JSON.stringify(state.user.permissions));
  },
  setVehicleColors(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.removeItem("vehicle_colors");
    }
    state.vehicle_colors =  data;
    localStorage.setItem("vehicle_colors", JSON.stringify(data));
  },

  setVehicleConditionItems(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.setItem("vehicle_condition_items", JSON.stringify([]));
    }
    state.vehicle_condition_items = data;
    localStorage.setItem("vehicle_condition_items", JSON.stringify(data));
  },

  setLocations(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.setItem("locations", JSON.stringify([]));
    }
    state.locations = data;
    localStorage.setItem("locations", JSON.stringify(data));
  },

  setVehicleCheckboxItems(state, data) {
    if (isEmptyDeep(data)) {
      localStorage.setItem("vehicle_checkbox_items", JSON.stringify([]));
    }
    state.vehicle_checkbox_items = data;
    localStorage.setItem("vehicle_checkbox_items", JSON.stringify(data));
  },
  setCheckOutData(state, data) {},

  addToCart(state, item) {

    if(localStorage.getItem("asl_checkout")) {
      let arr = Object.keys(JSON.parse(localStorage.getItem("asl_checkout"))).length;

      if(item) {
        state.user.cart.push(item);
      }
      state.user.cartCount = arr;
    }

    window.localStorage.setItem('cartCount', state.user.cartCount);
  },

  removeCart(state, item) {
      let index = Object.keys(JSON.parse(localStorage.getItem("asl_checkout"))).length;

      state.user.cartCount = index;

    // }
  
    window.localStorage.setItem('cartCount', state.user.cartCount);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
