<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <!-- <p 
          v-on="on"
          v-bind="attrs"
          @click="getData()"
          
        >
        {{ text}}
        </p> -->
        <v-btn
          v-if="!detailspage"
          color="primary"
          dark
          v-bind="attrs"
          @click="getData()"
          v-on="on"
        >
          Monthly Grap
        </v-btn>
      </template>
      <v-card>
        <div class="pa-4 d-flex">
          <span class="headline">{{this.customer_name}} Line Chart</span>
          <v-btn
            class="ml-auto"
            style="float: right"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0" style="border-bottom: 1px solid #eeee">
        </v-card-title>

        <v-card-text>
          <div class="container mt-2">
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";

export default {
  props: ["id", "show", "text", "lot"],
  name: "ManageLine",
  components: {
    Api,
  },
  data() {
    return {
      dialog: this.show,
      detailspage: false,
      customer_name:'',
      series: [
        {
          name: "Total Worth",
          data: [],
        },
        {
          name: "Pending Amount",
          data: [],
        },
        {
          name: "Discount given",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    cancel() {
      this.editing = false;
      this.dialog = false;
    },
    getData() {
      Api.get(`/invoices/monthly-graph/${this.$route.params.id}`)
        .then((res) => {
          let arr = [];
          this.customer_name = res.data.customer_name
          arr = res.data.invoices

          arr.map((e)=>{
              this.chartOptions.xaxis.categories.push(e.month)
              this.series[0].data.push(e.total_amount)
              this.series[1].data.push(e.total_pending)
              this.series[2].data.push(e.total_discount)
          })
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
  },
  created() {
    // this.getData()
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    if (roleId === 3) {
      this.isCustomer = false;
    } else {
      this.isCustomer = true;
    }
  },
  watch: {},
  mounted() {
    this.getData();
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
};
</script>
<style scoped>
.lot-number,
.lot-number:hover {
  color: #1976d2 !important;
  background-color: transparent !important;
  margin-top: -16px;
  outline: none;
}
</style>
