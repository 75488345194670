<template>
  <div class="home">
    <v-container fluid class="bg-white mt-65">
      <div class="d-flex pb-4">
        <h1 class="primetime ">Vehicles Weight</h1>

        <div class="ml-auto mr-4 d-flex" cols="12" md="2">
          <v-btn
            class="ml-auto mt-1 mr-5"
            @click="exportExcel"
            color="primary"
            :loading="downloading"
            >Export Excel</v-btn
          >
          <manage
            ref="manage"
            @dataUpdated="getData()"
            :show="formShow"
            :custom="true"
            :editing="true"
            @cancelForm="formShow = null"
          />
          <up
            :show="formUpload"
            @cancelForm="formUpload = null"
          />

         
        </div>
      </div>
       <v-container>
          <v-row>
              <v-col cols="12" md="12" class="text-right mt-2">
                  <h5>Showing : {{this.showingFrom? this.showingFrom : "0"}} - {{this.showingTo? this.showingTo: "0"}} of {{this.count? this.count : "0"}}</h5>
              </v-col>
          </v-row>
       </v-container>
      <confirmation
        :confirming="deletingId"
        @confirmed="remove"
        :show="deleteConfirmationShow"
        @canceled="deleteConfirmationShow = false"
      />
      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>
      <v-simple-table v-if="vehicles.data && !loading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Year</th>
              <th class="text-left">Maker</th>
              <th class="text-left">Model</th>
              <th class="text-left">Weight</th>
            </tr>
            <tr>
              
               <td class="py-3">
                <v-text-field
                  v-model="filters.year"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="search"
                ></v-text-field>
              </td>
               <td class="py-3">
                <v-text-field
                  v-model="filters.maker"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="search"
                ></v-text-field>
              </td>

              <td class="py-3">
                <v-text-field
                  v-model="filters.model"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="search"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.weight"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="search"
                ></v-text-field>
              </td>
             

              <td>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  :loading="filtering"
                  @click="search"
                >
                  <v-icon dark>mdi-clipboard-text-search</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  :loading="filtering"
                  @click="clearFilter"
                  v-if="isFilter"
                >
                  <v-icon dark>mdi-close-octagon</v-icon>
                </v-btn>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in vehicles.data" :key="item.name">
            
              <td>{{ item.year }}</td>
          
              <td>{{ item.maker }}</td>
              <td>{{ item.model }}</td>
              <td>{{ item.weight }}</td>
             

             
              <td>
                <div v-if="permissions['vehicles.view'] == false"></div>
                <v-btn v-else text :to="`/weights/${item.id}`">
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                <div v-if="permissions['vehicles.update'] == false"></div>
                <v-btn v-else text @click="edit(item.id)" color="blue">
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
                <div v-if="permissions['vehicles.destroy'] == false"></div>
                <v-btn v-else text @click="destroy(item.id)" color="red">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row justify="center" style="margin: 0;">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current, filtersUrl())"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!--<manageedit @dataUpdated="getData()" :id="editId" :show="formShowEdit" :editing="true" @cancelForm="formShow = false"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
import Api from "@/services/Api";
import Manage from "@/views/vehicles/weight/Manage.vue";
import up from "@/views/vehicles/weight/UploadModal.vue";
import NotesModal from "@/components/vehicle/NotesModal.vue";
// import Manageedit from "@/views/vehicles/Manageedit.vue";
import Confirmation from "@/components/Confirmation.vue";
import { VueContentLoading } from "vue-content-loading";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/helpers/downloadFile";
export default {
  name: "weights",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
    up,
    NotesModal,
    ManageDetails,
  },
  data() {
   
     return {
      notesShow: false,
      formUpload:false,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      // formShowEdit: false,
      deleteConfirmationShow: false,
      vehicles: {},
      customarUserId: "",
      pagination: {
        current: 1,
        total: 0,
      },
    
      loading: false,
      filters: {},
      filtering: false,
      loading2: false,
      showingFrom:0,
      showingTo:0,
      count:0,
      downloading: false,
    };
  },

  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {
    
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        var page = this.pagination.current;
      }
      if (searchQuery) {
        var searchQuery = `&${searchQuery}`;
      }

      Api.get(`/vehicle-weights?page=${page}${searchQuery}`)
        .then((res) => {
          this.vehicles = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.showingFrom = res.data.from;
              this.showingTo = res.data.to;
              this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }else{
            this.$toastr.e(err.response.data.status)
          }
        });
    },

    loadingManage (value) {
      this.downloading = value;
    },
   exportExcel(){
      this.loadingManage(true);
 const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      // window.open(`${baseUrl}/api/v1/vehicle-weights/export/excel`, "_blank");

     downloadFile(`${baseUrl}/api/v1/vehicle-weights/export/excel`,
         'vehicle_weight_export',
         '.xls',
         this.loadingManage
     );
   },

    search() {
      this.getData(1, this.filtersUrl());
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    remove() {
      Api.delete(`/vehicle-weights/${this.deletingId}`)
        .then((res) => {
          this.getData();
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
      // this.formShowEdit = false;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  mounted() {
    // this.getData();
    // this.getCustomerItem();

    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.mode && this.$route.query.mode === "openModal") {
        this.formShow = true;
      }
      this.filters = this.$route.query;
      this.search();
    } else {
      this.getData();
    }
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    customarUserId(newVal) {
      if (newVal) {
        this.loading2 = true;
        Api.get(`/customers-item?status=1&customer_name=${newVal}`)
          .then((res) => {
            this.loading2 = false;
            this.customersItem = res.data.data;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading2 = false;
            }
          });
      }
    },
    "filters.damage_claim"() {
      this.search();
    },
    "filters.claim_status"() {
      this.search();
    },
    "filters.keys"() {
      this.search();
    },
    "filters.title"() {
      this.search();
    },
    "filters.location"() {
      this.search();
    },
    "filters.status"() {
      this.search();
    },
    "filters.loading_type"() {
      this.search();
    },
    "filters.notes_status"() {
      this.search();
    },
    "filters.vehicle_type"() {
      this.search();
    },
    "filters.customer_user_id"() {
      this.search();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  width: 200px !important;
}
.manage-details {
  color: red !important;
  background: white !important;
}
</style>
