<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!detailspage"
          color="primary"
          dark
          v-bind="attrs"
          @click="(editing = false)"
          v-on="on"
        >
          Monthly Grap Notation
        </v-btn>
      </template>
      <v-card>
        <div class="pa-4 d-flex">
          <span class="headline">{{this.customer_name}} Bar Chart</span>
          <v-btn
            class="ml-auto"
            style="float: right"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0" style="border-bottom: 1px solid #eeee">
        </v-card-title>

        <v-card-text>
          <div class="container mt-2">
            <div id="chart">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";

export default {
  props: ["id", "show", "text", "lot"],
  name: "ManageBar",
  components: {
    Api,
  },
  data() {
    return {
      dialog: this.show,
      customer_name :'',
      amount:[],
      detailspage: false,
      series: [
        {
          name: "Cash Flow",
          // data: [
          //   1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09,
          //   0.34, 3.88, 13.07, 5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8,
          //   -27.03, -54.4, -47.2, -43.3, -18.6, -48.6, -41.1, -39.6, -37.6,
          //   -29.4, -21.4, -2.4,
          // ],
          data:[],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -100,
                  to: -46,
                  color: "#F15B46",
                },
                {
                  from: -45,
                  to: 0,
                  color: "#FEB019",
                },
              ],
            },
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Growth",
          },
          labels: {
            formatter: function (y) {
              return y.toFixed(0)
              
            },
          },
        },
        xaxis: {
          type: "text",
          categories:[],
          labels: {
            rotate: -90,
          },
        },
      },
    };
  },
  methods: {
    cancel() {
      this.editing = false;
      this.dialog = false;
    },
    getData() {
      // this.loading = true;
          let arr = [];
      Api.get(`/invoices/graphical-notation/${this.$route.params.id}`)
        .then((res) => {
          this.customer_name = res.data.customer_name;
          arr = res.data.invoices;
          arr.map((e)=>{
            return this.chartOptions.xaxis.categories.push(e.ar_number)
          })
          arr.map((e)=>{
           return this.series[0].data.push(e.due_amount)
          })
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/exports/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = true;
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e(
            "Failed to update data! One or more required field missing. "
          );
        });
    },
  },
  created() {
    let roleId = JSON.parse(localStorage.getItem("user")).role;
    if (roleId === 3) {
      this.isCustomer = false;
    } else {
      this.isCustomer = true;
    }
  },
  watch: {},
  mounted() {
    this.getData();
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
};
</script>
<style scoped>
.lot-number,
.lot-number:hover {
  color: #1976d2 !important;
  background-color: transparent !important;
  margin-top: -16px;
  outline: none;
}
</style>
