<template>
  <div class="home mt-65">
    <overview-data />
    <v-container fluid class="bg-white">
      <h1 style="margin-top: -13px">Invoice Unpaid</h1>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0 mt-5">
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  @keypress.enter="search"
                  outlined
                  dense
                  style="font-weight: bold"
                  v-model="filters.invoice_global_search"
                  label="Global Search"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn :loading="filtering" color="primary" @click="search"
                  >Search</v-btn
                >
              </v-col>

              <v-col cols="12" md="2" class="mt-2 text-right">
                <h5>
                  Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                  {{ this.showingTo ? this.showingTo : "0" }} of
                  {{ this.count ? this.count : "0" }}
                </h5>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" md="2">
          <manage
            class="mr-1 mt-1 ml-3"
            @dataUpdated="getData()"
            :id="editId"
            :show="formShow"
            @cancelForm="formShow = false"
          />
        </v-col>
      </v-row>
      <confirmation
        :confirming="deletingId"
        @confirmed="remove"
        :show="deleteConfirmationShow"
        @canceled="deleteConfirmationShow = false"
      />

      <v-simple-table class="mt-5" v-if="invoices.data">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Invoice Id</th>
              <th class="text-left date">Date</th>
              <th class="text-left">Company Name</th>
              <th class="text-left">Container No</th>
              <th class="text-left">Ar No</th>
              <th class="text-left">Total Amount</th>
              <th class="text-left">Damage</th>
              <th class="text-left">Storage</th>
              <th class="text-left">Discount</th>
              <th class="text-left">Other</th>
              <th class="text-left">Paid Amount</th>
              <th class="text-left">Balance</th>
              <th class="text-left">Note</th>
              <th class="text-left">Documents</th>
              <th class="text-center" style="width: 200px">Action</th>
            </tr>
          </thead>
          <tbody>
            <!--<tr>-->

            <!--<td><v-text-field v-model="filters.customer_user_id" outlined dense hide-details></v-text-field></td>-->
            <!--<td><v-text-field v-model="filters.company_name" outlined dense hide-details></v-text-field></td>-->
            <!--<td><v-text-field v-model="filters.total_amount" outlined dense hide-details></v-text-field></td>-->
            <!--<td><v-text-field v-model="filters.paid_amount" outlined dense hide-details></v-text-field></td>-->
            <!--<td><v-text-field v-model="filters.discount" outlined dense hide-details></v-text-field></td>-->
            <!--<td><v-text-field v-model="filters.balance" outlined dense hide-details></v-text-field></td>-->
            <!--<td>-->
            <!--<v-btn class="mx-2" fab dark small color="primary" :loading="filtering" @click="search">-->
            <!--<v-icon dark>mdi-clipboard-text-search</v-icon>-->
            <!--</v-btn>-->
            <!--<v-btn class="mx-2" fab dark small color="primary" :loading="filtering" @click="clearFilter">-->
            <!--<v-icon dark>mdi-close-octagon</v-icon>-->
            <!--</v-btn>-->
            <!--</td>-->
            <!--</tr>-->

            <tr v-for="item in invoices.data" :key="item.name">
              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.company_name }}</td>
              <td>{{ item.container_number }}</td>
              <td>{{ item.ar_no }}</td>

              <td>{{ item.total_amount }}</td>
              <td>{{ item.adjustment_damaged }}</td>
              <td>{{ item.adjustment_storage }}</td>
              <td>{{ item.adjustment_discount }}</td>
              <td>{{ item.adjustment_other }}</td>
              <td>{{ item.paid_amount }}</td>
              <td>{{ item.balance }}</td>

              <td>{{ item.note }}</td>
              <td>
                <div v-for="(invoice, i) in item.documents" :key="i">
                  <v-btn small class="ma-1" @click="invoiceUrl(invoice.url)"
                    >{{ invoice.label }}
                  </v-btn>
                </div>
              </td>

              <td style="width: 100px">
                <!--<div class="d-flex mt-1" style="justify-content:center">-->
                <!--<v-btn small color="#E0E0E0" elevation="0" class=" text-capitalize mt-1" :href="`${item.usa_invoice}`" target="_blank" style="margin-right: 5px" v-if="item.usa_invoice">USA INV</v-btn>-->
                <!--<v-btn small color="#E0E0E0" elevation="0" class=" text-capitalize mt-1" :href="`${item.clearance_invoice}`" target="_blank" style="margin-right: 5px" v-if="item.clearance_invoice">DXB INV</v-btn>-->
                <!--<v-btn small color="#E0E0E0" elevation="0" class=" text-capitalize mt-1" href="">Documents</v-btn>-->
                <!---->
                <!--</div>-->
                <!--<div class="text-center">-->
                <!--<v-btn text v-if="permissions['invoices.update'] !== false" @click="edit(item.id)" color="blue">-->
                <!--<v-icon>mdi-file-edit</v-icon>-->
                <!--</v-btn>-->
                <!--<v-btn text v-if="permissions['invoices.destroy'] !== false" @click="destroy(item.id)" color="red">-->
                <!--<v-icon>mdi-delete</v-icon>-->
                <!--</v-btn>-->
                <!--</div>-->

                <div class="d-flex float-right">
                  <a
                    v-if="item.usa_invoice"
                    :href="`${item.usa_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >USA INV</a
                  >
                  <a
                    v-if="item.clearance_invoice"
                    :href="`${item.clearance_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >CLEARANCE</a
                  >
                  <a
                    v-if="item.upload_invoice"
                    :href="`${item.upload_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >DXB INV</a
                  >
                  <v-btn
                    v-if="permissions['invoices.update'] !== false"
                    icon
                    color="primary"
                    @click="edit(item.id)"
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissions['invoices.destroy'] !== false"
                    icon
                    color="red"
                    @click="destroy(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style="font-weight: bold">
                {{ invoices.summary.grand_total }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_damage }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_storage }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_discount }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_other }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_paid }}
              </td>
              <td style="font-weight: bold">
                {{ invoices.summary.total_balance }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!--<div class="text-xs-center" v-else>-->
      <!--<v-btn primary>-->
      <!--<rotate-square5></rotate-square5>-->
      <!--</v-btn>-->
      <!--</div>-->

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current, filtersUrl())"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import overviewData from "@/components/overviewData.vue";
import Api from "@/services/Api";
import Manage from "@/views/invoices/Manage.vue";
import axios from "axios";
import { VueContentLoading } from "vue-content-loading";

export default {
  name: "InvoicesUnpaid",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
    overviewData,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      invoices: {},
      pagination: {
        current: 1,
        total: 0,
      },

      filters: {},
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      AuthStr: "Bearer " + localStorage.getItem("authtoken"),
      baseDomain: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      if (this.$route.query && this.$route.query.customer_user_id) {
        searchQuery += `&customer_user_id=${this.$route.query.customer_user_id}`;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      // Api.get(`/invoices/unpaid?page=${page}${searchQuery}`)
      //   .then((res) => {
      //     this.invoices = res.data;
      //     this.showingFrom = res.data.from;
      //     this.showingTo = res.data.to;
      //     this.count = res.data.total;
      //     this.pagination.current = res.data.current_page;
      //     this.pagination.total = res.data.last_page;
      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     if (err.response.status !== 401) {
      //       this.$toastr.e("Failed to load data!" + err);
      //       this.loading = false;
      //     } else {
      //       this.$toastr.e(err.response.data.status);
      //     }
      //   });

      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/invoices/unpaid?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.invoices = res.data;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });

      this.formShow = false;
      this.editId = null;
    },
    getDataWithFilter(finalSearchQuery) {
      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/invoices/unpaid?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.invoices = res.data;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      // this.filtering = true;
      this.getData(1, this.filtersUrl());
    },

    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/invoices/${this.deletingId}`)
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },

    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  mounted() {
    // if(this.$route.query && this.$route.query.customer_user_id) {
    //     this.getData(1, `customer_user_id=${this.$route.query.customer_user_id}`);
    // }else{
    //     this.getData();
    // }
    // this.getData(1);

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },

  // beforeRouteUpdate (to, from, next) {
  //     this.filters.q = to.query.q;
  //     this.search();
  //     next();
  // }
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}

.date {
  min-width: 107px;
}
</style>
