<template>
  <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/acl/roles" color="grey" text>Roles</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
  </v-container>
</template>

<script>
    export default {
        name : "Breadcrumbs"
    }
</script>