export default [
    // Authentication Routes
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Auth/Login.vue"),
        meta: {
            title: "Login"
        }
    },
    {
        path: "/password/forget",
        name: "password.forget",
        component: () => import("@/views/Auth/ForgotPassword.vue"),
        meta: {
            title: "Forget Password"
        }
    },
    {
        path: "/password/reset",
        name: "password.reset",
        component: () => import("@/views/Auth/ResetPassword.vue"),
        meta: {
            title: "Reset Password"
        }
    },
    {
        path: "/change-password",
        name: "password.change",
        component: () => import("@/views/Auth/ChangePassword.vue"),
        meta: {
            title: "Change Password",
            requiresAuth: true
        }
    },
    {
        path: "/vehicle/frontsearch",
        name: "tracking",
        component: () => import("@/views/Auth/TrackingVehicle.vue"),
        meta: {
            title: "Tracking",
        }
    },
]