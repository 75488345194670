const state = {
    counter: {
        notification_count: 0,
        complain_count: 0,
        feedback_count: 0,
        damage_claim_count: 0,
        storage_claim_count: 0,
        key_missing_claim_count: 0,
        notes_count: 0,
    },
};

const getters = {
    counter: (state) => state.counter,
};

const actions = {
    setNotificationCounter (commit, payload)  {
        commit("setNotificationCount", payload)
    },
};

const mutations = {
    setNotificationCount(state, payload) {
        state.counter.notification_count = payload;
    },

    setFeedbackCount(state, payload) {
        state.counter.feedback_count = payload;
    },

    setComplainCount(state, payload) {
        state.counter.complain_count= payload;
    },

    setDamageClaimCount(state, payload) {
        state.counter.damage_claim_count = payload;
    },

    setStorageClaimCount(state, payload) {
        state.counter.storage_claim_count = payload;
    },

    setKeyMissingClaimCount(state, payload) {
        state.counter.key_missing_claim_count = payload;
    },

    setNotesCount(state, payload) {
        state.counter.notes_count = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
