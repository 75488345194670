<template>
    <div>
        <v-dialog
                v-model="dialog"
                persistent
                max-width="500"
        >
        <v-card>
            <v-card-title v-if="msg" class="headline">
                {{ msg }}
            </v-card-title>
            <v-card-title v-else class="headline">
                Are you sure want to continue this action?
            </v-card-title>
            <v-card-text>If you confirm this action it can't be undo!</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="green darken-1"
                        text
                        @click="cancel"
                >
                    Cancel
                </v-btn>
                <v-btn
                        color="green darken-1"
                        text
                        @click="confirmed"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name: 'Confirmation',
        props: ['confirming', 'show', 'msg'],
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            cancel() {
                this.$emit('canceled');
                this.dialog = false;
            },
            confirmed() {
                this.$emit('confirmed');
                this.dialog = false;
            }
        },
        watch: {
            confirming: function(newVal, oldVal) {
                if (newVal) {
                    this.dialog = newVal;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            }
        }
    }
</script>