<template>
  <div class="vehicle pb-10">
    <breadcrumbs />

    <div
      v-if="permissions['customers.index'] == false"
        style="height: 50vh"
        class="d-flex align-center justify-center"
      >
        <h1 class="display-2 text-center">
          You are not allowed to view this page
        </h1>
    </div>

    <v-container fluid v-else>
      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Consignee Details</h3>
        <!--<v-btn text @click="edit(cosignee.id)" color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->

        <!--<manage-->
        <!--:detailspage="true"-->
        <!--@dataUpdated="show()"-->
        <!--ref="manages"-->
        <!--:show="formShow"-->
        <!--@cancelForm="formShow = false"-->
        <!--/>-->
        <!-- <v-btn color="primary" class="mx-1">Update</v-btn> -->

        <!--<v-btn-->

        <!--class="text-capitalize white&#45;&#45;text mt-2  py-5"-->
        <!--small-->
        <!--color="#2AAA79"-->
        <!--elevation="0"-->
        <!--@click="edit(cosignee.id)"-->
        <!--&gt;Update</v-btn>-->

        <manage
          v-if="permissions['consignees.update'] === true"
          :detailspage="true"
          ref="manage"
          @dataUpdated="getData()"
          :show="formShow"
          @cancelForm="formShow = null"
          :id="$route.params.id"
        />

        <v-btn
          v-if="permissions['consignees.update'] === true"
          class="text-capitalize white--text mt-2 ml-6 py-5"
          small
          color="#2AAA79"
          elevation="0"
          @click="edit($route.params.id)"
          >Update
        </v-btn>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> ID </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.id }}
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Company Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.company_name ? cosignee.company_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Consignee Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.consignee_name }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Consignee Address 1
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.consignee_address_1 }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Consignee Address 2
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.consignee_address_2 }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Country
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.country_name ? cosignee.country_name : "N/A" }}
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                State
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.state_name ? cosignee.state_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                City
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.city_name ? cosignee.city_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Zip Code
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.zip_code }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Phone
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ cosignee.phone }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!--<v-col cols="12" md="2">-->
        <!--<manageview @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/>-->
        <!--</v-col>-->
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import Breadcrumbs from "@/views/cosignees/Breadcrumbs.vue";
// import Manageview from "@/views/cosignees/Manageview.vue";
import Manage from "@/views/cosignees/Manage.vue";

export default {
  name: "Cosignee",
  components: {
    Breadcrumbs,
    Manage,
    // Manageview
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      cosignee: {},
      loading: false,
      editId: null,
      formShow: false,
    };
  },
  methods: {
    show() {
      this.loading = true;
      this.errors = {};
      Api.get(`/consignees/${this.$route.params.id}`)
        .then((res) => {
          this.cosignee = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.loading = false;
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    getData() {
      this.loading = true;
      this.errors = {};
      Api.get(`/consignees/${this.$route.params.id}`)
        .then((res) => {
          this.cosignee = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.loading = false;
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
  },
  created() {
    this.show();
  },
};
</script>
<style>
.vehicle {
  background-color: #f8f8fb;
}

.primetime {
  color: #1b455f !important;
}

.breaking {
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .small-row {
    width: 50% !important;
  }

  .show-all {
    display: none;
  }
}
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
.home {
  background-color: #f8f8fb;
}
</style>
