import router from "../router";
// Import store if you are using reactive Store/Vuex as User data source
import store from "../store";
import Api from "@/services/Api";
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'; // Ensure this is installed
Vue.use(VueCompositionAPI); // VueCompositionAPI must be used as plugin before any function, otherwise see your console if warning/errpr
import { computed } from '@vue/composition-api'; // import computed function
import { createAcl, defineAclRules } from 'vue-simple-acl/dist/vue-simple-acl.vue2';

const permissions = [
    'view-dashboard', 'view-vehicle-report', 'view-container-report', 'view-customer-report',
    'view-customer-record-report', 'view-customer-invoice-record-report', 'view-customer-title-status-report',
    'view-customer-management-report', 'view-invoices', 'locations-settings', 'roles-settings',
    'get-customers', 'update-customers', 'delete-customers', 'create-customers',
    'get-consignee', 'update-consignee', 'delete-consignee', 'create-consignee',
    'get-vehicles', 'update-vehicles', 'delete-vehicles', 'create-vehicles',
    'get-exports', 'update-exports', 'delete-exports', 'create-exports',
    'get-price', 'update-price', 'delete-price', 'create-price',
    'get-invoices', 'update-invoices', 'delete-invoices', 'create-invoices',
    'get-locations', 'update-locations', 'delete-locations', 'create-locations',
];

// const user = localStorage.getItem('user');
const user = {
    id: 1, // need to put logged user id
    // permissions: localStorage.getItem('permissions').split(',')
    permissions: permissions // need to put logged user permissions
}


const rules = () => defineAclRules((setRule) => {
    permissions.map((permission) => {
        setRule(permission, (user) => user.permissions && user.permissions.includes(permission));
    });
});

const simpleAcl = createAcl({
    user,
    rules,
    router,
});

export default simpleAcl;