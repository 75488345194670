import Location from '@/views/settings/locations/Location';
import Locations from '@/views/settings/locations/Locations';

import Country from '@/views/settings/country/country';
import Countrys from '@/views/settings/country/countrys';

import State from '@/views/settings/state/state';
import States from '@/views/settings/state/states';


import City from '@/views/settings/city/city';
import Citys from '@/views/settings/city/citys';

import Port from '@/views/settings/port/port';
import Ports from '@/views/settings/port/ports';

export default [
    {
        path: '/settings/locations',
        name: 'locations',
        component: Locations,
        meta: {
            title: "Locations",
            requiresAuth: true
        }
    },
    {
        path: "/settings/locations/:id",
        name: "locations.overview",
        component: Location,
        meta: {
            title: "Location Detail",
            requiresAuth: true
        }
    },
    {
        path: '/settings/countrys',
        name: 'countrys',
        component: Countrys,
        meta: {
            title: "Countrys"
        }
    },
    {
        path: "/settings/countrys/:id",
        name: "Country.overview",
        component: Country,
        meta: {
            title: "Country Detail"
        }
    },
    {
        path: '/settings/states',
        name: 'states',
        component: States,
        meta: {
            title: "states"
        }
    },
    {
        path: "/settings/states/:id",
        name: "states.overview",
        component: State,
        meta: {
            title: "states Detail"
        }
    },
    {
        path: '/settings/citys',
        name: 'citys',
        component: Citys,
        meta: {
            title: "citys"
        }
    },
    {
        path: "/settings/citys/:id",
        name: "citys.overview",
        component: City,
        meta: {
            title: "citys Detail"
        }
    },
    {
        path: "/ports/:id",
        name: "ports",
        component: Port,
        meta: {
            title: "Port Details",
            requiresAuth: true
        }
    },
    {
        path: "/ports",
        name: "ports",
        component: Ports,
        meta: {
            title: "Port Details",
            requiresAuth: true
        }
    },
]
