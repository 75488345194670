import axios from "axios";
import store from "@/store";
import router from "@/router";
import localforage from "localforage";

const baseDomain = process.env.VUE_APP_API_ENDPOINT;
const baseURL = `${baseDomain}/api/v1`;
const imageURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
  imageURL,
});

// set auth token if present
const token = localStorage.getItem("authtoken");
const sessionToken = sessionStorage.setItem('authtokensession' , localStorage.getItem("authtoken"));

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
  instance.defaults.headers.common["Lang"] = localStorage.getItem("locale");
}else{
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
  instance.defaults.headers.common["Lang"] = localStorage.getItem("locale");
}

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common['asl-platform'] = 'ASL_WEB_APPLICATION';

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      // AUTO LOGOUT IF 401 ERROR
      localStorage.clear();
      router.push("/login");
      // END
    }
    return Promise.reject(error);
  }
);
export default instance;
