<template>
    <v-row class="mt-1 float-right">
        <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-if="roleId !== 3"></div>
                <v-btn
                  v-else
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                    New Complain
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                  <span class="headline">{{ editing ? 'Edit' : 'New' }} Complain</span>
                    <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                      <v-row>
                        <!--<v-select-->
                            <!--item-text="title"-->
                            <!--item-value="id"-->
                            <!--:items="vehicle_vins"-->
                            <!--v-model="form.vehicle_id"-->
                            <!--label="Vehicle VIN"-->
                            <!--outlined-->
                            <!--dense-->
                            <!--:error-messages="errors.loading_type"-->
                        <!--&gt;</v-select>-->

                          <v-text-field
                                  v-model="form.subject"
                                  label="Subject"
                                  outlined
                                  dense
                                  :error-messages="errors.subject"
                          ></v-text-field>


                      </v-row>
                        <v-row>
                            <v-textarea dense outlined label="Message" class="" v-model="form.message" required
                                          :error-messages="errors.message"
                            ></v-textarea>
                            <br>
                        </v-row>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="blue darken-1"
                      text
                      @click="editing ? update() : store()"
                    >
                        {{ editing ? 'Update' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import Api from "@/services/Api";
    export default {
        props: ['id', 'show'],
        name: 'ManageComplain',
        data() {
            return {
                roleId: parseInt( JSON.parse( localStorage.getItem("user") ).role ),
                permissions: JSON.parse(localStorage.getItem('permissions')),
                dataId: this.id,
                editing: false,
                errors: {},
                vehicle_vins: [
                  { title: "Select one", id: "" },
                ],
                dialog: this.show,
                loading: false,
                form: {
                    message: '',
                    subject: '',
                    status: true,
                }
            }
        },
        methods: {
            store() {
                this.loading = true;
                this.errors = {};
                Api.post(`/complains`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        message: '',
                        vehicle_id: 0,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to create data! '+ err);
                })
            },
            async update() {
                this.loading = true;
                this.errors = {};
                await Api.put(`/complains/${this.id}`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        message: '',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to update data! '+ err);
                })

                this.editing = false;
            },
            edit() {
                if (!this.dataId) {
                    return;
                }
                this.loading = true;
                this.errors = {};
                Api.get(`/complains/${this.dataId}`).then(res => {
                    this.form = res.data.data;
                    this.dialog = true;
                    this.loading = false;
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }
                if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! '+ err);}
                })
            },
          getData() {
            Api.get(`/vehicles/dropdown`).then(res => {
              this.vehicle_vins = res.data;
            }).catch(err => {  });
          },
            cancel() {
                this.dataId = false;
                this.editing = false;
                this.dialog = false;
                this.$emit("cancelForm");
            }
        },
        watch: {
            id: function(newVal, oldVal) {
                if (newVal || oldVal) {
                    this.dataId = newVal;
                    this.edit();
                    this.editing = true;
                } else {
                    this.dataId = false;
                    this.editing = false;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            }
        },
      mounted() {
        this.getData();
      }
    }
</script>