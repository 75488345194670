<template>
  <div class="home">
    <div
      v-if="roleId == 3 || roleId == 8"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <v-container fluid class="bg-white mt-65" v-else>
      <v-row class="ml-2 mt-2">
        <h2>Customer Invoice Record</h2>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0 ml-1 pt-7">
          <div>
            <v-row>
              <v-col cols="12" md="3">
                <!--<v-autocomplete-->
                <!--dense-->
                <!--outlined-->
                <!--v-model="filters.customer_user_id"-->
                <!--:items="customersItem"-->
                <!--item-text="customer_name"-->
                <!--item-value="user_id"-->
                <!--:search-input.sync="customarUserId"-->
                <!--hide-no-data-->
                <!--label="CUSTOMER"-->
                <!--autocomplete="none"-->
                <!--:loading="loadingCustomar"-->
                <!--:error-messages="errors.customer_user_id"-->
                <!--&gt;</v-autocomplete>-->

                <v-autocomplete
                  dense
                  outlined
                  v-model="filters.customer_user_id"
                  :items="customersItem"
                  item-text="customer_name"
                  item-value="user_id"
                  label="CUSTOMER"
                  :search-input.sync="customarUserId"
                  :loading="loadingCustomar"
                  :error-messages="errors.customer_user_id"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  ref="datePicker1"
                  v-model="datePicker1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="filters.date_from"
                      label="Date from"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.date_from"
                    @input="datePicker1 = false"
                    no-title
                    scrollable
                    style="height: 350px"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="4">
                <v-menu
                  ref="datePicker2"
                  v-model="datePicker2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="filters.date_to"
                      label="Date to"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.date_to"
                    @input="datePicker2 = false"
                    no-title
                    scrollable
                    style="height: 370px"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="1">
                <v-btn :loading="filtering" color="primary" @click="search">
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-simple-table class="mt-10" v-if="!loading">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">INVOICE ID</th>
                  <th class="text-left">DATE</th>
                  <th class="text-left">TOTAL AMOUNT</th>
                  <th class="text-left">TOTAL PAID</th>
                  <th class="text-left">TOTAL DUE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in report.invoices" :key="index">
                  <td>{{ item.invoice_id }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.total_amount }}</td>
                  <td>{{ item.paid_amount }}</td>
                  <td>{{ item.due_amount }}</td>
                </tr>
                <tr>
                  <th colspan="2">TOTAL</th>
                  <th>{{ report.grand_total }}</th>
                  <th>{{ report.total_paid }}</th>
                  <th>{{ report.total_pending }}</th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <vue-content-loading :width="300" :height="100" v-else>
            <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
          </vue-content-loading>
        </v-col>
        <v-col cols="12" md="6" class="small">
          <pie-chart ref="paymentChart" :chart-data="chartData"></pie-chart>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PieChart from "@/components/charts/PieChart";
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";

export default {
  components: {
    VueContentLoading,
    PieChart,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      datePicker1: null,
      datePicker2: null,
      customers: null,
      loadingCustomar: false,
      customarUserId: "",
      customersItem: [],
      report: {},
      chartData: {
        labels: ["PAID", "UNPAID"],
        datasets: [
          {
            backgroundColor: ["#029b1c", "#ea1422"],
            data: [],
          },
        ],
      },
      filters: {
        customer_user_id: "",
        date_from: new Date(
          Date.now() -
            new Date().getTimezoneOffset() * 60000 -
            30 * 24 * 3600 * 1000
        )
          .toISOString()
          .substr(0, 10),
        date_to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      errors: {},
      loading: false,
      filtering: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.chartData.datasets[0].data = [];

      var filter = new URLSearchParams(this.filters).toString();

      let finalUrl = this.$route.path + `?` + filter;
      window.history.pushState({}, "", finalUrl);

      Api.post(`/reports/customer-invoice?${filter}`)
        .then((res) => {
          this.report = res.data;
          this.chartData.datasets[0].data = [
            res.data.total_paid.replace(",", ""),
            res.data.total_pending.replace(",", ""),
          ];
          this.$refs["paymentChart"].renderChart(this.chartData);
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    getDataWithFilter(filter) {
      Api.post(`/reports/customer-invoice?${filter}`)
        .then((res) => {
          this.report = res.data;
          this.chartData.datasets[0].data = [
            res.data.total_paid.replace(",", ""),
            res.data.total_pending.replace(",", ""),
          ];
          this.$refs["paymentChart"].renderChart(this.chartData);
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    getCustomers() {
      this.loadingCustomar = true;
      Api.get(`/customers-item`)
        .then((res) => {
          this.customersItem = [];
          this.customersItem = res.data.data;
          this.loadingCustomar = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
          this.loadingCustomar = false;
        });
    },
    search() {
      this.getData();
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
  },
  mounted() {
    this.getCustomers();
    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.$route.query.customer_user_id) {
        this.customarUserId = this.$route.query.customer_user_id;
      }

      this.getDataWithFilter(this.filtersUrl());
    }
  },
  watch: {
    customarUserId: function (newVal) {
      if (newVal) {
        this.loadingCustomar = true;
        Api.get(`/customers-item?customer_name=${newVal}`)
          .then((res) => {
            this.customersItem = res.data.data;
            this.loadingCustomar = false;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loadingCustomar = false;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}

.small {
  max-width: 300px;
  margin: 10px auto;
}
</style>
