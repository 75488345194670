import env from "dotenv";
Vue.use(env);
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueToastr from "vue-toastr";
Vue.use(VueToastr);
import store from "./store";
import acl from './services/acl';
import filters from './filters';
import i18n from './i18n';
import localforage from 'localforage';
import overviewData from '@/components/overviewData.vue'
import './assets/style.css';


localforage.config({
  driver: localforage.LOCALSTORAGE,
  storeName: 'asl'
});
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
 
Vue.component('apexchart', VueApexCharts)
Vue.component('overview-data',overviewData)
// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

    // use the language from the routing param or default language
    let language = localStorage.getItem('locale');
    if (!language) {
        language = 'en'
    }

    i18n.locale = language;
    next()
});

Vue.use(acl);


store.dispatch('auth/setToken').catch((err) => {
  store.dispatch('auth/clearAuth')
});

Vue.config.productionTip = false;

filters.forEach(f => {
  Vue.filter(f.name, f.execute);
});

Vue.mixin({
  methods: {

    // Extract the job base name.
    jobBaseName(name){
      if (!name.includes('\\')) return name;

      let parts = name.split("\\");

      return parts[parts.length - 1];
    },
  }
});





new Vue({
  mixins: [acl],
  i18n,
  store,
  router,
  watch: {
    $route(to, from) {
      if (to || from)
        document.title = to.meta.title ? `${to.meta.title} - ASL Shipping Line` : 'ASL Shipping Line';
    }
  },
  vuetify,
  render: h => h(App)
}).$mount('#app')
