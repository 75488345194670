import Vehicles from "@/views/vehicles/Vehicles";
import Vehicle from "@/views/vehicles/Vehicle";

import Weights from "@/views/vehicles/weight/Weights";
import Weight from "@/views/vehicles/weight/Weight";
import LoadingVehicle from "@/views/vehicles/LoadingVehicle";
import Notes from '@/views/vehicles/notes/notes'
import Note from '@/views/vehicles/notes/note'

export default [
  {
    path: "/vehicles",
    name: "Vehicles",
    component: Vehicles,
    meta: {
      title: "Vehicles",
      requiresAuth: true,
      permissionName: "vehicles.index",
    },
  },
  {
    path: "/vehicles/:id",
    name: "vehicles.overview",
    component: Vehicle,
    meta: {
      title: "Vehicle Overview",
      requiresAuth: true,
      permissionName: "vehicles.view",
    },
  },
  {
    path: "/weights",
    name: "weights",
    component: Weights,
    meta: {
      title: "Vehicle Weights",
      requiresAuth: true,
    },
  },
  {
    path: "/weights/:id",
    name: "weights.overview",
    component: Weight,
    meta: {
      title: "Vehicle Weight",
      requiresAuth: true,
    },
  },
  {
    path: "/loading-vehicle",
    name: "loading.overview",
    component: LoadingVehicle,
    meta: {
      title: "Loading Of Vehicle",
      requiresAuth: true,
    },
  },
  {
    path: "/notes",
    name: "notes",
    component:  Notes,
    meta: {
      title: "Notes",
      requiresAuth: true,
    },
  },
  {
    path: "/notes/:id",
    name: "notes.overview",
    component:  Note,
    meta: {
      title: "Note",
      requiresAuth: true,
    },
  },
];
