<template>

    <div class="vehicle pb-10" >
        <breadcrumbs/>

        <v-container fluid>

            <v-container class="white mt-5 pl-6 d-flex justify-space-between align-center">
                <h3 class="primetime">Price Details</h3>
              <manage
                  ref="manage"
                  @dataUpdated="getData()"
                    :show="formShow"
                    :id="$route.params.id"
                  @cancelForm="formShow = null"
              />
              <v-btn
                  v-if="permissions['prices.update'] === true"
                class="text-capitalize white--text mt-2 ml-6 py-5"
                small
                color="#2AAA79"
                elevation="0"
                @click="edit($route.params.id), (formShow = true)"
            >Update
            </v-btn>
                <!--<v-btn text @click="edit(cosignee.id)" color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
            </v-container>
            <v-container v-if="loading === true" class="white mt-1 pt-5 px-5">
                <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
            </v-container>
            <v-container v-else class="white mt-1 pt-5 px-5">
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Pricing Type
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.pricing_type_name }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Month
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.month_name }}
                            </v-col>
                        </v-row>


                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Status
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.status_name }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Description
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ prices.description ? prices.description : 'N/A'}}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    align="center"
                    class="table-wrapper mt-3"
                >
                    <v-col cols="12" md="12">
                        <iframe :src="pdfsrc" style="width: 100%;height: 1000px; border: none;" frameborder="0"></iframe>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from "@/views/prices/Breadcrumbs.vue";
    import Manage from "@/views/prices/Manage.vue";
    import Api from "@/services/Api";

    export default {
        name: "Price",
        components: {
            Breadcrumbs,
            Manage,
        },
        data() {
            return {
                permissions: JSON.parse(localStorage.getItem("permissions")),
                prices: {},
                loading: false,
                formShow: null,
                pdfsrc: null
            };
        },
        methods: {
            show() {
                this.loading = true;
                this.errors = {};
                this.getData();
            },
            edit(id) {
              this.editId = id;
              this.formShow = true;
              this.$refs.manage.editing = true;
              this.$refs.manage.edit(id);
              // this.formShowEdit = true;
            },
            getData() {
              Api.get(`/pricing/${this.$route.params.id}`).then(res => {
                this.pdfsrc = res.data.data.file_url;
                this.prices = res.data.data;
                this.loading = false;
              }).catch(err => {
                this.loading = false;
                if (err.response.status !== 401) {
                  this.$toastr.e('Failed to load data! ' + err);
                } else {
                  this.$$toastr.e(err.response.data.status)
                }
              })
            }
        },
        created() {
            this.show();
        },
        mounted() {

        }
    };
</script>
<style>
    .vehicle {
        background-color: #f8f8fb;
    }

    .primetime {
        color: #1b455f !important;
    }

    .breaking {
        overflow-wrap: break-word;
    }

    @media screen and (max-width: 600px) {
        .small-row {
            width: 50% !important;
        }

        .show-all {
            display: none;
        }
    }

    .table-wrapper {
        border: 1px solid #dddddd;
    }

    .second-column {
        border-left: 1px solid #dddddd;
    }
</style>

