import Invoice from '@/views/invoices/Invoice';
import InvoiceSummary from '@/views/invoices/InvoiceSummary';
import Invoices from '@/views/invoices/Invoices';
import InvoicePaid from '@/views/invoices/InvoicePaid';
import InvoicePartialPaid from '@/views/invoices/InvoicePartialPaid';
import InvoiceUnpaid from '@/views/invoices/InvoiceUnpaid';

export default [
    {
        path: '/invoices-summary',
        name: 'InvoiceSummary',
        component: InvoiceSummary,
        meta: {
            title: "InvoiceSummary",
        }
    },
    {
        path: '/invoices/:id',
        name: 'Invoices',
        component: Invoices,
        meta: {
            title: "Invoices",
            requiresAuth: true,
        }
    },
    {
        path: "/invoice/paid",
        name: "InvoicesPaid",
        component: InvoicePaid,
        meta: {
            requiresAuth: true,
            title: "Invoice Paid"
        }
    },
    {
        path: "/invoice/partial_paid",
        name: "Invoicespartialpaid",
        component: InvoicePartialPaid,
        meta: {
            title: "Invoice Partial Paid"
        }
    },
    {
        path: "/invoice/unpaid",
        name: "Invoicesunpaid",
        component: InvoiceUnpaid,
        meta: {
            title: "Invoice Unpaid"
        }
    },

]