<template>
  <div class="home">
    <v-container>
      <v-simple-table class="mt-14" v-if="roles">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">#</th>
          <th class="text-left">Title</th>
          <th class="text-left">Permissions</th>
          <th class="text-left">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in roles" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>
            <span v-for="permission in item.permissions">
              <v-chip class="ma-2" x-small color="secondary">{{ permission.name }}</v-chip>
            </span>
          </td>
          <td>
            <v-btn text :to="`/acl/roles/${item.id}`">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Api from "@/services/Api";
export default {
  name: 'roles',
  components: {},
  data() {
      return {
          editId: null,
          deletingId: null,
          formShow: false,
          roles: {},
          loading: false
      };
  },
  methods: {
      getData() {
          Api.get(`/acl/roles`).then(res => {
              this.roles = res.data;
          }).catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data!'+ err)
              this.loading = false;
              }else{
                this.$toastr.e(err.response.data.status);
              }
          });

          this.editId = null;
          this.deletingId = null;
      },
      edit(id) {
          this.editId = id;
          this.formShow = true;
      },
  },
  mounted() {
      this.getData();
      var roleId = JSON.parse(localStorage.getItem("user")).role
       if(roleId===0 || roleId===1) {

       }else{
         this.$router.go(-1)
       }
  }
}
</script>
<style scoped>

.home {
  background-color: #f8f8fb;
}

</style>