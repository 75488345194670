import axios from 'axios';
const _token = localStorage.getItem("authtoken");

const downloadPdf = (url, callback= null) => {

    axios
        .get(url, {
            responseType: 'blob',
            headers : {
                "Authorization" : "Bearer "+ _token
            }
        })
        .then((res) => {
            url = URL.createObjectURL(res.data);
            window.open(url);
            callback(false);
        });
};


export default downloadPdf;



