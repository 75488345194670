<template>
  <div class="home" style="margin-top: 60px;">
        <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/acl/users" color="grey" text>User Management</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
  </v-container>
    <v-container
      class="white mt-5 pl-6 d-flex justify-space-between align-center"
    >
      <h3 class="primetime">User Details</h3>

      <manage
        :detailspage="true"
        ref="manage"
        @dataUpdated="getData()"
        :show="formShow"
        @cancelForm="formShow = null"
        :id="$route.params.id"
      />

      <v-btn
        class="text-capitalize white--text mt-2 ml-6 py-5"
        small
        color="#2AAA79"
        elevation="0"
        @click="edit($route.params.id)"
        >Update
      </v-btn>
    </v-container>
    <v-container class="white mt-1 pt-5 px-5">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Username
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.username }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Email
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.email }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Role Name
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.role_name }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Status
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              <v-chip
                class="ma-2"
                small
                v-if="details.status === 'Active'"
                color="green"
                >Active</v-chip
              >
              <v-chip class="ma-2" small color="red" v-else>Inactive</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Api from "@/services/Api";
import Manage from "./Manage";

export default {
  components: { Manage },

  data: () => ({
    details: {},
    editId: null,
    formShow: false,
  }),
  methods: {
    getData() {
      Api.get(`/users/${this.$route.params.id}`)
        .then((res) => {
          this.details = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
    },
  },
  created() {
    this.getData();
  },
};
</script>
