<template>
  <div>
    <div
      v-if="permissions['consignees.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex pb-4">
          <h1 class="primetime">Consignee</h1>
          <div class="ml-auto mr-4 d-flex" cols="12" md="12">
            <v-btn
              :loading="filtering || downloading"
              color="primary"
              class="buttonleftclass mt-1"
              @click="exportConsignee"
            >
              Export Excel
            </v-btn>
            <!--                 <v-btn :loading="filtering" color="primary" class="buttonleftclass mt-1" @click="exportCustomersPDF">
                  Export PDF
                </v-btn>-->
            <manage
              class="d-inline-block mr-1 ml-3"
              @dataUpdated="getData()"
              :id="editId"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </div>
        </div>
        <v-row>
          <v-col cols="12" md="10" class="pb-0 pt-0">
            <div>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    dense
                    outlined
                    v-model="filters.consignee_global_search"
                    label="Global Search"
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                    :loading="filtering"
                    color="primary"
                    @click="manualSearch"
                  >
                    Search
                  </v-btn>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <h5>
                    Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                    {{ this.showingTo ? this.showingTo : "0" }} of
                    {{ this.count ? this.count : "0" }}
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />

        <v-simple-table class="mt-10" v-if="cosignees.data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Consignee name</th>
                <th class="text-left">Customer name</th>
                <th class="text-left">Phone</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td class="py-3">
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.consignee_name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <!--<v-autocomplete-->
                  <!--dense-->
                  <!--outlined-->
                  <!--v-model="filters.customer_user_id"-->
                  <!--:items="customersItem"-->
                  <!--item-text="customer_name"-->
                  <!--item-value="user_id"-->
                  <!--:search-input.sync="customarUserId"-->
                  <!--autocomplete="none"-->
                  <!--hide-no-data-->
                  <!--:loading="loading"-->
                  <!--style="margin-top: 25px;"-->
                  <!--@change="search"-->

                  <!--&gt;</v-autocomplete>-->

                  <v-autocomplete
                    dense
                    outlined
                    v-model="filters.customer_user_id"
                    :items="customersItem"
                    item-text="customer_name"
                    item-value="user_id"
                    :search-input.sync="customarUserId"
                    :loading="loading"
                    style="margin-top: 25px"
                    @change="manualSearch"
                    no-filter
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              data.item.customer_name +
                              ' (' +
                              data.item.legacy_customer_id +
                              ')'
                            "
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.company_name"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <!-- <v-select
                                    item-text="customer_name"
                                    item-value="user_id"
                                    :items="customersItem" v-model="filters.customer_user_id" outlined dense hide-details></v-select> -->
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.phone"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>

                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    v-if="isFilter"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr v-for="(item, index) in cosignees.data" :key="index">
                <td>{{ index + 1 + (pagination.current - 1) * 20 }}</td>
                <td>{{ item.consignee_name }}</td>
                <td>{{ item.customer_name }}</td>
                <td>{{ item.phone }}</td>

                <td>
                  <v-btn
                    v-if="permissions['consignees.view'] === true"
                    text
                    :to="`/cosignees/${item.id}`"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissions['consignees.update'] === true"
                    text
                    @click="edit(item.id)"
                    color="blue"
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissions['consignees.destroy'] === true"
                    text
                    @click="destroy(item.id)"
                    color="red"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!--<div class="text-xs-center" v-else>-->
        <!--<v-btn primary>-->
        <!--<rotate-square5></rotate-square5>-->
        <!--</v-btn>-->
        <!--</div>-->

        <vue-content-loading :width="300" :height="100" v-else>
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import Api from "@/services/Api";
import Manage from "@/views/cosignees/Manage.vue";
import { VueContentLoading } from "vue-content-loading";
import downloadFile from "@/helpers/downloadFile";

export default {
  name: "Cosignees",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      showingFrom: 0,
      showingTo: 0,
      deleteConfirmationShow: false,
      cosignees: {},
      pagination: {
        current: 1,
        total: 0,
      },
      count: 0,
      filters: {},
      customarUserId: "",
      loading: false,
      filtering: false,
      customersItem: [],
      downloading: false,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      this.loading = true;
      this.cosignees = [];
      if (!page) {
        page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + totalSortFilterQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/consignees?${totalSortFilterQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.cosignees = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    getDataWithFilter(searchQuery) {
      Api.get(`/consignees?${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.cosignees = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },
    manualSearch() {
      this.filters.page = 1;
      this.search();
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/consignees/${this.deletingId}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
    exportConsignee() {
      this.exportData(this.filtersUrl());
    },
    loadingManage (value) {
      this.downloading = value;
    },
    exportData(searchQuery = "") {
      this.loadingManage(true);
      var url = Api.defaults.baseURL + `/consignees/export/excel`;
      if (searchQuery) {
        searchQuery = `?${searchQuery}`;
        url = Api.defaults.baseURL + `/consignees/export/excel${searchQuery}`;
      }
      // window.location = url;
      downloadFile(url,
          'consignee_export',
          '.xls',
          this.loadingManage
      );
    },
    searchFunction() {
      const userId = this.filters.customer_user_id;
      if (userId) {
        this.loading = true;
        Api.get(`/customers-item?status=1&customer_name=${userId}`)
          .then((res) => {
            this.loading = false;
            this.customersItem = res.data.data;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loading = false;
            }
          });
      }
    },

    cusotmarAutoCompleteHandelar(newVal) {
      this.loading = true;
      Api.get(`/customers-item?status=1&customer_name=${newVal}`)
        .then((res) => {
          this.loading = false;
          this.customersItem = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length > 0) {
        const checkValue = Object.values(this.filters).find((item) => {
          if (item.length > 0) return true;
        });
        if (checkValue) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    customarUserId(newVal) {
      // if (newVal) {
      //   this.loading = true;
      //   Api.get(`/customers-item?status=1&customer_name=${newVal}`)
      //     .then((res) => {
      //       this.loading = false;
      //       this.customersItem = res.data.data;
      //     })
      //     .catch((err) => {
      //       if (err.response.status !== 401) {
      //         this.$toastr.e("Failed to load data!" + err);
      //         this.loading = false;
      //       }
      //     });
      // }

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
  },

  mounted() {
    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;

      if (this.filters.order_by_column) {
        this.currentSort = this.filters.order_by_column;
      }
      if (this.filters.order_by) {
        this.currentSortDir = this.filters.order_by;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData();
    }
  },
};
</script>

<style scoped>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
.home {
  background-color: #f8f8fb;
}

.buttonleftclass {
  margin-left: 5px;
}
</style>
