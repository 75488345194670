<template>
  <div>
    <div class="white mt-8 pr-4">
      <div
        class="d-flex justify-space-between flex-sm-column flex-column flex-md-row"
      >
        <div class="mt-5 ml-5">
          <h3>Vehicle Status</h3>
        </div>
        <div class="d-flex">
          <!-- <router-link to="/containers" style="text-decoration:none">
          <v-btn class="text-capitalize mr-7 mt-2 " color="#fbe8c9" elevation="0">All Containers</v-btn>
          </router-link> -->
          <!-- <manage :inventory='true'  /> -->
          <!-- <router-link to="/reports/inventory-report" style="text-decoration:none">
          
         
          </router-link> -->
        </div>
      </div>
      <vue-content-loading :width="300" :height="20" v-if="locationsLoading">
        <rect
          :x="index * 30"
          y="5"
          rx="2"
          ry="2"
          width="25"
          height="5"
          v-for="(n, index) in 10"
        />
        <rect
          :x="index * 30"
          y="15"
          rx="2"
          ry="2"
          width="25"
          height="5"
          v-for="(n, index) in 10"
        />
      </vue-content-loading>
      <div class="mt-3" v-else>
        <v-row>
          <v-col>
            <v-btn
              class="ma-2"
              outlined
              :color="location_id ? 'black' : 'primary'"
              background="location_id ? 'white' : 'indigo'"
              @click="filter(null)"
              width="100%"
            >
              All Locations
            </v-btn>
          </v-col>
          <v-col v-for="(location, index) in locations" :key="index">
            <v-btn
              outlined
              :color="location_id === location.id ? 'primary' : 'black'"
              elevation="0"
              class="ma-2"
              width="100%"
              @click="filter(location.id)"
              >{{ location.name }}</v-btn
            >
          </v-col>
        </v-row>
      </div>

      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect
          x="0"
          :y="n * 15"
          rx="4"
          ry="4"
          width="300"
          height="5"
          v-for="(n, index) in 10"
        />
      </vue-content-loading>
      <v-simple-table class="mt-3" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <!--              <th class="text-left">#</th>-->
              <th class="text-left">Sort Type</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Inventory</th>
              <th class="text-left">View</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report.status_overview" :key="index">
              <!--              <td>{{ item.status }}</td>-->
              <td>
                <a
                  target="_blank"
                  :href="`/vehicles?status=${item.status}&location=${location_id}`"
                >
                  {{ item.label }}
                </a>
              </td>
              <td>{{ item.total }}</td>
              <td>
                <manage-2 :status="item.status" :location="location_id" />

                <!-- <v-btn small color="`${item.color}`" class="px-5">Color</v-btn> -->
              </td>
              <td>
                <a
                  style="text-decoration: none"
                  :href="`vehicles?location=${location_id}&status=${item.status}`"
                  ><v-icon>mdi-eye-outline</v-icon></a
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <charts-data :location="location_id" :report="report" />
  </div>
</template>

<script>
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
// import Manage2 from './Manage2'
import ChartsData from "@/components/ChartsData.vue";
import Manage2 from "./Manage2.vue";

export default {
  components: {
    VueContentLoading,
    ChartsData,
    Manage2,
  },
  data() {
    return {
      report: {},
      locations: [],
      loading: false,
      locationsLoading: false,
      location_id: "",
    };
  },
  methods: {
    showVehiclesPage(status) {
      let routeData = this.$router.resolve({
        name: "Vehicles",
        query: {
          location: this.location_id,
          status: status,
        },
      });

      window.open(routeData.href, "_blank");
    },
    filter(location_id) {
      this.location_id = location_id;
      this.getData();
    },

    getData() {
      let url = `/dashboard`;
      if (this.location_id) {
        url = `/dashboard?location_id=${this.location_id}`;
      }
      this.loading = true;
      Api.get(url)
        .then((res) => {
          this.report = res.data;
          this.loading = false;

          this.$store.commit(
            "counter/setNotificationCount",
            res.data.counter.notification
          );
          this.$store.commit(
            "counter/setFeedbackCount",
            res.data.counter.feedback
          );
          this.$store.commit(
            "counter/setComplainCount",
            res.data.counter.complain
          );
          this.$store.commit(
            "counter/setDamageClaimCount",
            res.data.counter.damage_claims
          );
          this.$store.commit(
            "counter/setStorageClaimCount",
            res.data.counter.storage_claims
          );
          this.$store.commit(
            "counter/setKeyMissingClaimCount",
            res.data.counter.key_missing_claims
          );
        })
        .catch((err) => {
          if (err.response && err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
  },
  mounted() {
    this.locations = this.$store.state.auth.locations;
    this.getData();
  },
};
</script>

<style></style>
