<template>
  <div class="vehicle mt-5">
      <v-container class="white mt-5 pl-6 d-flex justify-space-between align-center">
          <h3 class="primetime">Title Information</h3>
<!--          <v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
      </v-container>
      <v-container class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div v-if="details.title==='1'" style="color:green">Yes</div>
                <div v-else style="color:red">No</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title Type
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                <div>{{ details.title_type_name }}</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title Amount
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.title_amount }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title Received Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.title_received_date }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.title_number }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Title State
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.title_state }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
export default {
  props:['details'],
    data() {
        return {
            titleInfo: [
                {
                    name: 'Title',
                    value: 'Yes'
                },
                {
                    name: 'Title Type',
                    value: 'Exportable'
                },
                {
                    name: 'Title Amount',
                    value: 'N/A'
                }
            ],
            titleInfo2: [
                {
                    name: 'Title Received Date',
                    value: '2021-03-05'
                },
                {
                    name: 'Title No',
                    value: 2333211552
                },
                {
                    name: 'Title State',
                    value: 'OHIO'
                }
            ]
        }
    }
}
</script>

<style scoped>

.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd
}
</style>