<template>
  <div class="vehicle pb-10">
    <breadcrumbs />
    <v-container class="mt-3" v-if="vehicles && Object.keys(vehicles).length">
      <div class="d-flex justify-end">
        <div class="d-flex">
          <!-- <claim-manage v-if="vehicles.visible_claim_button" class="mt-2 mr-4" @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/> -->
          <v-btn
            class="text-capitalize white--text mt-2 mr-2 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            :to="`/storage-claims?vehicle_id=${vehicles.id}`"
            v-if="vehicles.visible_storage_button"
            >New storage claim
          </v-btn>

          <v-btn
            class="text-capitalize white--text mt-2 mr-2 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            :to="`/key-missing-claims?vehicle_id=${vehicles.id}`"
            v-if="vehicles.visible_key_missing_button"
            >New key missing claim
          </v-btn>

          <v-btn
            class="text-capitalize white--text mt-2 mr-5 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            :to="`/claims?vehicle_id=${vehicles.id}`"
            v-if="vehicles.visible_claim_button"
            >New damage Claim
          </v-btn>
          <v-btn
            class="text-capitalize white--text mt-2 mr-5 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            :to="`/exports/${vehicles.export_id}`"
            v-if="vehicles.visible_export_button"
            >Current Export
          </v-btn>

          <manage-details
            text="Vehicle Condition"
            :lot="false"
            :id="$route.params.id"
          />

          <notes-modal
            :conversion="true"
            :id="$route.params.id"
            :vehicle_detail="{
              customer_name: vehicles.customer_name,
              vin: vehicles.vin,
              lot_number: vehicles.lot_number,
            }"
            @notestoggle="notesShow = !notesShow"
            :show="notesShow"
            v-if="roleId !== 8"
          />

          <v-btn
            v-if="permissions['vehicles.store'] == true"
            class="text-capitalize white--text mt-2 mr-2 ml-2 py-5"
            small
            color="#2AAA79"
            elevation="0"
            to="/vehicles?mode=openModal"
            >Create Vehicle
          </v-btn>
          <manage
            ref="manage"
            @dataUpdated="getData()"
            :show="formShow"
            @cancelForm="formShow = null"
          />

          <v-btn
            v-if="hasEditPermission(vehicles.status)"
            class="text-capitalize white--text mt-2 ml-1 py-5"
            small
            color="#2AAA79"
            elevation="0"
            @click="edit($route.params.id), (formShow = true)"
            >Update
          </v-btn>
        </div>
      </div>
    </v-container>
    <!-- <vehicle-info/> -->
    <div v-if="!loading">
      <vehicle-details :details="vehicles" />
      <towing-info :details="vehicles" />
      <title-info :details="vehicles" />
      <short-export-info :details="vehicles" />
    </div>
    <vue-content-loading :width="250" :height="100" v-if="loading">
      <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
    </vue-content-loading>
    <!--<attachments />-->
    <div v-if="!loading">
      <div class="vehicle" style="margin-top: 20px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">Auction Photos</h3>

          <div class="mt-5 mt-sm-0">
            <div class="mt-5 mt-sm-0">
              <v-btn
                text
                outlined
                @click="downloadSub(2, 'auction_photos')"
                class="mr-3 download-btn"
                color="blue"
                v-if="vehicles.auction_photos.length"
                :loading="downloading['auction_photos']"
                ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
                Download
              </v-btn>
              <v-btn text color="grey">
                <template>
                  <v-row justify="center">
                    <v-dialog v-model="dialog2" persistent max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" v-if="permissions['vehicles.update'] == true">
                          Add Image
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Add more attachment</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <vue-dropzone
                                  ref="vueDropzone"
                                  id="dropzone"
                                  @vdropzone-sending="sendingEvent"
                                  @vdropzone-success="storeSuccess"
                                  @vdropzone-removed-file="removeFile"
                                  @vdropzone-error="storeError"
                                  :options="dropzoneOptions"
                                ></vue-dropzone>
                                <button @click="removeAllFiles">
                                  Remove All Files
                                </button>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            Close
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="saveImage(2)"
                            :disabled="uploading_document"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </template>
              </v-btn>
            </div>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <div class="images" v-viewer>
              <img v-for="{thumbnail, url } in vehicles.auction_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
            </div>
            <!-- component -->
            <viewer :images="vehicles.auction_photos">
              <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
            </viewer>

          </v-row>
        </v-container>
      </div>
      <div class="vehicle" style="margin-top: 20px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">Pickup Photos</h3>

          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              outlined
              @click="downloadSub(3, 'pickup_photos')"
              class="mr-3 download-btn"
              :loading="downloading['pickup_photos']"
              color="blue"
              v-if="vehicles.pickup_photos.length"
              ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download
            </v-btn>
            <v-btn text color="grey">
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog3" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on"  v-if="permissions['vehicles.update'] == true">
                        Add Image
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add more attachment</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <vue-dropzone
                                ref="vueDropzone"
                                id="dropzone"
                                @vdropzone-sending="sendingEvent"
                                @vdropzone-success="storeSuccess"
                                @vdropzone-removed-file="removeFile"
                                @vdropzone-error="storeError"
                                :options="dropzoneOptions"
                              ></vue-dropzone>
                              <button @click="removeAllFiles">
                                Remove All Files
                              </button>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog3 = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveImage(3)"
                          :disabled="uploading_document"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-btn>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <div class="images" v-viewer>
              <img v-for="{thumbnail, url } in vehicles.pickup_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
            </div>
            <!-- component -->
            <viewer :images="vehicles.pickup_photos">
              <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
            </viewer>

          </v-row>
        </v-container>
      </div>
      <div class="vehicle" style="margin-top: 18px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">USA YARD PHOTOS</h3>
          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              @click="downloadSub(1, 'usa_yard_photos')"
              outlined
              class="mr-3 download-btn"
              color="blue"
              :loading="downloading['usa_yard_photos']"
              v-if="vehicles.photos.length"
            >
              <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download
            </v-btn>
            <v-btn text color="grey">
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog1" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on"  v-if="permissions['vehicles.update'] == true">
                        Add Image
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add more attachment</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <vue-dropzone
                                ref="vueDropzone"
                                id="dropzone"
                                @vdropzone-sending="sendingEvent"
                                @vdropzone-success="storeSuccess"
                                @vdropzone-removed-file="removeFile"
                                @vdropzone-error="storeError"
                                :options="dropzoneOptions"
                              ></vue-dropzone>
                              <button @click="removeAllFiles">
                                Remove All Files
                              </button>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog1 = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveImage(1)"
                          :disabled="uploading_document"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-btn>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <div class="images" v-viewer>
              <img v-for="{thumbnail, url } in vehicles.photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
            </div>
            <!-- component -->
            <viewer :images="vehicles.photos">
              <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
            </viewer>

          </v-row>
        </v-container>
      </div>
      <div class="vehicle" style="margin-top: 20px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">Arrived Photos</h3>
          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              outlined
              @click="downloadSub(4, 'arrived_photos')"
              class="mr-3 download-btn"
              color="blue"
              :loading="downloading['arrived_photos']"
              v-if="vehicles.arrived_photos.length"
              ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download
            </v-btn>
            <v-btn text color="grey">
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog4" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on"  v-if="permissions['vehicles.update'] == true">
                        Add Image
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add more attachment</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <vue-dropzone
                                ref="vueDropzone"
                                id="dropzone"
                                @vdropzone-sending="sendingEvent"
                                @vdropzone-success="storeSuccess"
                                @vdropzone-removed-file="removeFile"
                                @vdropzone-error="storeError"
                                :options="dropzoneOptions"
                              ></vue-dropzone>
                              <button @click="removeAllFiles">
                                Remove All Files
                              </button>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog4 = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveImage(4)"
                          :disabled="uploading_document"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-btn>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <div class="images" v-viewer>
              <img v-for="{thumbnail, url } in vehicles.arrived_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
            </div>
            <!-- component -->
            <viewer :images="vehicles.arrived_photos">
              <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
            </viewer>

          </v-row>
        </v-container>


      </div>
      <div class="vehicle" style="margin-top: 20px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">Document</h3>
          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              outlined
              class="mr-3 download-btn"
              color="blue"
              @click="() => downloadAttachment('documents')"
              :loading="downloading['documents']"
              v-if="vehicles.vehicle_documents.length"
              ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download
            </v-btn>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(item, index) in vehicles.vehicle_documents"
              :key="index"
            >
              <iframe
                v-if="item.type === 'pdf'"
                :src="`${item.url}`"
                style="width: 100%; height: 180px; border: none"
                frameborder="0"
              ></iframe>
              <v-img
                v-else
                class="img-border grey lighten-2"
                :src="`${item.url}`"
                aspect-ratio="2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <a
                style="text-decoration: underline"
                target="_blank"
                :href="item.url"
                >download</a
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="vehicle" style="margin-top: 20px">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
        >
          <h3 class="primetime text-uppercase">Vehicle Invoice</h3>
          <div class="mt-5 mt-sm-0">
            <v-btn
              text
              outlined
              class="mr-3 download-btn"
              color="blue"
              :loading="downloading['vehicle_invoices']"
              @click="() => downloadAttachment('vehicle_invoices')"
              v-if="vehicles.invoice_photos.length"
              ><v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
              Download
            </v-btn>
          </div>
        </v-container>

        <v-container class="white mt-1 pt-5 px-10">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(item, index) in vehicles.invoice_photos"
              :key="index"
            >
              <iframe
                v-if="item.type === 'pdf'"
                :src="`${item.url}`"
                style="width: 100%; height: 180px; border: none"
                frameborder="0"
              ></iframe>
              <v-img
                v-else
                class="img-border grey lighten-2"
                :src="`${item.url}`"
                aspect-ratio="2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <a
                style="text-decoration: underline"
                target="_blank"
                :href="item.url"
                >download</a
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="vehicle mt-5">
        <!-- <div
          class="container white mt-5 pl-6 d-flex justify-space-between align-center"
        >
          <h3 class="primetime">Select Date</h3>
        </div> -->
        <div class="container white mt-1 pt-5 px-5">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <h3>Calendar:</h3>
              <v-date-picker
                style="width: 100%"
                v-model="picker"
                color="green lighten-1"
              ></v-date-picker>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <h3>Current Status:</h3>
              <table class="table">
                <tr>
                  <td>
                    <v-chip label>{{
                      vehicles.created_at ? vehicles.created_at : "N/A"
                    }}</v-chip>
                    Car Purchased from auction
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-chip label>{{
                      vehicles.car_paid ? vehicles.car_paid : "N/A"
                    }}</v-chip>
                    Car Paid at the auction
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-chip label
                      >{{
                        vehicles.deliver_date ? vehicles.deliver_date : "N/A"
                      }}
                    </v-chip>
                    Client's storage begins
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-chip label
                      >{{
                        vehicles.manifest_date ? vehicles.manifest_date : "N/A"
                      }}
                    </v-chip>
                    Client's storage ends
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-chip label
                      >{{ vehicles.pickup_date ? vehicles.pickup_date : "N/A" }}
                    </v-chip>
                    Car left the auction
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <!-- <car-view /> -->
  </div>
</template>

<script>

import Breadcrumbs from "@/components/vehicle/Breadcrumbs.vue";
import NotesModal from "@/components/vehicle/NotesModal.vue";
import ShortExportInfo from "@/components/vehicle/ShortExportInfo.vue";
import TitleInfo from "@/components/vehicle/TitleInfo.vue";
import TowingInfo from "@/components/vehicle/TowingInfo.vue";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import { VueContentLoading } from "vue-content-loading";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Api from "@/services/Api";
import Manage from "@/views/vehicles/Manage.vue";
import VehicleDetails from "../../components/vehicle/VehicleDetails.vue";
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

import Vue from 'vue'
Vue.use(VueViewer)
import downloadFile from "@/helpers/downloadFile";
export default {
  name: "Vehicle",
  components: {
    Breadcrumbs,
    TowingInfo,
    ManageDetails,
    TitleInfo,
    VueContentLoading,
    ShortExportInfo,
    VehicleDetails,
    NotesModal,
    Manage,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      vehicles: {},
      loading: false,
      vehicleId: "",
      images: [],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      vehicleGallar: false,
      currentUrl: null,
      notesShow: false,
      currentPage: 1,
      showModal: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      errors: "",
      attrs: null,
      loadContent: false,
      formShow: false,
      index: 0,
      vehicleDocumentGallar: false,
      vehicleauctionGallar: false,
      vehiclePicupGallar: false,
      vehicleArrivedGallar: false,
      form: {
        fileUrls: [],
      },
      dropzoneOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
      },
      uploading_document: false,
      downloading: {
        'auction_photos' : false,
        'pickup_photos' : false,
        'arrived_photos' : false,
        'usa_yard_photos' : false,
        'vehicle_invoice' : false,
        'documents' : false,
      },
    };
  },
  methods: {
    saveImage(type) {
      this.loading = true;
      this.errors = {};
      this.form.type = type;
      const dialogVar = "dialog" + type;
      Api.post(`/vehicles/${this.$route.params.id}/add-more-images`, this.form)
        .then((res) => {
          this[dialogVar] = false;
          this.loading = false;
          this.removeAllFiles();
          this.getData();
        })
        .catch((err) => {
          this.removeAllFiles();
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this[dialogVar] = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to create data! " + err);
        });
    },

    onClick(i) {
      this.index = i;
    },

    hasEditPermission(status) {
      if (this.roleId === 8) {
        return (
          this.permissions["vehicles.update"] && (status === 3 || status === 1)
        );
      } else {
        return this.permissions["vehicles.update"];
      }
    },

    getData() {
      this.loading = true;
      Api.get(`/vehicles/${this.vehicleId}`)
        .then((res) => {
          this.vehicles = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Vehicle Not Found");
          this.loading = false;
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1 || 1;
    },

    removeAllFiles() {
      this.form.fileUrls = [];
      if (this.$refs.vueDropzone) {
        this.$refs.vueDropzone.removeAllFiles();
      }
    },
    sendingEvent(file, xhr, formData) {
      this.uploading_document = true;
    },
    storeSuccess(file, response) {
      this.loading = false;
      this.form.fileUrls.push(response.data);
      this.uploading_document = false;
    },
    storeError(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }
      this.$toastr.e("Failed to create data! " + message.message);
      this.uploading_document = false;
    },
    removeFile(file, error, xhr) {},
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    loadingManage (value, index) {
      this.downloading[index] = value;
    },
    downloadAttachment(index) {
      this.loadingManage(true, index);
      const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      // window.open(
      //   `${baseUrl}/api/v1/vehicles/${this.$route.params.id}/download-attachments`,
      //   "_blank"
      // );
      downloadFile(`${baseUrl}/api/v1/vehicles/${this.$route.params.id}/download-attachments`,
          'vehicle_attachment',
          '.zip',
          this.loadingManage,
          index
      );
    },
    downloadSub(e, index) {
      this.loadingManage(true, index);
      const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      // window.open(
      //   `${baseUrl}/api/v1/vehicles/${this.$route.params.id}/download-photos?type=${e}`,
      //   "_blank"
      // );
      downloadFile(`${baseUrl}/api/v1/vehicles/${this.$route.params.id}/download-photos?type=${e}`,
          'vehicle_photos',
          '.zip',
          this.loadingManage,
          index
      );
    },
    show() {
      this.$viewerApi({
        images: this.vehicles.photos,
      })
    },
  },
  mounted() {
    console.log(this.vehicles);
    // this.getData();
    this.dropzoneOptions.url = `${Api.defaults.baseURL}/vehicles/${this.$route.params.id}/photos-upload`;
  },
  created() {
    this.vehicleId = this.$route.params.id;
    this.form.vehicleId = this.$route.params.id;
    this.getData();
  },
  computed: {
    toBeShown() {
      return this.images.slice(0, this.currentPage * 4);
    },
    totalPages() {
      return Math.ceil(this.images.length / 4);
    },
    baseurls() {
      return process.env.VUE_APP_API_ENDPOINT;
    },
  },
};
</script>
<style>
.vehicle {
  background-color: #f8f8fb;
}

.primetime {
  color: #1b455f !important;
}

.breaking {
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .small-row {
    width: 50% !important;
  }

  .show-all {
    display: none;
  }
}

.show-all-container {
  height: 100%;
}

.show-all {
  color: #385f68;
  font-weight: 700;
  text-decoration: none;
}

.show-all::after {
  content: "";
  display: block;
  margin: auto;
  width: 0;
  height: 2px;
  /* margin-top: 10px; */
  background: #385f78;
  transition: all 0.2s linear;
}

.show-all:hover::after {
  width: 100%;
}

.img-border {
  border-radius: 5px;
}
table.table {
  width: 100% !important;
  padding: 4px;
}
table.table td {
  border: 1px solid black;
  padding: 19px;
}
</style>
