<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <div v-if="permissions['vehicles.store'] == false"></div>
        <div v-else>
            <v-btn
              v-if="!custom"
            class="text-capitalize white--text mt-1 mr-5 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            @click="(dialog = true), emptyForm(), (editing = false), edit(id)"
            >Update
          </v-btn>
          
          <v-btn
            v-else
            class=""
            color="primary"
            @click="(dialog = true), emptyForm(), (editing = false)"
            dark
            v-bind="attrs"
            v-on="on"
            >New vehicle</v-btn
          >
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom:1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "Create" }}  Vehicle Weight</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
              <v-text-field
                  v-model="form.year"
                  label="Year"
                  :error-messages="errors.year"
                  outlined
                  dense
                ></v-text-field>

                 <v-text-field
                  v-model="form.maker"
                  label="Maker"
                  :error-messages="errors.maker"
                  outlined
                  dense
                ></v-text-field>

                 <v-text-field
                  v-model="form.model"
                  label="Model"
                  :error-messages="errors.model"
                  outlined
                  dense
                ></v-text-field>
                 <v-text-field
                  v-model="form.weight"
                  label="Weight"
                  :error-messages="errors.weight"
                  outlined
                  dense
                ></v-text-field>
           
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="cancel()">
            Close
          </v-btn> -->
          <v-btn
            color="blue darken-1"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import axios from "axios";

export default {
  props: ["show", "custom", "id"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      defaultDate: "2018",
      DatePickerFormat: "yyyy",
      date: "",
      menu: false,
      modal: false,
      menu2: false,
      id: null,
      ids:'',
      editing: false,
      errors: {},
      dialog: this.show,
      loading: false,
     
      form: {
       
      },

     
    };
  },
  methods: {
  

    emptyForm() {
      this.form = {
       
      };
    },
    store() {
      this.loading = true;
      this.errors = {};
     

      Api.post(`/vehicle-weights`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("cancelForm");
           this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to create data! " + err);
        });

      this.editing = false;
    },
    

    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/vehicle-weights/${this.ids}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e("Failed to update data! " + err);
        });

      this.editing = false;
    },
    edit(id) {
      this.emptyForm();
      this.form = {};
      if (!id) {
        return;
      }
      this.ids = id;
      this.loading = true;
      this.editing = true;
      this.errors = {};
      Api.get(`/vehicle-weights/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    cancel() {
      this.editing = false;
      this.dialog = false;
      this.emptyForm();
      this.form = {};
      this.errors = {};
      this.$emit("cancelForm");
    },

   
   

   

   
   
   
  },
  created() {
    this.emptyForm();
  },
  watch: {
    show: function(newVal, oldVal) {
      this.dialog = newVal;
    },
    "form.customer_user_id": function(newVal) {
      this.changeCustomer();
    },
    customerUserId: function(newVal) {
      if (newVal) {
        this.loading = true;
        Api.get(`/customers-item?customer_name=${newVal}`)
          .then((res) => {
            this.customersItem = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
            }
          });
      }
    },
  },
  mounted() {
    // this.getCustomerItem();
    // this.getVehicleCheckOptions();
    // this.getVehicleConditionOptions();
    // this.colorAutoCompleteHandler("");
    // this.locationAutoCompleteHandler("");
  },
};
</script>
