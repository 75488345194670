<template>
  <v-row class="mt-1 float-right">
    <v-dialog v-model="dialog" max-width="85%" scrollable persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="permissions['prices.store'] && !id"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="emptyForm(), (editing = false), (form.upload_file = '')"
        >
          New Invoice
        </v-btn>
      </template>
      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} Invoice</span>
          <v-btn
            class="ml-auto"
            color="blue darken-1"
            text
            @click="cancel(), (editing = true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" md="12">
                <input
                  type="file"
                  ref="file"
                  accept="text/xml"
                  v-on:change="XMLReader()"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.export_id"
                  :items="containerItem"
                  item-text="container_number"
                  item-value="id"
                  :search-input.sync="containerNumber"
                  hide-no-data
                  label="Container Number"
                  autocomplete="none"
                  placeholder="Container Number"
                  :error-messages="errors.export_id"
                ></v-autocomplete>

                <!--<v-text-field v-model="form.container_ar_number" label="Container AR Number" outlined dense :error-messages="errors.container_ar_number"></v-text-field>-->
                <v-text-field
                  v-model="form.total_amount"
                  label="Total Amount"
                  outlined
                  dense
                  :error-messages="errors.total_amount"
                ></v-text-field>
                <v-text-field
                  v-model="form.adjustment_damaged"
                  label="Damaged"
                  outlined
                  dense
                  :error-messages="errors.adjustment_damaged"
                ></v-text-field>
                <v-text-field
                  v-model="form.adjustment_discount"
                  label="Discount"
                  outlined
                  dense
                  :error-messages="errors.adjustment_discount"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <!--<v-autocomplete-->
                <!--dense-->
                <!--outlined-->
                <!--v-model="form.customer_user_id"-->
                <!--:items="customersItem"-->
                <!--item-text="customer_name"-->
                <!--item-value="user_id"-->
                <!--:search-input.sync="customarUserId"-->
                <!--hide-no-data-->
                <!--label="CUSTOMER"-->
                <!--placeholder="Customer"-->
                <!--autocomplete="none"-->
                <!--:loading="customarIdLoading"-->
                <!--&gt;</v-autocomplete>-->

                <v-autocomplete
                  dense
                  outlined
                  v-model="form.customer_user_id"
                  :items="customersItem"
                  label="CUSTOMER"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customarUserId"
                  :loading="customarIdLoading"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="form.paid_amount"
                  label="Paid Amount"
                  outlined
                  dense
                  :error-messages="errors.paid_amount"
                ></v-text-field>
                <v-text-field
                  v-model="form.adjustment_storage"
                  label="Storage"
                  outlined
                  dense
                  :error-messages="errors.adjustment_storage"
                ></v-text-field>
                <v-text-field
                  v-model="form.adjustment_other"
                  label="Other"
                  outlined
                  dense
                  :error-messages="errors.adjustment_other"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="3" md="3"> Upload Invoice </v-col>
                  <v-col cols="12" md="12">
                    <vue-dropzone
                      ref="upload_invoice"
                      id="dropzone_upload_invoice"
                      @vdropzone-sending="sendingEvent"
                      @vdropzone-success="storeSuccess"
                      @vdropzone-removed-file="removeUploadInvoice"
                      @vdropzone-error="storeError"
                      :options="dropzoneOptions_upload_invoice"
                    ></vue-dropzone>
                    <button @click="removeAllUploadFiles">Remove Files</button>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="3" md="3"> Clearance Invoice </v-col>
                  <v-col cols="12" md="12">
                    <vue-dropzone
                      ref="clearance_invoice"
                      id="dropzone_clearance_invoice"
                      @vdropzone-sending="sendingEvent"
                      @vdropzone-success="storeSuccessClearance"
                      @vdropzone-removed-file="removeClearanceInvoice"
                      @vdropzone-error="storeError"
                      :options="dropzoneOptions_clearance_invoice"
                    ></vue-dropzone>
                    <button @click="removeAllClearanceFiles">
                      Remove Files
                    </button>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="3" md="3"> Documents </v-col>
                  <v-col cols="12" md="12">
                    <vue-dropzone
                      ref="documents"
                      id="dropzone_documents"
                      @vdropzone-removed-file="removeInvoiceDocument"
                      @vdropzone-sending="sendingEvent"
                      @vdropzone-success="storeSuccessDocuments"
                      @vdropzone-error="storeError"
                      :options="dropzoneOptions_documents"
                    ></vue-dropzone>
                    <button @click="removeAllDocumentsFiles">
                      Remove Files
                    </button>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  rows="3"
                  cols="4"
                  name="input-7-4"
                  label="Note"
                  v-model="form.note"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            color="white"
            text
            class="primary"
            :disabled="loading"
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Api from "@/services/Api";
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const _token = "Bearer " + localStorage.getItem("authtoken");
export default {
  props: ["id", "show"],
  name: "Manage",
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      dataId: this.id,
      editing: false,
      customarIdLoading: false,
      customarUserId: "",
      containerNumber: "",
      errors: {},
      dialog: this.show,
      form: {
        total_amount: "",
        adjustment_damaged: "",
        adjustment_discount: "",
      },
      dropzoneOptions_upload_invoice: {
        url: Api.defaults.baseURL + "/invoices/document-upload",
        thumbnailWidth: 150,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 1,
        headers: { Authorization: _token },
      },
      dropzoneOptions_clearance_invoice: {
        url: Api.defaults.baseURL + "/invoices/document-upload",
        thumbnailWidth: 150,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 1,
        headers: { Authorization: _token },
      },
      dropzoneOptions_documents: {
        url: Api.defaults.baseURL + "/invoices/document-upload",
        thumbnailWidth: 150,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 10,
        headers: { Authorization: _token },
      },
      customersItem: [],
      containerItem: [],
      file: "",
      AuthStr: "Bearer " + localStorage.getItem("authtoken"),
      baseDomain: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  methods: {
    XMLReader(file) {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      Api.post(`/invoices/xml-upload`, formData)
        .then((res) => {
          this.form.total_amount = res.data.data.amount;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e(
            "Failed to create data! One or more required field missing. "
          );
        });
    },

    removeAllDocumentsFiles() {
      if (this.$refs.documents) {
        this.$refs.documents.removeAllFiles();
      }
    },

    removeAllUploadFiles() {
      if (this.$refs.upload_invoice) {
        this.$refs.upload_invoice.removeAllFiles();
      }
    },

    removeAllClearanceFiles() {
      if (this.$refs.clearance_invoice) {
        this.$refs.clearance_invoice.removeAllFiles();
      }
    },

    removeAllInvoiceFiles() {
      if (this.$refs.documents) {
        this.$refs.documents.removeAllFiles();
      }
      if (this.$refs.upload_invoice) {
        this.$refs.upload_invoice.removeAllFiles();
      }
      if (this.$refs.clearance_invoice) {
        this.$refs.clearance_invoice.removeAllFiles();
      }
    },

    sendingEvent(file, xhr, formData) {
      // formData.append('image_id', 12);
      // for (let key in this.form) {
      //   formData.append(key, this.form[key]);
      // }
    },

    storeSuccess(file, response) {
      this.loading = false;
      this.form.upload_invoice = response.data;
    },
    storeSuccessClearance(file, response) {
      this.loading = false;
      this.form.clearance_invoice = response.data;
    },
    storeSuccessDocuments(file, response) {
      this.loading = false;
      this.form.documents.push(response.data);
    },
    removeInvoiceDocument(file, error, xhr) {
      let index = this.form.documents
        ? this.form.documents.findIndex((x) => x.id === file.id)
        : -1;
      this.form.documents.splice(index, 1);
    },
    removeUploadInvoice(file, error, xhr) {
      this.form.upload_invoice = "";
    },
    removeClearanceInvoice(file, error, xhr) {
      this.form.clearance_invoice = "";
    },
    storeError(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    emptyForm() {
      this.form = {
        clearance_invoice: "",
        customer_user_id: "",
        adjustment_damaged: "",
        adjustment_discount: "",
        documents: [],
        export_id: "",
        note: "",
        adjustment_other: "",
        paid_amount: "",
        adjustment_storage: "",
        total_amount: "",
        upload_invoice: "",
      };
      this.removeAllInvoiceFiles();
    },
    store() {
      this.loading = true;
      this.errors = {};

      Api.post(`/invoices`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
          }
          this.loading = false;

          this.$toastr.e(
            "Failed to create data! One or more required field missing. "
          );
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/invoices/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.$emit("dataUpdated");
          this.emptyForm();
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e(
              "Failed to update data! One or more required field missing."
            );
          } else {
            this.$toastr.e(
              err.response?.data?.message ??
                "Failed to update data! One or more required field missing."
            );
          }
          this.loading = false;
        });

      this.editing = false;
    },
    edit() {
      this.removeAllInvoiceFiles();

      if (!this.dataId) {
        return;
      }
      this.loading = true;
      this.errors = {};
      Api.get(`/invoices/${this.dataId}`)
        .then((res) => {
          this.form = res.data.data;

          this.customerName(this.form.customer_user_id);

          if (this.form.export_id) {
            this.getContainerItem(this.form.export_id);
          }

          this.form.documents.map((value) => {
            let file = {
              id: value.id,
              size: value.size,
              url: value.url,
              type: value.type,
            };
            let ext = file.url.split(".").pop();
            let url = file.url;
            if (ext === "pdf") {
              url = Api.defaults.imageURL + "/images/pdf_thumbnail.jpg";
            }

            this.$refs.documents.manuallyAddFile(file, url, null, null, {
              dontSubstractMaxFiles: false,
              addToFiles: true,
            });
          });

          if (res.data.data.upload_invoice_item) {
            let file = {
              size: res.data.data.upload_invoice_item.size,
              name: res.data.data.upload_invoice_item.name,
              type: res.data.data.upload_invoice_item.type,
            };
            this.$refs.upload_invoice.manuallyAddFile(
              file,
              res.data.data.upload_invoice_item.url
            );
          }

          if (res.data.data.clearance_invoice_item) {
            let file = {
              size: res.data.data.clearance_invoice_item.size,
              name: res.data.data.clearance_invoice_item.name,
              type: res.data.data.clearance_invoice_item.type,
            };
            this.$refs.clearance_invoice.manuallyAddFile(
              file,
              res.data.data.clearance_invoice_item.url
            );
          }

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.status !== 401) {
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },
    getContainerItem(id = null) {
      this.loading = true;
      // Api.get(`/containers-search?id=${id}`).then(res => {
      //     this.containerItem = res.data.data;
      //     this.loading = false;
      // }).catch(err => {
      //     if (err.response.status !== 401) {
      //       this.$toastr.e("Failed to load data!" + err);
      //       this.loading = false;
      //     }
      // });

      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/containers-search?id=${id}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.containerItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    cusotmarAutoCompleteHandelar(e) {
      this.customarIdLoading = true;
      Api.get(`/customers-item?customer_name=${e}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.customarIdLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.customarIdLoading = false;
            this.$toastr.e("Failed to create data!");
          }
        });
    },
    containerAutoCompleteHandler(e) {
      Api.get(`/containers-search?with_ar_number=true&q=${e}`)
        .then((res) => {
          this.containerItem = [];
          this.containerItem = res.data.data;
        })
        .catch(() => {});
    },
    customerName(id) {
      Api.get(`/customers-item?user_id=${id}`)
        .then((res) => {
          this.customersItem = [];
          this.customersItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },

    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  mounted() {},
  created() {
    this.getContainerItem();
    this.emptyForm();
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal || oldVal) {
        this.dataId = newVal;
        this.edit();
        this.editing = true;
      } else {
        this.editing = false;
      }
    },
    show: function (newVal) {
      this.dialog = newVal;
    },
    customarUserId: function (newVal) {
      //   if (newVal) {
      //     this.cusotmarAutoCompleteHandelar(newVal);
      //   }
      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
    containerNumber: function (newVal) {
      if (newVal) {
        this.containerAutoCompleteHandler(newVal);
      }
    },
  },
};
</script>
