<template>
  <div class="home">
    <div
      v-if="roleId == 3 || roleId == 8"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <v-container fluid class="bg-white mt-65" v-else>
      <v-row class="ml-2 mt-2">
        <h2>Customer Management Report</h2>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0 ml-1 pt-7">
          <div>
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                  dense
                  outlined
                  :items="[20, 50, 75, 100]"
                  v-model="pagination.per_page"
                  :label="`Showing ${pagination.per_page} Items`"
                  @change="getData(1)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  @keypress.enter="search"
                  dense
                  outlined
                  v-model="filters.report_global_search"
                  @keyup.enter.native="search"
                  label="CUSTOMER ID, NAME, EMAIL, COMPANY NAME"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn :loading="filtering" color="primary" @click="search">
                  Search
                </v-btn>
                <v-btn
                  :loading="filtering"
                  color="primary"
                  @click="exportData"
                  class="ml-5"
                >
                  Export
                </v-btn>
              </v-col>

              <v-col cols="12" md="2" class="text-right">
                <h5>
                  Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                  {{ this.showingTo ? this.showingTo : "0" }} of
                  {{ this.count ? this.count : "0" }}
                </h5>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-simple-table class="mt-10" v-if="!loading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">CUSTOMER</th>
              <th class="text-left">CUSTOMER User ID</th>
              <th class="text-left">AMOUNT PENDING FOR PAYMENT</th>
              <th class="text-left">ON THE WAY</th>
              <th class="text-left">ON HAND</th>
              <th class="text-left">CAR Manifest</th>
              <th class="text-left">CAR Shipped</th>
              <th class="text-left">Value of Total Cars</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report" :key="index">
<!--              <td>{{ item.customer_name }}</td>-->
              <td>
                <router-link
                    target="_blank"
                    style="text-decoration: none"
                    :to="`/customers/${item.id}`"
                >
                  {{ item.customer_name }}
                </router-link>
              </td>
              <td>{{ item.legacy_customer_id }}</td>
              <td>{{ item.pending_amount }}</td>
<!--              <td>{{ item.on_the_way }}</td>-->
              <td>
                <router-link
                    target="_blank"
                    style="text-decoration: none"
                    :to="`/vehicles?status=3&customer_user_id=${item.user_id}&page=1`"
                >
                  {{ item.on_the_way }}
                </router-link>
              </td>
<!--              <td>{{ item.on_hand }}</td>-->
              <td>
                <router-link
                    target="_blank"
                    style="text-decoration: none"
                    :to="`/vehicles?status=1&customer_user_id=${item.user_id}&page=1`"
                >
                  {{ item.on_hand }}
                </router-link>
              </td>
<!--              <td>{{ item.manifest_cars }}</td>-->
              <td>
                <router-link
                  target="_blank"
                  style="text-decoration: none"
                  :to="`/vehicles?status=2&customer_user_id=${item.user_id}&page=1`"
                >
                {{ item.manifest_cars }}
                </router-link>
              </td>
<!--              <td>{{ item.shipped_cars }}</td>-->
              <td>
                <router-link
                    target="_blank"
                    style="text-decoration: none"
                    :to="`/vehicles?status=4&customer_user_id=${item.user_id}&page=1`"
                >
                  {{ item.shipped_cars }}
                </router-link>
              </td>
              <td>{{ item.total_value_of_vehicles }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";

export default {
  components: {
    VueContentLoading,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      report: {},
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      filters: {
        report_global_search: "",
      },
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      userId: JSON.parse(localStorage.getItem("user")).id,
    };
  },
  methods: {
    getData(page) {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }

      this.filters.page = page;
      this.filters.limit = this.pagination.per_page;

      var filter = new URLSearchParams(this.filters).toString();

      let finalUrl = this.$route.path + `?` + filter;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/reports/customer-management?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    getDataWithFilter(filter) {
      Api.get(`/reports/customer-management?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      this.getData(1);
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    exportData() {
      window.location =
        Api.defaults.baseURL +
        `/reports/customer-management/export?report_global_search=${this.filters.report_global_search}&auth_user_id=${this.userId}`;
    },
  },
  mounted() {
    // this.getData();
    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.$route.query.customer_user_id) {
        this.customarUserId = this.$route.query.customer_user_id;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
