<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="60%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="!id && (roleId === 0 || roleId === 1)"
          @click="newModalOpen"
        >
          Create Page
        </v-btn>
      </template>

      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} Page</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
              v-model="form.title"
              label="Title"
              :error-messages="errors.title"
              outlined
              dense
            ></v-text-field>

            <vue-editor v-model="form.content"></vue-editor>
            <br />
            <!-- <v-select
              :items="statusFilter"
              item-text="text"
              item-value="id"
              v-model="form.status"
              outlined
              dense
              hide-details
            ></v-select> -->

            <v-radio-group v-model="form.status" row>
              <v-radio label="Active" :value="1"></v-radio>
              <v-radio label="Inactive" :value="2"></v-radio>
            </v-radio-group>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/services/Api";
import { VueEditor } from "vue2-editor";

export default {
  props: ["show", "id"],
  components: {
    VueEditor,
  },
  data() {
    return {
      roleId: parseInt(JSON.parse(localStorage.getItem("user")).role),
      dialog: false,
      editing: false,
      datePicker11: "",
      form: {
        title: "",
        content: "",
        status: 1,
      },
      errors: {},
      loading: false,
      editId: "",
    };
  },
  methods: {
    newModalOpen() {
      this.errors = {};
      this.form = {
        title: "",
        content: "",
        status: 1,
      };
      this.editing = false;
    },
    cancel() {
      this.dialog = false;
    },
    store() {
      Api.post(`/settings/pages`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });
    },
    edit(id) {
      this.errors = {};
      if (!id) false;
      this.editing = true;
      this.dialog = true;
      this.editId = id;
      Api.get(`/settings/pages/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    update() {
      Api.put(`/settings/pages/${this.editId}`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
  },
  computed: {},
  created() {},
  watch: {},
};
</script>
