<template>
  <div class="home mt-65">
    <overview-data :id="$route.params.id" />
    <v-container fluid class="bg-white mt-65">
      <div class="d-flex pb-4">
        <h1 class="primetime">Invoice</h1>

        <div class="ml-auto" cols="12" md="3">
          <v-btn
            :loading="filtering || downloading"
            color="primary"

            class="buttonleftclass mt-1 ml-10"
            @click="exportInvoiceExcel"
          >
            Export
          </v-btn>
          <manage
            class="mr-1 mt-1 ml-3"
            @dataUpdated="getData(1)"
            :id="editId"
            :show="formShow"
            @cancelForm="formShow = false"
          />

          <!-- <invoice-details-modal :show="detaisModal"/>
                             <line-chart/> -->
        </div>
        <div class="ml-3 mr-3" cols="12" md="3">
          <manage-bar
            @dataUpdated="getData(1)"
            :id="this.$route.params.id"
            :show="formShow"
            @cancelForm="formShow = false"
          />
        </div>
        <div class="ml-5 mr-3" cols="12" md="3">
          <manage-line
            @dataUpdated="getData(1)"
            :id="this.$route.params.id"
            :show="formShow"
            @cancelForm="formShow = false"
          />
        </div>
      </div>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0">
          <div>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  @keypress.enter="search"
                  dense
                  outlined
                  v-model="filters.invoice_global_search"
                  label="Global Search"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn
                  :loading="filtering"
                  color="primary"
                  style="margin-right: 5px"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <confirmation
        :confirming="deletingId"
        @confirmed="remove"
        :show="deleteConfirmationShow"
        @canceled="deleteConfirmationShow = false"
      />

      <v-simple-table
        class="mt-10 mm"
        v-if="invoices.invoices || invoices.summary"
      >
        <template v-slot:default style="l">
          <thead>
            <tr>
              <th class="text-left">Invoice Id</th>
              <th class="text-left" style="min-width: 105px">Date</th>
              <th class="text-left">Company Name</th>
              <th class="text-left">Container No</th>
              <th class="text-left">Ar No</th>
              <th class="text-left">Total Amount</th>
              <th class="text-left">Damage</th>
              <th class="text-left">Storage</th>
              <th class="text-left">Discount</th>
              <th class="text-left">Other</th>
              <th class="text-left">Paid Amount</th>
              <th class="text-left">Balance</th>
              <th class="text-left">Note</th>
              <th class="text-left">Documents</th>
              <th class="text-center" style="width: 300px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in invoices.invoices" :key="item.name">
              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td style="min-width: 240px">{{ item.company_name }}</td>
              <td>{{ item.container_number }}</td>
              <td>{{ item.ar_number }}</td>
              <td class="text-right">{{ item.total_amount }}</td>
              <td class="text-right">{{ item.adjustment_damaged }}</td>
              <td class="text-right">{{ item.adjustment_storage }}</td>
              <td class="text-right">{{ item.adjustment_discount }}</td>
              <td class="text-right">{{ item.adjustment_other }}</td>
              <td class="text-right">{{ item.paid_amount }}</td>
              <td class="text-right">{{ item.balance }}</td>
              <td>{{ item.note }}</td>
              <td>
                <div v-for="(invoice, i) in item.documents" :key="i">
                  <v-btn small class="ma-1" @click="invoiceUrl(invoice.url)"
                    >{{ invoice.label }}
                  </v-btn>
                </div>
              </td>
              <td>
                <div class="d-flex float-right">
                  <a
                    v-if="item.usa_invoice"
                    :href="`${item.usa_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >USA INV</a
                  >
                  <a
                    v-if="item.clearance_invoice"
                    :href="`${item.clearance_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >CLEARANCE</a
                  >
                  <a
                    v-if="item.upload_invoice"
                    :href="`${item.upload_invoice}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >DXB INV</a
                  >
                  <v-btn
                    v-if="permissions['invoices.update'] !== false"
                    icon
                    color="primary"
                    @click="edit(item.id)"
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissions['invoices.destroy'] !== false"
                    icon
                    color="red"
                    @click="destroy(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.grand_total : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_damage : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_storage : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_discount : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_other : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_paid : ""
                }}</strong>
              </td>
              <td class="text-right">
                <strong>{{
                  invoices.summary ? invoices.summary.total_balance : ""
                }}</strong>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current, filtersUrl())"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import overviewData from "@/components/overviewData.vue";
import Api from "@/services/Api";
import Manage from "@/views/invoices/Manage.vue";
import ManageBar from "@/views/invoices/ManageBar.vue";
import ManageLine from "@/views/invoices/ManageLine.vue";

import { VueContentLoading } from "vue-content-loading";
import downloadFile from "@/helpers/downloadFile";

export default {
  name: "Invoices",
  components: {
    Confirmation,
    Manage,
    ManageBar,
    ManageLine,
    VueContentLoading,
    overviewData,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      detaisModal: true,
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      invoices: {},
      pagination: {
        current: 1,
        total: 0,
      },
      filters: {},
      loading: false,
      filtering: false,
      downloading: false,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      let id = this.$route.params.id;
      // let url = `/invoices?customer_user_id=${id}`;
      // if (searchQuery) {
      //   url += `&${searchQuery}`;
      // }
      this.loading = true;

      this.filters.page = page;
      this.filters.customer_user_id = id;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`invoices?${finalSearchQuery}`)
        .then((res) => {
          this.invoices = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
      this.formShow = false;
      this.editId = null;
    },

    getDataWithFilter(finalSearchQuery) {
      Api.get(`invoices?${finalSearchQuery}`)
        .then((res) => {
          this.invoices = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      if (this.filters.invoice_global_search) {
        this.getData(1, this.filtersUrl());
      } else {
        delete this.filters.invoice_global_search;
        this.getData(1);
      }
    },

    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    invoiceUrl(e) {
      window.open(e, "_blank");
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/invoices/${this.deletingId}`)
        .then((res) => {
          this.getData(1);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },

    clearFilter() {
      this.filters = {};
      this.getData(1);
    },

    loadingManage (value) {
      this.downloading = value;
    },

    exportInvoiceExcel() {
      this.loadingManage(true);
      if (this.$route.params.id) {
        let url =
          Api.defaults.baseURL +
          `/invoices/${this.$route.params.id}/export-excel`;
        if (this.filtersUrl()) {
          var searchQuery = `?${this.filtersUrl()}`;
          url =
            Api.defaults.baseURL +
            `/invoices/${this.$route.params.id}/export-excel${searchQuery}`;
        }
        // window.location = url;
        downloadFile(url,
            'invoice_export',
            '.xls',
            this.loadingManage
        );

      }
    },
  },
  mounted() {
    // this.getData(1);
    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
};
</script>

<style>
.mm .v-data-table__wrapper table {
  width: 114% !important;
  border-spacing: 0;
}
.home {
  background-color: #f8f8fb;
}
.link-btn {
  text-decoration: none;
}
.link-btn:hover {
  text-decoration: underline;
}
</style>
