<template>
  <div class="vehicle home">
       <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/feedbacks" color="grey" text>Feedback</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
  </v-container>
      <v-container   class="white mt-5 pl-6 d-flex justify-space-between align-center">
      <h3 class="primetime">Feedback Details</h3>
      </v-container>
      <v-container  class="white mt-1 pt-5 px-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" >
            <v-row
                no-gutters

                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Customer
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{feedback.customer_name}}
              </v-col>
            </v-row>
            <v-row
              no-gutters

              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Feedback
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
               {{feedback.message}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Admin Reply
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{feedback.note}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                  Created At
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{feedback.created_at}}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/settings/locations/Breadcrumbs.vue";
 import Api from "@/services/Api";

export default {
    name: "Feedback",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            feedback: {},
            loading: false,
            errors:{}
        };
    },
    methods: {
       getData(){
                this.errors = {};
                Api.get(`/feedbacks/${this.$route.params.id}`).then(res => {
                    this.feedback = res.data.data;

                    if(this.feedback.total_unread_feedback ) {
                        localStorage.setItem("feedback_count", this.feedback.total_unread_feedback);
                    }

                }).catch(err => {
                  if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! ' + err);
                  }
                })
       }
    },
    mounted() {
     this.getData()
    }
};
</script>