<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      :close-on-click="false"
      :nudge-width="200"
      offset-x
      offset-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <!--<v-btn-->
          <!--:x-large="$vuetify.breakpoint.smAndUp ? true : false"-->
          <!--fab-->
          <!--v-bind="attrs"-->
          <!--v-on="on"-->
          <!--class="headline font-weight-bold mr-5 mr-sm-10 mb-5"-->
          <!--:class="{'popup&#45;&#45;active' : isActive}"-->
          <!--@click="popupActive"-->
        <!--&gt;-->
          <!--ASL -->
        <!--</v-btn>-->
        <v-btn
          :x-large="$vuetify.breakpoint.smAndUp ? true : false"
          fab
          v-bind="attrs"
          class="headline font-weight-bold mr-5 mr-sm-10 mb-5"
        >
          ASL
        </v-btn>
      </template>

      <v-card max-width="960" height="700" style="overflow-y: scroll">
        <v-card-title class="d-flex justify-space-between">
          ASL Shipping Line
          <v-icon
            class="card-header-icon d-none d-sm-flex"
            @click.stop="popupActive"
          >
          mdi-close-thick
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="d-flex align-center">
            <v-avatar color="#2BA5B6" size="32">
              <v-icon dark small>
                mdi-phone
              </v-icon>
            </v-avatar>
            <p class="mb-0 text-decoration-underline ml-3">Contact</p>
          </div>
          <v-row no-gutters class="mt-4">
            <v-col cols="auto">
              <v-subheader class="px-0 body-1 font-weight-bold"><v-icon class="mr-3">mdi-phone-hangup</v-icon> Public relation (Customer Service)</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5" v-for="(item, i) in cs" :key="i" class="my-1 link-text">
              <v-icon :class="$vuetify.breakpoint.smAndUp ? `${item.class}` : ''" color="#2BA5B6">{{ item.icon }}</v-icon> {{ item.title }}
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-subheader class="px-0 body-1 font-weight-bold"><v-icon class="mr-3">mdi-account-tie-voice</v-icon>  Technical Support (Software Engineer)</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5" v-for="(item, i) in ts" :key="i" class="my-1 link-text">
              <v-icon :class="$vuetify.breakpoint.smAndUp ? `${item.class}` : ''" color="#2BA5B6">{{ item.icon }}</v-icon> {{ item.title }}
            </v-col>
          </v-row>
          <v-subheader class="px-0 body-1 font-weight-bold mt-5">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Auction
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in auctions"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Add New Vehicle
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in vehicles"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Towing
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in towing"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Title
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in title"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Loading
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in loading"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Accounting
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in accounting"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader class="px-0 body-1 font-weight-bold">
            <v-icon class="mr-1">mdi-arrow-right-bold-outline</v-icon> Photo
          </v-subheader>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Picture
                  </th>
                  <th class="text-left">
                    Responsible
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Active Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in photo"
                  :key="item.name"
                >
                  <td><v-img :src="item.img" height="25" width="25"></v-img></td>
                  <td>{{ item.responsible }}</td>
                  <td class="link-text">{{ item.phone }}</td>
                  <td class="link-text">
                    <p class="mb-0" v-for="email in item.email" :key="email">
                      {{ email}}
                    </p>
                  </td>
                  <td>{{ item.activeTime }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {

  data() {
    return {
      popup: false,
      isActive: false,
      menu: false,
      cs: [
        {
          title: '+1 (818) 570-9044',
          icon: 'mdi-phone-incoming'
        },
        {
          title: 'info@aslshipping.com',
          icon: 'mdi-email-outline',
          class: 'ml-5'
        },
        {
          title: '+1 (818) 570-9044',
          icon: 'mdi-phone-incoming'
        },
        {
          title: 'info@aslshipping.com',
          icon: 'mdi-email-outline',
          class: 'ml-5'
        }
      ],
      ts: [
        {
          title: '+1 (818) 570-9044',
          icon: 'mdi-phone-incoming'
        },
        {
          title: 'techsupport@aslshipping.com',
          icon: 'mdi-email-outline',
          class: 'ml-5'
        },
        {
          title: '+1 (818) 570-9044',
          icon: 'mdi-phone-incoming'
        }
      ],
      auctions: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Copart',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'IAAI',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'MANHEIM',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
      vehicles: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Adding Vehicles',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
      ],
      towing: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'dispatch',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'dispatch',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'dispatch@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
      title: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Vehicle`s title in all Auctions',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Vehicle`s title in all Auctions',
          phone: '+1 323-515-4022',
          email: ['info@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
      loading: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Load Plan & Export',
          phone: '+1 323-515-4022',
          email: ['docs@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        },
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Load Plan & Export',
          phone: '+1 323-515-4022',
          email: ['docs@aslshipping.com', 'export@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
      accounting: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: 'Invoices',
          phone: '+1 323-515-4022',
          email: ['invoice@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
      photo: [
        {
          img: 'http://backend.aslshipping.com/images/logo.jpg',
          responsible: '5 Person',
          phone: '//',
          email: ['inventory@aslshipping.com'],
          activeTime: '12hrs'
        }
      ],
    }
  },

  watch: {
    isActive(val) {

    }
  },

  methods: {
    popupActive() {
      this.isActive = !this.isActive
      this.menu = !this.menu
    }
  }
}
</script>

<style>

.popup--active {
  border: 5px solid #2BA5B6 !important;
  transform: scale(1.25) !important;
}

.link-text {
  color: #2BA5B6
}

</style>