import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../Home.vue';
import Dashboard from '../views/Dashboard.vue';

import Audit from '@/views/audit/Audit';
import Audits from '@/views/audit/Audits';
import Complain from '@/views/complain/Complain';
import Complains from '@/views/complain/Complains';
import Containers from '@/views/containers/Containers';
import Cosignee from '@/views/cosignees/Consignee';
import Cosignees from '@/views/cosignees/Consignees';
import Export from '@/views/exports/Export';
import Exports from '@/views/exports/Exports';
import Feedback from '@/views/feedback/Feedback';
import Feedbacks from '@/views/feedback/Feedbacks';
import KeyMissingClaim from '@/views/keymissingclaims/KeyMissingClaim';
import KeyMissingClaims from '@/views/keymissingclaims/KeyMissingClaims';
import Price from '@/views/prices/Price';
import Prices from '@/views/prices/Prices';
import StorageClaim from '@/views/storageclaims/StorageClaim';
import StorageClaims from '@/views/storageclaims/StorageClaims';

import Claim from '@/views/claims/claim';
import Claims from '@/views/claims/claims';

import Upload from '@/views/settings/test/Upload';

import beforeEach from "@/router/beforeEach.js";
import FourZeroFour from "@/views/FourZeroFour.vue";

import Page from '@/views/settings/pages/Page';
import Pages from '@/views/settings/pages/Pages';

import Acl from './acl';
import Auth from './auth';
import Customers from './customers';
import Invoices from './invoices';
import Reports from './reports';
import Settings from './settings';
import Vehicles from './vehicles';


import Notification from '@/views/notifications/notification';
import Notifications from '@/views/notifications/notifications';


Vue.use(VueRouter);

const routes = [
    ...Auth,
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('../views/About.vue')
            },
            {
                path: '/checkout',
                name: 'CheckOut',
                component: () => import('../views/CheckOut.vue'),
                meta: {
                    title: "Check Out",
                    requiresAuth: true
                }
            },
            {
                path: '/exports',
                name: 'exports',
                component: Exports,
                meta: {
                    title: "Exports",
                    requiresAuth: true,
                    permissionName: 'exports.index'
                }
            },
            {
                path: "/exports/:id",
                name: "export.overview",
                component: Export,
                meta: {
                    title: "Exports Overview",
                    requiresAuth: true,
                    permissionName: 'exports.view'
                }
            },
            {
                path: '/prices',
                name: 'prices',
                component: Prices,
                meta: {
                    title: "Prices",
                    requiresAuth: true,
                    permissionName: 'prices.index'
                }
            },
            {
                path: "/prices/:id",
                name: "price.overview",
                component: Price,
                meta: {
                    title: "Prices Overview",
                    requiresAuth: true,
                    permissionName: 'prices.view'
                }
            },
            {
                path: '/cosignees',
                name: 'cosignees',
                component: Cosignees,
                meta: {
                    title: "Consignees",
                    requiresAuth: true,
                    permissionName: 'consignees.index'
                }
            },
            {
                path: "/cosignees/:id",
                name: "cosignees.overview",
                component: Cosignee,
                meta: {
                    title: "Cosignees Overview",
                    requiresAuth: true,
                    permissionName: 'consignees.view'
                }
            },

            {
                path: '/containers',
                name: 'Containers',
                component: Containers,
                meta: {
                    title: "Container",
                    requiresAuth: true,
                    permissionName: 'consignees.index'
                }
            },

            {
                path: "/test-file-upload",
                name: "upload",
                component: Upload,
                meta: {
                    title: "image upload",
                    requiresAuth: true
                }
            },
            {
                path: '/claims',
                name: 'claims',
                component: Claims,
                meta: {
                    title: "Claims",
                    requiresAuth: true
                }
            },
            {
                path: '/damage-claims/:id',
                name: 'claim',
                component: Claim,
                meta: {
                    title: "Claim",
                    requiresAuth: true,
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: Notifications,
                meta: {
                    title: "Notifications",
                    requiresAuth: true
                }
            },
            {
                path: '/notification/:id',
                name: 'notification',
                component: Notification,
                meta: {
                    title: "Notification",
                    requiresAuth: true
                },
            },
            {
                path: '/storage-claims',
                name: 'StorageClaims',
                component: StorageClaims,
                meta: {
                    title: "Storage Claims",
                    requiresAuth: true,
                }
            },
            {
                path: '/storage-claims/:id',
                name: 'StorageClaim',
                component: StorageClaim,
                meta: {
                    title: "Storage Claim",
                    requiresAuth: true,
                }
            },
            {
                path: '/key-missing-claims',
                name: 'KeyMissingClaims',
                component: KeyMissingClaims,
                meta: {
                    title: "Key Missing Claims",
                    requiresAuth: true,
                }
            },
            {
                path: '/key-missing-claims/:id',
                name: 'KeyMissingClaim',
                component: KeyMissingClaim,
                meta: {
                    title: "Key Missing Claim",
                    requiresAuth: true,
                }
            },
            {
                path: '/feedbacks',
                name: 'feedbacks',
                component: Feedbacks,
                meta: {
                    title: "Feedbacks",
                    requiresAuth: true
                }
            },
            {
                path: "/feedbacks/:id",
                name: "feedback.detail",
                component: Feedback,
                meta: {
                    title: "Feedback Overview",
                    requiresAuth: true,
                }
            },
            {
                path: '/complains',
                name: 'complains',
                component: Complains,
                meta: {
                    title: "Complains",
                    requiresAuth: true
                }
            },
            {
                path: "/complains/:id",
                name: "complains.detail",
                component: Complain,
                meta: {
                    title: "Complain Overview",
                    requiresAuth: true,
                }
            },
            {
                path: '/audits',
                name: 'audits',
                component: Audits,
                meta: {
                    title: "Audits",
                    requiresAuth: true
                }
            },
            {
                path: "/audits/:id",
                name: "audits.detail",
                component: Audit,
                meta: {
                    title: "Audit Detail",
                    requiresAuth: true,
                }
            },
            {
                path: '/pages',
                name: 'pages',
                component: Pages,
                meta: {
                    title: "pages"
                }
            },
            {
                path: "/pages/:id",
                name: "pages.overview",
                component: Page,
                meta: {
                    title: "Pages Detail"
                }
            },
            ...Customers,
            ...Vehicles,
            ...Settings,
            ...Reports,
            ...Invoices,
            ...Acl,
        ]
    },
    /**
     * Not Found
     */
    {
        path: '*',
        component: FourZeroFour,
        meta: {
            title: '404 - Not Found'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(beforeEach);

export default router
