<template>
        <v-menu>
            <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs"
                        v-on="on"
                >
                    {{ $i18n ? $i18n.locale.toUpperCase() : '' }}
                </v-chip>
            </template>
            <v-list>
                <v-list-item
                        :key="index"
                        v-for="(language,index) in languages"
                        @click="setLocale(language.code)"
                >
                    <v-list-item-title>{{ language.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
</template>

<script>
  export default {
    name: "LanguageSwitcher",
    data() {
      return {
        languages: [
          {
            code: 'en',
            title: 'English',
          },
          {
            code: 'ar',
            title: 'Arabic',
          }
        ]
      };
    },

    methods:{
      setLocale ( locale ) {
        this.$i18n.locale = locale;
        localStorage.setItem('locale', locale);
      },
    }
  };
</script>
