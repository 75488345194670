<template>
  <v-row class="mt-1 pos">
    <v-dialog scrollable v-model="show" max-width="1150px">
      <v-card>
        <v-card-title style="border-bottom:1px solid #eeee">
          <!-- <span class="headline">{{ editing ? "Edit" : "New" }} Exports</span> -->

          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="export-modal">
            <div class="d-flex">
              <v-btn color="primary mx-1" @click="generateReport">Print</v-btn>
              
              <v-btn color="primary mx-1" @click="pdfBtnHandel" :loading="downloading" v-if="details.openPdf"
                >Open as Pdf</v-btn
              >
              <v-btn color="primary mx-1"  @click="emailConfirm=true" v-if="details.email && isCustomer"
                >Email</v-btn
              >
            </div>

             <div>
        <v-dialog
                v-model="emailConfirm"
                persistent
                max-width="500"
        >
        <v-card>
            <v-card-title class="headline">
                Are you sure want to continue this action?
            </v-card-title>
            <v-card-text>If you confirm this action it can't be undo!</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="green darken-1"
                        text
                        @click="emailConfirm=false"
                >
                    Cancel
                </v-btn>
                <v-btn
                        color="green darken-1"
                        text
                        @click="downloadBtnHandel()"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>

            <div class="mt-2">
              <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
                <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
              </v-container>
              <div id="printme" v-else v-html="pagedata"></div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">
            Close
          </v-btn>
          <!-- <v-btn
            color="blue darken-1"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import downloadPdf from "@/helpers/downloadPdf";

export default {
  props: ["id", "show", "detailspage", "details"],
  name: "Manage",
  components: {},

  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      dataId: this.id,
      editing: false,
      errors: {},
      pagedata: null,
      loading: false,
      emailConfirm:false,
      isCustomer:null,
      downloading : false,
    };
  },
  methods: {
    cancel() {
      this.$emit("clearform");
    },
    async getData() {
      if (!this.details.api) {
        return;
      }
      this.loading = true;
      await Api.get(this.details.api).then((res) => {
        this.pagedata = res.data;
        this.loading = false;
      });
    },
    generateReport() {
      var prtContent = document.getElementById("printme");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      // WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();;
    },
    
    downloadBtnHandel(){
       
       window.open(process.env.VUE_APP_API_ENDPOINT +'/api/v1'+ this.details.email, '_blank');
       this.emailConfirm=false
    },
    manageLoading (value) {
      this.downloading = value;
    },
    pdfBtnHandel(){
    //   Api({
    //     url: process.env.VUE_APP_API_ENDPOINT  +'/api/v1'+ this.details.openPdf,
    //     method: 'GET',
    //     responseType: 'blob', // important
    //   }).then((response) => {
        /*const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();*/
     // });
      let url = process.env.VUE_APP_API_ENDPOINT  +'/api/v1'+ this.details.openPdf;
      if ( this.roleId === 3 ) {
        url += '?customer_user_id=' + JSON.parse(localStorage.getItem("user")).id;
      }

      window.open( url , '_blank');
      // this.manageLoading(true)
      // downloadPdf(url,this.manageLoading )
    },
  },
  created() {
       if(this.roleId===3) {
        this.isCustomer = false
       }else{
         this.isCustomer = true
       }
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>
<style scoped>
.export-modal .row .col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.export-modal__title {
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}
.export-modal hr {
  background: #ebebeb;
}
.pos {
  position: absolute !important;
}
table {
  border: 2px solid red !important;
}
</style>
