<template>
    <div class="vehicle" style="margin-top: 70px;">
        <Breadcrumbs />
        <v-container class="permision">
            <h2 class="ml-3">{{ role.name }}</h2>
            <row class="d-flex " style="flex-wrap:wrap;">
                <v-col cols="12" md='4' class="pa-4" v-for="(permission,p) in selected_permissions" :key="p">
                    <v-card
                            max-width="100%"
                            class="pa-3"
                            elevation="2"

                    >
                        <strong>
                            <v-checkbox
                                    v-model="permission.enabled"
                                    :label='`Module: ${permission.name }`'
                                    :value="true"
                                    @click="allCheckHandle(permission)"
                                    hide-details
                            ></v-checkbox>
                        </strong>
                        <div class="module-checkbox pl-6">
                            <v-checkbox
                                    v-for="(p,i) in permission.permissions" :key="i"
                                    v-model="p.has_access"
                                    :label="p.name"
                                    :value="true"
                                    @click="checkHandle(p.id,permission)"
                                    hide-details
                            ></v-checkbox>

                        </div>
                    </v-card>
                </v-col>

              <v-btn :loading="loading" color="primary" @click="updateRole" style="margin-bottom: 60px; margin-left: 15px;">
                Update
              </v-btn>

            </row>

        </v-container>
    </div>
</template>

<script>
    import Api from "@/services/Api";
    import Breadcrumbs from "@/views/acl/roles/Breadcrumbs.vue"

    export default {
        name: "role",
        components: {Breadcrumbs},
        data() {
            return {
                selected_permissions: [],
                permissions: [],
                hasPermissions: [],
                role: {},
                loading: false,
                moduleName: '',
                sub: '',
                invoice: {
                    moduleName: '0',
                    invoiceIndex: '0',
                    invoiceUpdate: '0',
                    invoiceCreate: '0'
                }
            };
        },
        methods: {
            getAccessId() {
                Object.values(this.selected_permissions).forEach((item) => {
                    item.permissions.forEach((permission) => {
                        if (permission.has_access) {
                            this.hasPermissions.push(permission.id)
                        }
                    })
                });
            },
            updateRole() {
                Api.post(`/acl/roles/${this.$route.params.id}`, {
                    permissions: this.hasPermissions
                }).then(res => {
                    this.$toastr.s('Role successfully updated')
                }).catch(err => {
                    this.$toastr.e('Failed to load data!' + err)
                    this.loading = false;
                });

                this.editId = null;
                this.deletingId = null;
            },
            async getPermissions() {
                await Api.get(`/acl/permissions`).then(res => {
                    this.permissions = res.data;
                    this.getRole();
                }).catch(err => {
                    this.$toastr.e('Failed to load data!' + err)
                    this.loading = false;
                });

                this.editId = null;
                this.deletingId = null;
            },
            getRole() {
                Api.get(`/acl/roles/${this.$route.params.id}`).then(res => {
                    this.role = res.data.role;
                    this.selected_permissions = res.data.permissions;

                    this.getAccessId()
                }).catch(err => {
                    this.$toastr.e('Failed to load data!' + err)
                    this.loading = false;
                });

                this.editId = null;
                this.deletingId = null;
            },
            checkBoxAll(e) {
                // for (var key in e) {

                //     if ( e[this.moduleName]==null) {
                //         e[key]='1'

                //     }else{
                //       e[key]=null

                //     }
                // }
            },
            allCheckHandle(permission) {
                if (permission.enabled) {
                    permission.permissions.forEach(e => {
                        e.has_access = true
                        if (!this.hasPermissions.includes(e.id)) {
                            this.hasPermissions.push(e.id)
                        }
                    });
                } else {
                    permission.enabled = false
                    permission.permissions.forEach(e => {
                        e.has_access = false
                        this.hasPermissions.splice(this.hasPermissions.findIndex(k => k == e.id), 1)
                    });
                }
            },
            checkHandle(id, permission) {
                if (!Array.isArray(this.hasPermissions) || !this.hasPermissions.length) {
                    this.hasPermissions.push(id)
                } else if (this.hasPermissions.includes(id)) {
                    this.hasPermissions.splice(this.hasPermissions.findIndex(k => k == id), 1)
                } else {
                    this.hasPermissions.push(id)
                }
                permission.permissions.forEach(e => {
                    if (e.has_access) {
                        permission.enabled = true
                    }
                })
                // ====================
                permission.permissions.forEach(e => {
                    if (!e.has_access) {
                        permission.enabled = false
                    }
                })

            },

        },
        mounted() {
            this.getPermissions();
        },
        created() {

        }
    };
</script>
<style scoped>
    .permision .v-card {
        height: 100% !important;
        /* margin-bottom: 10px; */
    }
</style>