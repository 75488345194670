<template>
  <div>
    <div class="home mt-65">
      <overview-data />
      <v-container fluid class="bg-white">
        <div class="d-flex">
          <h1 class="primetime" style="margin-top: -13px">Invoices</h1>

          <div class="ml-auto">
            <!-- <v-btn
              :loading="filtering"
              color="primary"
              class="buttonleftclass mt-1"
              @click="exportInvoiceSummary"
            >
              Export
            </v-btn> -->
            <manage
              class="mt-1 ml-3 mr-1"
              @dataUpdated="getData()"
              :id="editId"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </div>
        </div>
        <v-row>
          <v-col cols="12" md="10" class="pb-0 pt-0 mt-5">
            <div>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    @keypress.enter="search"
                    dense
                    outlined
                    v-model="filters.customer_global_search"
                    label="Global Search"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    :loading="filtering"
                    color="primary"
                    style="margin-right: 5px"
                    @click="search"
                  >
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" class="text-right mt-2">
                  <h5>
                    Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                    {{ this.showingTo ? this.showingTo : "0" }} of
                    {{ this.count ? this.count : "0" }}
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />

        <vue-content-loading :width="300" :height="100" v-if="loading">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-simple-table class="mt-5" v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Customer ID</th>
                <th class="text-left">Company Name</th>
                <th class="text-left">Total Amount</th>
                <th class="text-left">Paid Amount</th>
                <th class="text-left">Discount</th>
                <th class="text-left">Balance</th>
                <th class="text-center" style="width: 200px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoiceSummary.data" :key="item.user_id">
                <td>{{ item.legacy_customer_id }}</td>
                <td>{{ item.company_name }}</td>
                <td>{{ item.total_amount }}</td>
                <td>{{ item.paid_amount }}</td>
                <td>{{ item.discount }}</td>
                <td>{{ item.balance }}</td>

                <td style="width: 100px">
                  <div v-if="permissions['invoices.view'] == false"></div>
                  <v-btn v-else text :to="`/invoices/${item.user_id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td></td>
                <td style="font-weight: bold">
                  {{
                    invoiceSummary.summary
                      ? invoiceSummary.summary.grand_total
                      : ""
                  }}
                </td>
                <td style="font-weight: bold">
                  {{
                    invoiceSummary.summary
                      ? invoiceSummary.summary.total_paid
                      : ""
                  }}
                </td>
                <td style="font-weight: bold">
                  {{
                    invoiceSummary.summary
                      ? invoiceSummary.summary.total_discount
                      : ""
                  }}
                </td>
                <td style="font-weight: bold">
                  {{
                    invoiceSummary.summary
                      ? invoiceSummary.summary.total_balance
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import overviewData from "@/components/overviewData.vue";
import Api from "@/services/Api";
import Manage from "@/views/invoices/Manage.vue";
import axios from "axios";
import { VueContentLoading } from "vue-content-loading";

export default {
  name: "InvoiceSummary",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
    overviewData,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      invoiceSummary: {},
      filters: {},
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      AuthStr: "Bearer " + localStorage.getItem("authtoken"),
      baseDomain: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }

      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      // Api.get(`/invoices/summary?page=${page}${searchQuery}`)
      //   .then((res) => {
      //     this.showingFrom = res.data.from;
      //     this.showingTo = res.data.to;
      //     this.count = res.data.total;
      //     this.invoiceSummary = res.data;
      //     this.pagination.current = res.data.current_page;
      //     this.pagination.total = res.data.last_page;
      //
      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     if (err.response.status !== 401) {
      //     this.$toastr.e("Failed to load data!" + err);
      //     this.loading = false;
      //     }else{
      //       this.$toastr.e(err.response.data.status)
      //     }
      //   });

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/invoices/summary?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.invoiceSummary = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;

          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    getDataWithFilter(finalSearchQuery) {
      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/invoices/summary?${finalSearchQuery}`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.invoiceSummary = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;

          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      this.getData(1, this.filtersUrl());
    },

    filtersUrl() {
      let str = "";
      for (let key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str !== "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/invoices/${this.deletingId}`)
        .then((res) => {
          this.getData(1);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },

    clearFilter() {
      this.filters = {};
      this.getData(1);
    },
    exportInvoiceSummary() {
      this.exportData(this.filtersUrl());
    },
    exportData(searchQuery = "") {
      let url = Api.defaults.baseURL + `/customers/export/excel?excel-export=1`;
      if (searchQuery) {
        searchQuery = `excel-export=1&${searchQuery}`;
        url = Api.defaults.baseURL + `/customers/export/excel${searchQuery}`;
      }
      window.location = url;
    },
  },
  mounted() {
    // this.getData(1);
    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
