<template>
  <div>
    <div
      v-if="permissions['customers.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex pb-4">
          <h1 class="primetime" style="margin-top: -13px">Customer</h1>
          <div class="ml-auto mt-3" cols="12" md="4">
            <v-btn
              :loading="filtering || downloading"
              color="primary"
              class="buttonleftclass"
              @click="exportCustomers"
            >
              Export Excel
            </v-btn>
            <!--                 <v-btn :loading="filtering" color="primary" class="buttonleftclass" @click="exportCustomersPDF">
                  Export PDF
                </v-btn>-->
            <manage
              class="d-inline-block mr-1 ml-3"
              @dataUpdated="getData()"
              ref="manage"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </div>
          <!-- <v-col class="ml-auto" cols="12" md="2">
                <manage class="" @dataUpdated="getData()" ref="manage" :show="formShow" @cancelForm="formShow = false"/>
           </v-col> -->
        </div>
        <v-row class="">
          <v-col cols="12" md="10" class="pb-0 pt-0 mb-5">
            <div>
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    outlined
                    dense
                    :items="[20, 50, 75, 100]"
                    v-model="pagination.per_page"
                    :label="`Showing ${pagination.per_page} Items`"
                    @change="getData(1)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.customer_global_search"
                    label="Global Search"
                    @keyup.enter.native="manualSearch"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    :loading="filtering"
                    color="primary"
                    @click="manualSearch"
                  >
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <h5>
                    Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                    {{ this.showingTo ? this.showingTo : "0" }} of
                    {{ this.count ? this.count : "0" }}
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />

        <vue-content-loading :width="300" :height="100" v-if="loading">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>
        <v-simple-table v-else>
          <template>
            <thead>
              <tr>
                <th
                  @click="Sort('legacy_customer_id')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Customer ID
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' &&
                      currentSort === 'legacy_customer_id'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' &&
                      currentSort === 'legacy_customer_id'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th
                  @click="Sort('customer_name')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Name
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' &&
                      currentSort === 'customer_name'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' &&
                      currentSort === 'customer_name'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th
                  @click="Sort('company_name')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Company Name
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' && currentSort === 'company_name'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' &&
                      currentSort === 'company_name'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th class="text-left">Email</th>
                <th
                  @click="Sort('phone')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Phone UAE
                  <v-icon
                    v-if="currentSortDir === 'asc' && currentSort === 'phone'"
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="currentSortDir === 'desc' && currentSort === 'phone'"
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th
                  @click="Sort('loading_type')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Loading Type
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' && currentSort === 'loading_type'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' &&
                      currentSort === 'loading_type'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th
                  @click="Sort('city_id')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  City
                  <v-icon
                    v-if="currentSortDir === 'asc' && currentSort === 'city_id'"
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' && currentSort === 'city_id'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th class="text-left">Status</th>
                <th class="text-left">Total Vehicles</th>
                <th class="text-left">Total Containers</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td class="py-3">
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.customer_name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.company_name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.email"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.phone"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <!--<td><v-text-field v-model="filters.loading_type" outlined dense hide-details></v-text-field></td>-->
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="loading_types"
                    v-model="filters.loading_type"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.city"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="statuses"
                    v-model="filters.status"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td></td>
                <td></td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="item in customers.data" :key="item.name">
                <!-- <td>{{i+1+ ((pagination.current-1)*20 )}}</td> -->
                <td>{{ item.legacy_customer_id }}</td>
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="`/customers/${item.id}`"
                  >
                    {{ item.customer_name }}
                  </router-link>
                </td>
                <td>{{ item.company_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.loading_type_title }}</td>
                <td>{{ item.city }}</td>
                <td>
                  <v-chip
                    class="ma-2"
                    small
                    v-if="item.status_name === 'Active'"
                    color="success"
                    >{{ item.status_name }}</v-chip
                  >
                  <v-chip class="ma-2" small color="warning" v-else>{{
                    item.status_name
                  }}</v-chip>
                </td>
                <td style="text-align: right">
                  <router-link
                    style="font-weight: bold; text-decoration: none"
                    :to="{
                      name: 'Vehicles',
                      query: {
                        customer_name: item.user_id,
                        excluded_status: 6,
                      },
                    }"
                  >
                    {{ item.vehicles_count }}
                  </router-link>
                </td>
                <td style="text-align: right">
                  <router-link
                    style="font-weight: bold; text-decoration: none"
                    :to="{
                      name: 'exports',
                      query: {
                        customer_user_id: item.user_id,
                        excluded_status: 6,
                      },
                    }"
                  >
                    {{ item.exports_count }}
                  </router-link>
                </td>
                <td style="width: 20%">
                  <div v-if="permissions['customers.view'] == false"></div>
                  <v-btn v-else text :to="`/customers/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>

                  <div v-if="permissions['customers.update'] == false"></div>
                  <v-btn v-else text @click="edit(item.id)" color="blue">
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <div v-if="permissions['customers.destroy'] == false"></div>
                  <v-btn v-else text @click="destroy(item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import Api from "@/services/Api";
import Manage from "@/views/customers/Manage.vue";
import { VueContentLoading } from "vue-content-loading";
import downloadFile from "@/helpers/downloadFile";

export default {
  name: "Customers",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      deletingId: null,
      formShow: false,
      currentSort: "customer_name",
      currentSortDir: "asc",
      deleteConfirmationShow: false,
      customers: {},
      statuses: [
        { title: "Select status", id: "" },
        { title: "Active", id: 1 },
        { title: "Inactive", id: 0 },
      ],
      loading_types: [
        { title: "Select one", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      filters: {},
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      downloading : false,
    };
  },
  methods: {
    Sort(currentsort) {
      this.loading = true;
      this.currentSortDir =
        this.currentSortDir === "asc" && this.currentSort === currentsort
          ? "desc"
          : "asc";
      this.currentSort = currentsort;

      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + totalSortFilterQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/customers?${totalSortFilterQuery}`)
        .then((res) => {
          this.customers = res.data;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + totalSortFilterQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/customers?${totalSortFilterQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.customers = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    getDataWithFilter(searchQuery) {
      Api.get(`/customers?${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.customers = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },
    manualSearch() {
      this.filters.page = 1;
      this.search();
    },
    remove() {
      Api.delete(`/customers/${this.deletingId}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.deletingId = null;
      this.formShow = false;
    },
    edit(id) {
      this.loading = true;
      this.$refs.manage.edit(id);
      this.formShow = true;
      this.loading = false;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },

    exportCustomers() {
      this.exportData(this.filtersUrl());
    },

    loadingManage (value) {
      this.downloading = value;
    },
    exportData(searchQuery = "") {
      this.loadingManage(true);
      var url = Api.defaults.baseURL + `/customers/export/excel`;
      if (searchQuery) {
        searchQuery = `?${searchQuery}`;
        url = Api.defaults.baseURL + `/customers/export/excel${searchQuery}`;
      }
      // window.location = url;

      downloadFile(url,
          'customer_export',
          '.xls',
          this.loadingManage
      );
    },
    exportCustomersPDF() {
      // alert();
      this.exportDataPDF(this.filtersUrl());
    },
    exportDataPDF(searchQuery = "") {
      var url = Api.defaults.baseURL + `/customers/export/pdf`;
      if (searchQuery) {
        searchQuery = `?${searchQuery}`;
        url = Api.defaults.baseURL + `/customers/export/pdf${searchQuery}`;
      }
      window.location = url;
    },
  },
  mounted() {
    // this.getData();

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.filters.status) {
        this.filters.status = parseInt(this.filters.status);
      }
      if (this.filters.loading_type) {
        this.filters.loading_type = parseInt(this.filters.loading_type);
      }

      if (this.filters.order_by_column) {
        this.currentSort = this.filters.order_by_column;
      }
      if (this.filters.order_by) {
        this.currentSortDir = this.filters.order_by;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
  // watch: {
  //   "filters.loading_type"() {
  //     this.search();
  //   },
  //   "filters.status"() {
  //     this.search();
  //   },
  // },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
.buttonleftclass {
  margin-left: 5px;
}
th.sortable {
  cursor: pointer;
}
th.sortable:hover {
  color: black;
  text-decoration: underline;
}
.mdi.mdi-sort-descending,
.mdi.mdi-sort-ascending {
  float: right;
}
</style>
