<template>
  <div class="vehicle mt-5">
    <v-container
      class="white mt-5 pl-6 d-flex justify-space-between align-center"
    >
      <h3 class="primetime">Vehicle Details</h3>
      <!--          <v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
    </v-container>
    <v-container class="white mt-1 pt-5 px-5">
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Customer Name
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.customer_name }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Company Name
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.company_name }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> VCR </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.vcr }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> HAT </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.hat_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Buyer No
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.buyer_id }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Keys </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              <span v-if="details.keys_name == 'No'" style="color: red">{{
                details.keys_name
              }}</span>
              <span v-else>{{ details.keys_name }}</span>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            align="center"
            class="table-wrapper"
            v-if="details.status == 7"
          >
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Handed Over Date
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.handed_over_date }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> VIN </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.vin }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Year </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.year }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Make </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.make }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Model
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.model }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col
              cols="12"
              sm="6"
              class="pa-3 font-weight-bold"
              style="color: blue"
            >
              LOT Number
            </v-col>
            <v-col
              cols="12"
              sm="6"
              style="color: blue"
              class="pa-3 second-column"
            >
              {{ details.lot_number }}
            </v-col>
          </v-row>

          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Color
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.color }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Key Note
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.key_note }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col
              cols="12"
              sm="6"
              class="pa-3 font-weight-bold"
              style="color: red"
            >
              Storage Amount
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.storage_amount }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Check No
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.check_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Value
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.value }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Add. CHGS
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.additional_charges }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Location
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.location }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Weight
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.weight }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["details"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
</style>
