<template>
    <div class="vehicle pb-10">
        <breadcrumbs/>
        <div class="vehicle mt-10">
            <v-container class="white mt-5 pl-6 d-flex justify-space-between align-center">
                <h3 class="primetime">Key Missing Claim Details</h3>
            </v-container>
            <v-container class="white mt-1 pt-5 px-5">
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Vehicle Vin
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.vin }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Customer
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.customer_name }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Claim Amount
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.claim_amount }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Approved Claim Amount
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.approved_amount }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold" style="color:blue">
                                Claim Status
                            </v-col>
                            <v-col cols="12" sm="6" style="color:blue" class="pa-3 second-column">
                                {{ claim.status_name }}
                            </v-col>
                        </v-row>

                    </v-col>


                    <v-col cols="12" sm="12" md="6">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Created Date
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.created_at }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Approved Date
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.approved_date }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Damaged Part
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.damaged_part }}
                            </v-col>
                        </v-row>

                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Approved By
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.approved_by }}
                            </v-col>
                        </v-row>
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                                Other Parts
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 second-column">
                                {{ claim.other_parts }}
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>


                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="6" md="3" class="pa-3 font-weight-bold">
                                Customer Remarks
                            </v-col>
                            <v-col cols="12" sm="9" md="9" class="pa-3 second-column">
                                {{ claim.remarks }}
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-row
                                no-gutters
                                align="center"
                                class="table-wrapper"
                        >
                            <v-col cols="12" sm="3" md="3" class="pa-3 font-weight-bold">
                                Admin Remarks
                            </v-col>
                            <v-col cols="12" sm="9" md="9" class="pa-3 second-column ">
                                {{ claim.admin_remarks }}
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </v-container>

            <div class="vehicle" style="margin-top: 20px">
                <v-container
                        class="
                            white
                            mt-5
                            pl-6
                            d-flex
                            justify-space-between
                            flex-column flex-sm-row
                          "
                >
                    <h3 class="primetime text-uppercase">Customer Photos</h3>

                    <div class="mt-5 mt-sm-0">
                        <v-btn
                                text
                                outlined
                                @click="downloadSub(1)"
                                class="mr-3 download-btn"
                                color="blue"
                                v-if="claim.customer_photos && claim.customer_photos.length"
                        >
                            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
                            Download
                        </v-btn>
                    </div>
                </v-container>

                <v-container class="white mt-1 pt-5 px-10">
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                v-for="(item, index) in claim.customer_photos"
                                :key="index"
                        >
                            <v-img
                                    class="img-border grey lighten-2"
                                    :src="item.url"
                                    aspect-ratio="2"
                            >
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="vehicle" style="margin-top: 20px">
                <v-container
                        class="
                            white
                            mt-5
                            pl-6
                            d-flex
                            justify-space-between
                            flex-column flex-sm-row
                          "
                >
                    <h3 class="primetime text-uppercase">Admin Photos</h3>

                    <div class="mt-5 mt-sm-0">
                        <v-btn
                                text
                                outlined
                                @click="downloadSub(2)"
                                class="mr-3 download-btn"
                                color="blue"
                                v-if="claim.admin_photos && claim.admin_photos.length"
                        >
                            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
                            Download
                        </v-btn>
                    </div>
                </v-container>

                <v-container class="white mt-1 pt-5 px-10">
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                v-for="(item, index) in claim.admin_photos"
                                :key="index"
                        >
                            <v-img
                                    class="img-border grey lighten-2"
                                    :src="item.url"
                                    aspect-ratio="2"
                            >
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </div>


        </div>
    </div>
</template>

<script>
    import Breadcrumbs from "@/views/storageclaims/Breadcrumbs.vue";
    import Api from "@/services/Api";

    export default {
        name: "KeyMissingClaim",
        components: {
            Breadcrumbs,
        },
        data() {
            return {
                claim: {},
                loading: false
            };
        },
        methods: {
            show() {
                this.loading = true;
                this.errors = {};
                Api.get(`/storage-claims/${this.$route.params.id}`).then(res => {
                    this.claim = res.data.data;
                    this.loading = false;
                }).catch((err) => {
                    if (err.response.status !== 401) {
                        this.loading = false;
                        this.$toastr.e('Failed to load data! ' + err);
                    } else {
                        this.$toastr.e(err.response.data.status);
                    }
                })
            },
            downloadSub(e) {
                const baseUrl = process.env.VUE_APP_API_ENDPOINT;
                window.open(
                    `${baseUrl}/api/v1/vehicle-claims/${this.$route.params.id}/download-photos?type=${e}`,
                    "_blank"
                );
            },
        },
        created() {
            this.show();
        },
        mounted() {

        }
    };
</script>
<style>
    .vehicle {
        background-color: #f8f8fb;
    }

    .primetime {
        color: #1b455f !important;
    }

    .breaking {
        overflow-wrap: break-word;
    }

    @media screen and (max-width: 600px) {
        .small-row {
            width: 50% !important;
        }

        .show-all {
            display: none;
        }
    }

    .table-wrapper {
        border: 1px solid #dddddd;
    }

    .second-column {
        border-left: 1px solid #dddddd;
    }

</style>

