<template>
  <div class="vehicle pb-10">
    <v-container>
      <div class="d-flex grey--text">
        <v-btn small to="/" color="grey" text>Home</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small to="/weights" color="grey" text>Vehicle Weights</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
    </v-container>
    <v-container>
      <div class="d-flex justify-end">
        <div class="d-flex">
          <!-- <v-btn
            class="text-capitalize white--text mt-2 mr-5 ml-auto py-5"
            small
            color="#026D7B"
            elevation="0"
            @click="edit($route.params.id)"
            >Update
          </v-btn> -->
          <manages
            :id="$route.params.id"
            ref="manage"
             @dataUpdated="getData()"
            :show="formShow"
            :custom="false"
            :editing="true"
            @cancelForm="formShow = null"
          />

          <v-btn
            class="text-capitalize white--text mt-2 py-5"
            small
            color="red"
            @click="destroy($route.params.id)"
            elevation="0"
            >Delete
          </v-btn>
        </div>
      </div>
    </v-container>

    <!-- <vehicle-info/> -->
    <div v-if="!loading">
      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Vehicle Weight Details</h3>
        <v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-container>
      <v-container class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Year
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.year }}
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Maker
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.maker }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="6">
           

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Model
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.model }}
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Weight
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ details.weight }}
              </v-col>
            </v-row>
          </v-col>

        
        </v-row>
      </v-container>
      <confirmation
        :confirming="deletingId"
        @confirmed="remove"
        :show="deleteConfirmationShow"
        @canceled="deleteConfirmationShow = false"
      />
     
    </div>
    <vue-content-loading :width="250" :height="100" v-if="loading">
      <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
    </vue-content-loading>
    <!--<attachments />-->
  </div>
</template>

<script>
// import Attachments from "@/components/vehicle/Attachments.vue";
// import Breadcrumbs from "@/components/vehicle/Breadcrumbs.vue";
// import CarView from "@/components/vehicle/CarView.vue";
// import ShortExportInfo from "@/components/vehicle/ShortExportInfo.vue";
// import TitleInfo from "@/components/vehicle/TitleInfo.vue";
// import TowingInfo from "@/components/vehicle/TowingInfo.vue";
// import vue2Dropzone from "vue2-dropzone";
// import NotesModal from "@/components/vehicle/NotesModal.vue";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import { VueContentLoading } from "vue-content-loading";
import Confirmation from "@/components/Confirmation.vue";
// import VehicleInfo from "@/components/vehicle/VehicleInfo.vue";
// import Manage from "@/views/vehicles/Manage.vue";
import Api from "@/services/Api";
import Manages from "@/views/vehicles/weight/Manage.vue";
// import VehicleDetails from "@/components/vehicle/VehicleDetails.vue";

export default {
  name: "Vehicle",
  components: {
    // Breadcrumbs,
    // VehicleInfo,
    // TowingInfo,
    // ManageDetails,
    // TitleInfo,
    VueContentLoading,
    // ShortExportInfo,
    // Attachments,
    Confirmation,
    // CarView,
    // VehicleDetails,
    // NotesModal,
    // Manage,
    Manages,
    // vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      vehicles: {},
      loading: false,
      vehicleId: "",
      images: [],
      notesShow: false,
      currentPage: 1,
      showModal: false,
      dialog: false,
      errors: "",
      loadContent: false,
      formShow: false,
      editId: "",
      deletingId: "",
      deleteConfirmationShow: false,
      details: [],
      form: {
       
      },
     
    };
  },
  methods: {
    getData() {
      this.loading = true;
      Api.get(`/vehicle-weights/${this.vehicleId}`)
        .then((res) => {
          this.details = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }else{
            this.$toastr.e(err.response.data.status)
          }
        });
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    remove() {
      Api.delete(`/vehicle-weights/${this.deletingId}`)
        .then((res) => {
          //   this.getData();
          this.$router.push("/weights");
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;}
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
      // this.formShowEdit = false;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1 || 1;
    },

    
   
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    downloadAttachment() {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      window.open(`${baseUrl}/api/v1/vehicles/download-attachments`, "_blank");
    },
    downloadSub(e) {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      window.open(
        `${baseUrl}/api/v1/vehicles/download-photos?type=${e}`,
        "_blank"
      );
    },
  },
  mounted() {
    // this.getData();
  },
  created() {
    this.vehicleId = this.$route.params.id;
    this.form.vehicleId = this.$route.params.id;
    this.getData();
  },
  computed: {
    toBeShown() {
      return this.images.slice(0, this.currentPage * 4);
    },
    totalPages() {
      return Math.ceil(this.images.length / 4);
    },
  },
};
</script>
<style>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}

.vehicle {
  background-color: #f8f8fb;
}

.primetime {
  color: #1b455f !important;
}

.breaking {
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .small-row {
    width: 50% !important;
  }

  .show-all {
    display: none;
  }
}

.show-all-container {
  height: 100%;
}

.show-all {
  color: #385f68;
  font-weight: 700;
  text-decoration: none;
}

.show-all::after {
  content: "";
  display: block;
  margin: auto;
  width: 0;
  height: 2px;
  /* margin-top: 10px; */
  background: #385f78;
  transition: all 0.2s linear;
}

.show-all:hover::after {
  width: 100%;
}

.img-border {
  border-radius: 5px;
}
</style>
