<template>
    <v-row class="mt-1 float-right">
        <v-dialog
                v-model="dialog"
                max-width="85%"
                scrollable
                persistent
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="permissions['prices.store'] && !id"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="emptyForm(),editing = false,form.upload_file = ''"
                >
                    New Prices
                </v-btn>
            </template>
            <v-card>
                <v-card-title style="border-bottom:1px solid #eeee">
                    <span class="headline">{{ editing ? 'Edit' : 'New' }} Prices</span>
                     <v-btn class="ml-auto" color="blue darken-1" text @click="cancel(),editing = true">
            <v-icon>mdi-close</v-icon>
          </v-btn>
                </v-card-title>
                <v-card-text>
                     <vue-content-loading :width="300" :height="100" v-if="modalloader">
                        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
                        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
                    </vue-content-loading>
                    <v-container v-else>
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-select
                                    item-text="title"
                                    item-value="id"
                                    :items="pricing_types" v-model="form.pricing_type" outlined dense label="Pricing type"
                                    :error-messages="errors.pricing_type">
                                </v-select>

                                   <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        :error-messages="errors.month"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.month"
                                            label="Month"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.month"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="form.month"
                                        @input="menu = false"
                                        type="month"
                                        no-title
                                        scrollable
                                        ></v-date-picker>
                                    </v-menu>




                            </v-col>

                            <v-col cols="12" md="6">

                                <v-select
                                        item-text="title"
                                        item-value="id"
                                        :items="statuses" v-model="form.status" outlined dense label="Status"
                                        :error-messages="errors.status"></v-select>
                            </v-col>


                            <v-col cols="12" md="12">
                                Description
                                <v-textarea
                                        outlined
                                        rows="3"
                                        cols="4"
                                        name="input-7-4"
                                        label="Description"
                                        v-model="form.description"
                                ></v-textarea>
                            </v-col>



                            <v-col cols="12" md="12">
                                <v-row>
                                    <v-col cols="3" md="3">
                                        Upload File
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <p style="color:red">{{errors.upload_file? errors.upload_file : ""}}</p>
                                        <vue-dropzone
                                                ref="vueDropzone"
                                                id="dropzone"
                                                @vdropzone-sending="sendingEvent"
                                                @vdropzone-success="storeSuccess"
                                                @vdropzone-error="storeError"
                                                :options="dropzoneOptions"
                                                
                                                ></vue-dropzone>
                                                <!-- :error-messages="errors.upload_file" -->
                                        <button @click="removeAllFiles">Remove Files</button>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>

                    </v-spacer>
                    <v-btn
                        color="white"
                        text
                        class="primary"
                        :disabled="loading"
                        @click="editing ? update() : store()"
                    >
                        {{ editing ? 'Update' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import Api from "@/services/Api";
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import { VueContentLoading } from "vue-content-loading";

    const _token = "Bearer " + localStorage.getItem("authtoken");
    export default {
        props: ['id', 'show'],
        name: 'Manage',
        components : { vueDropzone: vue2Dropzone,VueContentLoading},
        data() {
            return {
                permissions: JSON.parse(localStorage.getItem('permissions')),
                 selectedMonth: new Date().toISOString().substr(0, 7),
                date : '',
                monthSelect : new Date().toISOString().substr(0, 7)  ,
                 menu: false,
                 loading: false,
                 modal: false,
                dataId: this.id,
                editing: false,
                errors: {},
                modalloader:false,
                dialog: this.show,
                form: {
                    upload_file : '',
                },
               
                statuses: [
                    {title: 'Active', id: 1},
                    {title: 'In-active', id: 2}
                ],
                pricing_types: [
                    { title: 'Select status', id: '' },
                    { title: 'Towing Price', id: 1 },
                    { title: 'Ocean Freight Price', id: 2 },
                    { title: 'Bike towing', id: 3 },
                    { title: "Single Vehicle Rate", id: 4 },
                    { title: "Destination Rate", id: 5 },
                    { title: "Full Container Rate", id: 6 },
                  { title: "Half Cut Towing Rate", id: 7 },
                  { title: "Half Cut Shipping Rate", id: 8 },
                ],
                dropzoneOptions: {
                    url: Api.defaults.baseURL + '/pricing/file-upload',
                    thumbnailWidth: 150,
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    acceptedFiles: ".pdf",
                    maxFiles: 1,
                   headers: { "Authorization": _token },
                },
            }
        },
        methods: {
            removeAllFiles() {
                this.form.upload_file = '';
                if(this.$refs.vueDropzone) {
                  this.$refs.vueDropzone.removeAllFiles();
                }
            },

            sendingEvent(file, xhr, formData) {
                // formData.append('image_id', 12);
                // for (let key in this.form) {
                //   formData.append(key, this.form[key]);
                // }
            },

            storeSuccess(file, response) {
                // this.dialog = false;
                this.loading = false;
                this.form.upload_file = response.data;
                // this.emptyForm();
            },

            storeError(file, message, xhr) {
                this.dialog = true;
                if (xhr.status === 422) {
                    // this.errors = message.errors;
                } else {
                    this.dialog = false;
                    this.loading = false;
                }

                this.$toastr.e('Failed to create data! ' + message.message);
            },

            emptyForm() {
                this.form = {
                    month: '',
                    str_month: '',
                    pricing_type: '',
                    status: '',
                    upload_file : '',
                }
                 this.removeAllFiles();
                
            },
            store() {
                this.loading = true;
                this.errors = {};

                Api.post(`/pricing`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.emptyForm();
                    this.$emit("dataUpdated");
                    this.$toastr.s(res.data.message);
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                    }
                    this.loading = false;

                    this.$toastr.e("Failed to create data! One or more required field missing. ");
                })
            },
            async update() {
                this.loading = true;
                this.errors = {};
                await Api.put(`/pricing/${this.id}`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.emptyForm();
                    this.$emit("dataUpdated");
                    this.$toastr.s(res.data.message);
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                    }
                    this.loading = false;
                    this.$toastr.e("Failed to update data! One or more required field missing. ");
                });

                this.editing = false;
            },
            edit() {
                this.emptyForm();
                this.removeAllFiles();
                if (!this.dataId) {
                    return;
                }
                this.loading = true;
                this.errors = {};
                Api.get(`/pricing/${this.dataId}`).then(res => {
                    this.form = res.data.data;
                    this.loading = false;
                    this.selectedMonth = res.data.data.month;

                    if(res.data.data.upload_file) {
                        let file = { size: res.data.data.file_size, name: res.data.data.file_name, type: res.data.data.file_type };
                        this.$refs.vueDropzone.manuallyAddFile(file, res.data.data.upload_file);
                    }

                    this.dialog = true;
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                    }
                    this.loading = false;
                    if (err.response.status !== 401) {
                      this.$toastr.e('Failed to load data! ' + err);
                    }
                })
            },
            cancel() {
                this.dataId = false;
                this.editing = false;
                this.dialog = false;
                this.$emit("cancelForm");
                // this.emptyForm();
            },
            customFormatter(date) {
                let dateObj = new Date(date);
                return dateObj.toLocaleString('en-US', {month: 'short'}) +'-'+ dateObj.year();
            },
            saveMonth(el) {

            }
        },
        created() {
            this.emptyForm();
        },
        watch: {
            id: function (newVal, oldVal) {
                if (newVal || oldVal) {
                    this.dataId = newVal;
                    this.edit();
                    this.editing = true;
                } else {
                    this.dataId = false;
                    this.editing = false;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            },
            selectedMonth : function(value) {
                let month = this.customFormatter(value);
                if(month) {
                    this.form.month = month
                    this.selectedMonth = month;
                }else{
                    this.form.month = '';
                    this.selectedMonth = '';
                }
            },
            monthSelect : function(value) {
                // let month = this.customFormatter(value);
                // if(month) {
                //     this.form.month = month
                //     this.monthSelect = month;
                // }else{
                //     this.form.month = '';
                //     this.monthSelect = '';
                // }
            }
        }
    }
</script>
<style >
.w div input{
    border: 1px solid black;
    height: 37px;
    border-radius: 3px;
    padding: 0 16px;

}
.month-pickers{
    position: relative;
}
.month-picker__text{
    position: absolute;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    top: 6px;
    left: 19px;
}
</style>