const filters = [
    {
        name: 'excerpt',
        execute: (text, length = 30) => {
     		return text.length < length ? text : text.substring(0, length) + '...';
        },
    },
    {
    	name: 'titleCase',
    	execute: (str) => {
    		return str.replace(
	            /\w\S*/g,
	            function(txt) {
	                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	            }
	        );
    	}
    },
    {
    	name: 'capitalize',
    	execute: (str) => {
    		if (!str) return ''
			let value = str.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
    	}
    }
]

export default filters;