<template>
 <div>
    <div
      v-if="permissions['locations.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
    <v-container fluid class="bg-white mt-65">
       <div class="d-flex">
            <h1 class="primetime ">Location</h1>
             <div class="ml-auto mr-4">
    <manage-location @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/>
             </div>
        </div>
        <v-row>
              <v-col cols="12" md="12" class="text-right mt-2">
                  <h5>Showing : {{this.showingFrom? this.showingFrom : "0"}} - {{this.showingTo? this.showingTo: "0"}} of {{this.count? this.count : "0"}}</h5>
              </v-col>
        </v-row>
    <confirmation :confirming="deletingId" @confirmed="remove" :show="deleteConfirmationShow" @canceled="deleteConfirmationShow = false"/>
    <v-simple-table class="mt-4" v-if="locations.data">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">#</th>
          <th class="text-left">Name</th>
          <th class="text-left">Status</th>
          <th class="text-left">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in locations.data" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>
            <v-chip v-if="item.status" color="success" small>Active</v-chip>
            <v-chip v-else color="warning" small>Inactive</v-chip>
          </td>
          <td>
            <div v-if="permissions['locations.view'] == false"></div>
            <v-btn v-else text :to="`/settings/locations/${item.id}`">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <div v-if="permissions['locations.update'] == false"></div>
            <v-btn v-else text @click="edit(item.id)" color="blue">
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
            <div v-if="permissions['locations.destroy'] == false"></div>
            <v-btn v-else text @click="destroy(item.id)" color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <vue-content-loading :width="300" :height="100" v-else>
                <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
     </vue-content-loading>

    <v-row justify="center" style="margin: 0;">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  class="my-4"
                  :total-visible="7"
                  circle
                  @input="getData(pagination.current)"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    </v-container>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ManageLocation from "@/components/settings/locations/Manage.vue";
import Api from "@/services/Api";
import Confirmation from "@/components/Confirmation.vue";
    import { VueContentLoading } from 'vue-content-loading';

export default {
  name: 'locations',
  components: {
      Confirmation,
      ManageLocation,
      VueContentLoading
  },
  data() {
      return {
        permissions: JSON.parse(localStorage.getItem('permissions')),
          editId: null,
          deletingId: null,
          formShow: false,
          deleteConfirmationShow: false,
          locations: {},
          pagination: {
              current: 1,
              total: 0
          },
          loading: false,
           showingFrom:0,
          showingTo:0,
          count:0
      };
  },
  methods: {
      getData(page) {
          let current_page = page;
          if (!current_page) {
              current_page = this.pagination.current;
          }

          Api.get(`/settings/locations?page=${current_page}`).then(res => {
            this.showingFrom = res.data.from;
              this.showingTo = res.data.to;
              this.count = res.data.total;
              this.locations = res.data;
              this.pagination.current = res.data.current_page;
              this.pagination.total = res.data.last_page;
          }).catch(err => {
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data!'+ err)
              this.loading = false;
            }else{
              this.$$toastr.e(err.response.data.status)
            }
          });

          this.editId = null;
          this.deletingId = null;
      },
      remove() {
          Api.delete(`/settings/locations/${this.deletingId}`).then(res => {
              this.getData();
          }).catch(err => {
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data!'+ err)
              this.loading = false;
            }
          });

          this.editId = null;
          this.deletingId = null;
          this.formShow = false;
      },
      edit(id) {
          this.editId = id;
          this.formShow = true;
      },
      destroy(id) {
          this.deletingId = id;
          this.deleteConfirmationShow = true;
      }

  },
  mounted() {
      this.getData();
      var roleId = JSON.parse(localStorage.getItem("user")).role
       if(roleId===0 || roleId===1) {

       }else{
         this.$router.go(-1)
       }
  }
}
</script>
<style scoped>

.home {
  background-color: #f8f8fb;
}
th{
  width: 227px !important;
}

</style>