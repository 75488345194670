<template>
  <div class="home">
    <div
      v-if="roleId == 3 || roleId == 8"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <v-container fluid class="bg-white mt-65" v-else>
      <v-row class="ml-1 mt-1">
        <h2>Graphical Notation</h2>
      </v-row>

      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0 pt-7">
          <div>
            <v-row>
              <v-col cols="12" md="5">
                <!-- <v-select
                  dense
                  outlined
                  v-if="customers"
                  item-text="customer_name"
                  item-value="user_id"
                  @change="getData(1)"
                  :items="customers"
                  v-model="filters.customer_user_id"
                  label="Select Customer"
                  :error-messages="errors.customer_user_id"
                >
                </v-select> -->

                <!--<v-autocomplete-->
                  <!--dense-->
                  <!--outlined-->
                  <!--v-model="filters.customer_user_id"-->
                  <!--:items="customers"-->
                  <!--item-text="customer_name"-->
                  <!--item-value="user_id"-->
                  <!--:search-input.sync="customarUserId"-->
                  <!--hide-no-data-->
                  <!--@change="getData(1)"-->
                  <!--hide-selected-->
                  <!--label="CUSTOMER"-->
                  <!--autocomplete="none"-->
                  <!--:loading="loadingCustomar"-->
                  <!--:error-messages="errors.customer_user_id"-->
                <!--&gt;</v-autocomplete>-->

                <v-autocomplete
                        dense
                        outlined
                        v-model="filters.customer_user_id"
                        :items="customersItem"
                        item-text="customer_name"
                        item-value="user_id"
                        label="CUSTOMER"
                        :search-input.sync="customarUserId"
                        :loading="loadingCustomar"
                        :error-messages="errors.customer_user_id"
                        no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.customer_name +' ('+ data.item.legacy_customer_id +')'"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.company_name"></v-list-item-subtitle>

                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>


              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  dense
                  outlined
                  :items="[20, 50, 75, 100]"
                  v-model="pagination.per_page"
                  :label="`Showing ${pagination.per_page} Items`"
                  @change="getData(1)"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <bar-chart
            ref="customerChart"
            :chart-data="chartData"
            :width="500"
            :height="150"
          ></bar-chart>
        </v-col>
      </v-row>
      <div class="divider"></div>
      <v-row class="mt-4">
        <v-col cols="12" md="6">
          <h3>Customer Details</h3>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <h5>
            Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
            {{ this.showingTo ? this.showingTo : "0" }} of
            {{ this.count ? this.count : "0" }}
          </h5>
        </v-col>
      </v-row>
      <v-simple-table class="mt-10" v-if="report">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">CUSTOMER ID</th>
              <th class="text-left">CUSTOMER NAME</th>
              <th class="text-left">COMPANY NAME</th>
              <th class="text-left">User Email</th>
              <th class="text-left">PHONE UAE</th>
              <th class="text-left">CITY</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report.data" :key="index">
              <td>{{ item.legacy_customer_id }}</td>
              <!-- <td>{{ item.customer_name }}</td> -->
              <td>
                <!-- <a
                  href="`customers/${item.legacy_customer_id}`"
                  target="_blank"
                  >{{ item.customer_name }}</a
                > -->

                <router-link
                  style="text-decoration: none"
                  :to="`/customers/${item.id}`"
                  target="_blank"
                >
                  {{ item.customer_name }}
                </router-link>
              </td>
              <td>{{ item.company_name }}</td>
              <td>{{ item.user ? item.user.email : "-" }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.city }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0;">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";

export default {
  components: {
    VueContentLoading,
    BarChart,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      customers: [],
      loadingCustomar: false,
      customarUserId: "",
      customersItem: [],
      chartData: {
        labels: ["Total Worth", "Remaining Amount", "Discount Given"],
        datasets: [
          {
            label: "Total Worth",
            backgroundColor: ["#2F4F4F", "#128080", "#128080"],
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
      },
      report: {},
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      errors: {},
      filters: {
        customer_user_id: "",
      },
      loading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
    };
  },
  methods: {
    getData(page) {
      this.loading = true;
      if (!page) {
        var page = this.pagination.current;
      }
      Api.get(
        `/reports/customer-report?page=${page}&customer_user_id=${this.filters.customer_user_id}&limit=${this.pagination.per_page}`
      )
        .then((res) => {
          this.showingFrom = res.data.customers.from;
          this.showingTo = res.data.customers.to;
          this.count = res.data.customers.total;
          this.report = res.data.customers;
          this.pagination.per_page = this.report.per_page;
          this.pagination.current = this.report.current_page;
          this.pagination.total = this.report.last_page;
          this.chartData.datasets[0].data = [
            res.data.grand_total,
            res.data.total_pending,
            res.data.total_adjustment,
            0, // Zero for start with 0 for bar chart
          ];
          this.$refs["customerChart"].renderChart(this.chartData, this.options);
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    search() {
      this.getData(1);
    },
    getCustomers() {
      this.loading = true;
      Api.get(`/customers`)
        .then((res) => {
          this.customers = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
  },
  mounted() {
    this.getData(1);
    this.getCustomers();
  },
  watch: {
    customarUserId: function (newVal){
                if (newVal) {
                    this.loadingCustomar = true;
                Api.get(`/customers-item?customer_name=${newVal}`).then(res => {
                    this.customersItem = res.data.data;
                    this.loadingCustomar = false;
                }).catch(err => {
                    this.$toastr.e("Failed to load data!" + err);
                    this.loadingCustomar = false;
                })
                }
            },
  },
};
</script>
<style>
.home {
  background-color: #f8f8fb !important;
}
</style>
