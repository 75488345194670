<template>
  <div class="home">
    <v-container>
      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>
      <div v-else class="bg-white mt-65 pt-5">
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          :msg="confirmationMsg"
          @canceled="deleteConfirmationShow = false"
        />
        <div class="d-flex p-3">
          <h1 class="ml-2" style="margin-top: -13px">User List</h1>
          <div class="ml-auto mt-3" cols="12" md="4">
            <manage
              ref="manage"
              @dataUpdated="getData(pagination.current, filtersUrl())"
              class="d-flex mr-5"
              style="justify-content: flex-end"
            />
          </div>
        </div>

        <v-container>
          <v-row>
            <v-col cols="12" md="12" class="text-right mt-2">
              <h5>
                Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                {{ this.showingTo ? this.showingTo : "0" }} of
                {{ this.count ? this.count : "0" }}
              </h5>
            </v-col>
          </v-row>
        </v-container>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">User Name</th>
                <th class="text-left">Email</th>
                <th class="text-left" style="min-width: 150px">Role Name</th>
                <th class="text-left">Location Name</th>
                <th class="text-left">Status</th>
                <th class="text-left">PERMISSION</th>
                <th>VIEW</th>
                <th>EDIT</th>
                <th>CHANGE STATUS</th>
                <th>DELETE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">
                  <v-text-field
                    outlined
                    class="my-auto"
                    style="height: 40px"
                    dense
                    v-model="filters.username"
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </th>
                <th class="text-left">
                  <v-text-field
                    outlined
                    class="my-auto"
                    style="height: 40px"
                    dense
                    v-model="filters.email"
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </th>
                <th class="text-left">
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="roleName"
                    v-model="filters.role"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </th>
                <th class="text-left"></th>

                <th class="text-left" style="font-weight: 400">
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="statusName"
                    v-model="filters.status"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </th>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <td colspan="5">
                  <v-btn
                    class="mx-2 pull-right"
                    fab
                    dark
                    small
                    color="primary"
                    @click="clearFilter"
                    v-if="isFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2 pull-right"
                    fab
                    dark
                    small
                    color="primary"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="(item, i) in users" :key="item.id">
                <td>{{ i + 1 + (pagination.current - 1) * 20 }}</td>
                <th class="text-left">{{ item.username }}</th>
                <th class="text-left">{{ item.email }}</th>
                <td>{{ item.role_name }}</td>
                <td>{{ item.location_names }}</td>
                <td>
                  <v-chip
                    class="ma-2"
                    small
                    v-if="item.status === 'Active'"
                    color="success"
                    >Active</v-chip
                  >
                  <v-chip class="ma-2" small color="warning" v-else
                    >Inactive</v-chip
                  >
                </td>
                <td>
                  <v-btn
                    elevation="2"
                    small
                    text
                    color="primary"
                    :to="`/acl/users/${item.id}`"
                    v-if="filters.role !== 'Master Admin'"
                  >
                    <!-- <v-icon>mdi-eye-outline</v-icon> -->
                    permission
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    :to="`/users/${item.id}`"
                    class="pa-0 mx-1"
                    style="min-width: 35px !important"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    @click="edit(item.id)"
                    color="blue"
                    class="pa-0 mx-1"
                    style="min-width: 35px !important"
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    elevation="2"
                    small
                    text
                    class="mt-1 pa-2"
                    color="success"
                    @click="(statusId = item.id), (statusConfirm = true)"
                    v-if="item.status === 'Inactive'"
                  >
                    Active
                  </v-btn>

                  <v-btn
                    elevation="2"
                    small
                    text
                    class="mt-1 pa-2"
                    color="warning"
                    @click="(statusId = item.id), (statusConfirm = true)"
                    v-else
                  >
                    InActive
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    v-if="item.role !== 3 && item.role !== 0"
                    text
                    @click="destroy(item.id)"
                    color="red"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div>
      <v-dialog v-model="statusConfirm" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">
            Are you sure want to active/inactive this user?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="statusConfirm = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-1" text @click="statusHandle(statusId)">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
import Manage from "./Manage";

export default {
  name: "roles",
  components: { VueContentLoading, Manage, Confirmation },
  data() {
    return {
      deleteConfirmationShow: false,
      confirmationMsg: "Are you sure want to delete this user?",
      editId: null,
      deletingId: null,
      formShow: false,
      users: {},
      statusConfirm: false,
      filters: {},
      statusId: null,
      loading: false,
      isActive: true,
      pagination: {
        current: 1,
        total: 0,
      },
      roleName: [
        { name: "Master Admin", id: "0" },
        { name: "Super Admin", id: 1 },
        { name: "Location Admin", id: 2 },
        { name: "Customer", id: 3 },
        { name: "Employee", id: 4 },
        { name: "Account", id: 5 },
        { name: "Admin", id: 6 },
        { name: "Location View Admin", id: 7 },
        { name: "Location Restricted Admin", id: 8 },
      ],
      statusName: [
        { title: "Select Status", id: "" },
        { title: "Inactive", id: "0" },
        { title: "Active", id: 1 },
      ],
      showingFrom: 0,
      showingTo: 0,
      count: 0,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      if (!page) {
        page = this.pagination.current;
      }
      this.loading = true;
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/users?${finalSearchQuery}`)
        .then((res) => {
          this.users = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.pagination.per_page = res.data.per_page;
          this.loading = false;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });

      this.editId = null;
      this.deletingId = null;
    },
    getDataWithFilter(finalSearchQuery) {
      Api.get(`/users?${finalSearchQuery}`)
        .then((res) => {
          this.users = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.pagination.per_page = res.data.per_page;
          this.loading = false;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
    },
    statusHandle(id) {
      if (this.users.find((e) => e.id === id).status === "Active") {
        this.users.find((e) => e.id === id).status = "Inactive";
      } else {
        this.users.find((e) => e.id === id).status = "Active";
      }
      this.statusConfirm = false;
      Api.get(`users/${id}/change-status`)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.getData(1, "");
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },
    manualSearch() {
      this.filters.page = 1;
      this.search();
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/users/${this.deletingId}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    // eslint-disable-next-line no-empty
    if (roleId === 0 || roleId === 1) {
    } else {
      this.$router.go(-1);
    }

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.filters.status) {
        this.filters.status = parseInt(this.filters.status);
      }
      if (this.filters.role) {
        this.filters.role = parseInt(this.filters.role);
      }
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
  watch: {
    "filters.role"() {
      this.search();
    },
    // "filters.status"() {
    //   this.search();
    // },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
