import axios from 'axios';
const _token = localStorage.getItem("authtoken");

const downloadFile = (url, fileName, ext = '.xls', callback = null, index = null) => {
    axios
        .get(url, {
            responseType: 'blob',
            headers : {
                "Authorization" : "Bearer "+ _token
            }
        })
        .then((res) => {
            const element = document.createElement('a');
            element.download = fileName + ext;
            element.href = URL.createObjectURL(res.data);
            document.body.appendChild(element);
            element.click();
            if (callback && index) {
                callback(false, index);
            }else if (callback) {
                callback(false);
            }
        });
};


export default downloadFile;



