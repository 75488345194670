<template>
  <v-row>
    <v-col cols="12" md="5">
      <h4>Daily Report ({{ status }})</h4>
      <v-simple-table class="mt-7 daily-report">
        <template v-slot:default>
          <thead>
          <tr>
            <th>Location</th>
            <th>Count</th>
          </tr>
          </thead>
          <tbody>
          <tr class="table-odd" v-for="location in locations">
            <td>{{ location.title }} </td>
            <td>{{ location.count }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col  cols="12" md="7"  class="mt-6" >
      <bar-chart ref="reportChart" :chart-data="chartData" :width="300" :height="300"></bar-chart>
    </v-col>
  </v-row>
</template>
<script>
import BarChart from '@/components/charts/BarChart';
export default {
  props: ['status', 'locations'],
  components: {
    BarChart
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: ['#FFB3C0', '#9FD1F6', '#FFE6AE', '#AADDDE', '#7821b0'],
            data: []
          },
        ]
      },
      options: {
        responsive: true,
      },
    };
  },
  methods: {
    generateChart() {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      this.locations.map((item) => {
        this.chartData.labels.push(item.title);
        this.chartData.datasets[0].data.push(item.count);
      })
      this.chartData.datasets[0].data.push(0); // to start from 0
      this.$refs['reportChart'].renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.generateChart();
  }
}
</script>

<style>
.daily-report table {
  border: 2px solid #eeee !important;
}
td{
  height: 43px !important;
}
.table-odd:nth-child(odd) {
    background: #eeee;
}
</style>