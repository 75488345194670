import store from "@/store/index.js";

import router from './index'
const beforeEach = (to, from, next) => {
	let token = localStorage.getItem('authtoken');
	if (token && ['login', 'password.reset', 'password.forget'].includes(to.name)) {
		next({ name: 'Home' });
	}
	window.scrollTo(0, 0);

	if (to.meta.requiresAuth) {
		store.dispatch('auth/checkTokenExists').then(()=> {
			next({ to: to.path });
		})
		.catch((err) => {
			console.log(err);
			next({ name: 'login' });
		});
	}else{
		next();
	}
};

export default beforeEach;
