<template>
  <v-row>
    <!-- <div class="d-flex justify-space-between flex-column flex-md-row flex-sm-row"> -->
    <v-col cols="12" sm="6">
      <div>
        <v-card-title style="white-space: nowrap; overflow: hidden;"
          >Invoice Overview</v-card-title
        >
        <v-list>
          <v-list-item class="my-n5">
            <v-list-item-icon class="mr-2">
              <v-icon color="#34c38f">mdi-circle-medium</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-2">Paid</v-list-item-title>
          </v-list-item>
          <v-list-item class="my-n5">
            <v-list-item-icon class="mr-2">
              <v-icon color="#6087e0">mdi-circle-medium</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-2">Partially</v-list-item-title>
          </v-list-item>
          <v-list-item class="my-n5">
            <v-list-item-icon class="mr-2">
              <v-icon color="#f46a69">mdi-circle-medium</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-2">Unpaid</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-col>
    <v-col cols="12" sm="6">
      <div class="chart-container mt-5">
        <Doughnut
          ref="invoiceChart"
          :height="200"
          :width="200"
          :chartData="secondChartData"
          :getTotal="totalInvoice"
        ></Doughnut>
      </div>
    </v-col>
    <!-- </div> -->
  </v-row>
</template>

<script>
import Doughnut from "@/components/charts/Doughnut.vue";
export default {
  components: { Doughnut },
  props: ['report'],
  data() {
    return {
      totalOrder: 0,
      totalInvoice: 0,
      secondChartData: {
        hoverBackgroundColor: "red",
        radius: 1,
        hoverBorderWidth: 10,
        labels: ["Paid", "Partially", "Unpaid"],
        datasets: [
          {
            label: ["Invoice Overview"],
            backgroundColor: ["#34c38f", "#6087e0", "#f46a69"],
            data: []
          }
        ]
      }
    };
  },
  methods: {
      generateChartData() {
          this.fillInvoiceChart();
      },

      fillInvoiceChart() {
        this.secondChartData.datasets[0].data = [];
        this.secondChartData.datasets[0].data = [
          this.report.invoice_overview.paid ? this.report.invoice_overview.paid : 0,
          this.report.invoice_overview.partially ? this.report.invoice_overview.partially : 0,
          this.report.invoice_overview.unpaid ? this.report.invoice_overview.unpaid : 0
        ];
        this.totalInvoice = this.report.invoice_overview.total;
        this.$refs['invoiceChart'].render();
        this.$refs['invoiceChart'].update();
      }
  },
  watch: {
      report: function () {
        this.generateChartData();
      }
  }
};
</script>

<style></style>
