<template>
  <div class="home mt-65">
    <search-bar />
    <!-- role 3 for customer -->
    
    <customer-vehicle-status />
    <admin-vehicle-status />
  </div>
</template>

<script>
// @ is an alias to /src\

import SearchBar from '@/components/SearchBar.vue'
import AdminVehicleStatus from '@/components/dashboard/AdminVehicleStatus.vue'
import CustomerVehicleStatus from '@/components/dashboard/CustomerVehicleStatus.vue'
import localforage from "localforage";
// import SearchedItem from '../components/SearchedItem.vue'

export default {
  name: 'Dashboard',
  components: {
    SearchBar,
    AdminVehicleStatus,
    CustomerVehicleStatus
    // SearchedItem
  },
  data() {
    return {
      user: localforage.getItem('user')
    }
  }
}
</script>
<style scoped>

.home{
  background: #107785  !important;
}

</style>