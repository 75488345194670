<template>
    <v-row class="mt-1 float-right">
        <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-if="permissions['countries.store'] == false"></div>
                <v-btn
                v-else
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                >
                    New Country
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                        <span class="headline">{{ editing ? 'Edit' : 'New' }} country</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-text-field dense outlined label="country name" class="mr-5" v-model="form.name" required
                                          :error-messages="errors.name"
                            ></v-text-field>
                            <br>
                            <v-text-field dense outlined label="short code" v-model="form.short_code" required
                                          :error-messages="errors.short_code"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-checkbox label="Status" v-model="form.status" required
                                        :error-messages="errors.status"
                            ></v-checkbox>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="cancel()"
                    >
                        Close
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="editing ? update() : store()"
                    >
                        {{ editing ? 'Update' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import Api from "@/services/Api";
    export default {
        props: ['id', 'show'],
        name: 'ManageLocation',
        data() {
            return {
                permissions: JSON.parse(localStorage.getItem('permissions')),
                dataId: this.id,
                editing: false,
                errors: {},
                dialog: this.show,
                loading: false,
                form: {
                    name: '',
                    short_code:'',
                    status: true,
                }
            }
        },
        methods: {
            store() {
                this.loading = true;
                this.errors = {};
                Api.post(`/settings/countries`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        name: '',
                        short_code:'',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to create data! '+ err);
                })
            },
            async update() {
                this.loading = true;
                this.errors = {};
                await Api.put(`/settings/countries/${this.id}`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        name: '',
                        short_code:'',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to update data! '+ err);
                })

                this.editing = false;
            },
            edit() {
                if (!this.dataId) {
                    return;
                }
                this.loading = true;
                this.errors = {};
                Api.get(`/settings/countries/${this.dataId}`).then(res => {
                    this.form = res.data.data;
                    this.dialog = true;
                    this.loading = false;
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }
if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! '+ err);}
                })
            },
            cancel() {
                this.dataId = false;
                this.editing = false;
                this.dialog = false;
                this.$emit("cancelForm");
            }
        },
        watch: {
            id: function(newVal, oldVal) {
                if (newVal || oldVal) {
                    this.dataId = newVal;
                    this.edit();
                    this.editing = true;
                } else {
                    this.dataId = false;
                    this.editing = false;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            }
        }
    }
</script>