<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="60%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="!id && (roleId === 0 || roleId === 1)"
          @click="newModalOpen"
        >
          Create Notification
        </v-btn>
      </template>

      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline"
            >{{ editing ? "Edit" : "New" }} Notification</span
          >
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
              v-model="form.subject"
              label="SUBJECT"
              :error-messages="errors.subject"
              outlined
              dense
            ></v-text-field>

            <vue-editor v-model="form.message"></vue-editor>
            <br />

            <v-menu
              ref="datePicker11"
              v-model="datePicker11"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :error-messages="errors.expire_date"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="form.expire_date"
                  label="Expire date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors.expire_date"
                ></v-text-field>
              </template>
              <v-date-picker
                style="height: 350px"
                v-model="form.expire_date"
                @input="datePicker11 = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/services/Api";
import { VueEditor } from "vue2-editor";

export default {
  props: ["show", "id"],
  components: {
    VueEditor,
  },
  data() {
    return {
      roleId: parseInt(JSON.parse(localStorage.getItem("user")).role),
      dialog: false,
      editing: false,
      datePicker11: "",
      form: { status: 1, message: "" },
      errors: {},
      loading: false,
      editId: "",
    };
  },
  methods: {
    newModalOpen() {
      this.errors = {};
      this.form = {};
      this.editing = false;
    },
    cancel() {
      this.dialog = false;
    },
    store() {
      Api.post(`/notifications`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });
    },
    edit(id) {
      this.errors = {};
      if (!id) false;
      this.editing = true;
      this.dialog = true;
      this.editId = id;
      Api.get(`/notifications/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    update() {
      Api.put(`/notifications/${this.editId}`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
  },
  computed: {},
  created() {},
  watch: {},
};
</script>
