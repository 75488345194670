<template>
  <v-row class="ma-auto">
    <v-dialog scrollable v-model="dialog" max-width="85%">
      <template v-slot:activator="{ on, attrs }">
        <!-- <div v-if="permissions['vehicles.store'] == false"></div> -->
        <v-btn
          v-if="conversion"
          class="text-capitalize white--text mt-2 ml-3 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="getData()"
          v-bind="attrs"
          v-on="on"
          >Conversations
        </v-btn>
        <div
          v-else
          :class="{
            'red-color': color === 'link_red',
            'blue-color': color === 'link_blue',
            'green-color': color === 'link_green',
          }"
          style="text-decoration: underline"
          color="primary"
          @click="getData()"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ text }}
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <!-- <span class="headline">{{ editing ? "Edit" : "New" }} vehicle</span> -->
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12" sm="12" md="6">
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                    Customer Name
                  </v-col>
                  <v-col cols="12" sm="8" class="pa-3 second-column">
                    {{ vehicle_detail.customer_name }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                    VIN
                  </v-col>
                  <v-col cols="12" sm="8" class="pa-3 second-column">
                    {{ vehicle_detail.vin }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-textarea
              v-model="form.description"
              outlined
              name="input-7-1"
              label="Vehicle Note"
              rows="3"
            ></v-textarea>
            <!-- <v-file-input
              outlined
              dense
              accept="image/*"
              label="Select File"
              @change="photoHandle"
            ></v-file-input> -->

            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            <v-btn
              class="mb-3"
              @click="addNote"
              :loading="loading"
              :disabled="loading"
              >Add Note
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template></v-btn
            >

            <hr />
            <div class="d-flex my-3" style="justify-content: flex-end">
              <v-btn class="mx-1" @click="cancel()">close</v-btn>
              <v-btn class="mx-1" @click="conversionHandel"
                >{{ status === 1 ? "open" : "closed" }} Conversion</v-btn
              >
            </div>
            <hr />
            <div class="py-4"></div>
            <div class="d-flex" style="flex-wrap: wrap">
              <div
                class="box mt-3"
                style="background: #2ba5b6; color: white"
                v-for="(info, i) in boxInfo"
                :key="i"
              >
                <div>
                  <strong>{{ info.created_by }}</strong>
                </div>
                <div>{{ info.created_at }}</div>
                <div>{{ info.description }}</div>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
export default {
  props: [
    "editing",
    "show",
    "text",
    "id",
    "vehicle_detail",
    "conversion",
    "color",
    "status",
  ],
  data() {
    return {
      dialog: this.show,
      boxInfo: [],
      form: {},
      mycolor: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
      loading: false,
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.$emit("notestoggle");
      this.loading = false;
    },
    getData() {
      if (!this.id) {
        return;
      }
      Api.get(`/notes?vehicle_id=${this.id}`).then((res) => {
        this.boxInfo = res.data.data;
      });
    },
    uploadPhoto(event) {
      let files = event.target.files;
      const formData = new FormData();
      for (const i of Object.keys(files)) {
        formData.append("file", files[i]);
      }
      let currentID;
      if (this.id) {
        currentID = this.id;
      } else {
        currentID = 0;
      }
      Api.post("/photo", formData, {}).then((res) => {
        this.form.photo = res.data;
      });
    },
    addNote() {
      this.loading = true;
      let formData = new FormData();
      Object.entries(this.form).forEach(([key, value]) => {
        formData.append(key, value);
      });

      Api.post(`/notes?vehicle_id=${this.id}`, formData).then((res) => {
        (this.form = {}), this.cancel();
        this.dialog = true;
        this.getData();
      });
    },
    photoHandle(e) {
      this.form.photo = e;
    },
    conversionHandel() {
      let updated_status;
      if (this.status && this.status === 1) {
        updated_status = 2;
      } else {
        updated_status = 1;
      }
      Api.post(`vehicles/${this.id}/change-note-status`, {
        note_status: updated_status,
      })
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataupdate");
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {},
  watch: {},
};
</script>
<style>
.box {
  width: 200px;
  height: 200px;
  padding: 10px;
  border-radius: 5px;
  text-align: end;
  /* transform: rotate(-10deg); */
  margin-left: 25px;
}
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.blue-color {
  color: blue;
}
</style>
