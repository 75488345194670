<template>
  <div>
    <div>
      <v-row class="">
        <v-col cols="12" md="4" sm="12">
          <v-card
            class="max-auto"
            max-width="960"
            color="#ededed"
            elevation="0"
          >
            <v-card-title class="white py-2">Welcome to ASL</v-card-title>
            <v-card-text class="d-flex pt-2">
              <v-avatar size="70">
                <img alt="user" :src="`${profileInfo.photo}`" />
              </v-avatar>
              <div class="d-flex flex-column justify-center">
                <h3 class="ml-3" v-if="roleId == 3">
                  {{ profileInfo.company_name }}
                </h3>
                <h3 class="ml-3" v-else>{{ profileInfo.username }}</h3>
                <p class="ml-3 subtitle-2 mb-0">{{ profileInfo.role_name }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="12">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
              v-for="(overview, i) in status_overview"
              :key="i"
            >
              <v-card color="white pt-5" elevation="0">
                <div class="d-flex mx-4 justify-space-between">
                  <div class="d-flex flex-column justify-center">
                    <router-link
                      class="dashboard-link"
                      :to="`vehicles?status=${overview.status}`"
                    >
                      <p>{{ overview.label }}</p>
                      <p class="font-weight-black headline">
                        {{ overview.total }}
                      </p>
                    </router-link>
                  </div>
                  <div class="d-flex flex-column justify-center mb-2">
                    <v-avatar color="#E3F2FD">
                      <v-img :src="`${overview.logo}`"></v-img>
                    </v-avatar>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
export default {
  data() {
    return {
      status_overview: [],
      user: null,
      profileInfo: {},
      roleId: JSON.parse(localStorage.getItem("user")).role,
    };
  },
  methods: {
    getProfile() {
      this.profileInfo = JSON.parse(localStorage.getItem("user"));
    },
    getReportData() {
      this.loading = true;
      let url = `/status-overview`;
      Api.get(url)
        .then((res) => {
          this.status_overview = res.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
  },
  created() {
    this.getProfile();
    this.getReportData();
  },
};
</script>
<style>
.dashboard-link {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none !important;
}
</style>
