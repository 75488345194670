<template>
  <div>
    <div class="pt-8 mb-8">
      <div>
        <v-row>
          <v-col cols="12" sm="6" md="6" xl="6">
            <!-- WRAPPER -->
            <v-row class="">
              <v-col cols="12" md="8" xl="5" class="py-0">
                <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  label="Vehicle search by vin/lot/Ar/Cont No"
                  v-model="filters.vehicle_global_search"
                  solo
                  @keypress.enter="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="1" class="py-0 mr-4">
                <v-btn
                  large
                  color="#E3F2FD"
                  elevation="0"
                  class="text-capitalize mt-1 d-block mr-5"
                  width="100%"
                  @click="search"
                  >Search
                </v-btn>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="1" class="py-0">
                <v-btn
                  large
                  color="#E3F2FD"
                  elevation="0"
                  class="text-capitalize mt-1 ml-5"
                  width="100%"
                  @click="ImageHandle()"
                  >Image
                </v-btn>
              </v-col>
            </v-row>
            <!-- WRAPPER  ENDS -->
          </v-col>
          <v-col cols="12" sm="6" md="6" xl="6">
            <!-- WRAPPER -->
            <v-row class="">
              <v-col cols="12" sm="6" md="6" xl="6">
                <v-row class="">
                  <v-col cols="12" sm="8" md="8" xl="8" class="py-0">
                    <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      label="Container Tracking"
                      v-model="number"
                      solo
                      @keypress.enter="trackContainer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" class="py-0">
                    <v-btn
                      large
                      color="#E3F2FD"
                      elevation="0"
                      class="text-capitalize mt-1 d-block mr-5"
                      :loading="loading"
                      :disabled="loading"
                      @click="trackContainer"
                      >Track
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>

                    <!--<v-btn-->
                    <!--class="ma-2"-->
                    <!--:loading="loading4"-->
                    <!--:disabled="loading4"-->
                    <!--color="info"-->
                    <!--@click="loader = 'loading4'"-->
                    <!--&gt;-->
                    <!--Icon Loader-->
                    <!--<template v-slot:loader>-->
                    <!--<span class="custom-loader">-->
                    <!--<v-icon light>mdi-cached</v-icon>-->
                    <!--</span>-->
                    <!--</template>-->
                    <!--</v-btn>-->
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" md="6" xl="6">
                <v-row>
                  <v-col cols="12" sm="8" md="8" xl="8" class="py-0">
                    <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      label="Copart Search"
                      v-model="copart_number"
                      solo
                      @keypress.enter="searchcopart"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="3" lg="3" class="py-0">
                    <v-btn
                      large
                      color="#E3F2FD"
                      elevation="0"
                      class="text-capitalize mt-1 d-block"
                      @click="searchcopart"
                    >
                      <img
                        src="images/copart_logo.png"
                        alt="Copart"
                        height="30"
                        width="78"
                      />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- WRAPPER  ENDS -->
          </v-col>
          <!-- WRAPPER  ENDS-->
        </v-row>
        <v-row nodense class="d-flex flex-row">
          <v-col v-if="roleId !== 3">
            <manage
              ref="manage"
              :dash="true"
              :show="formShow"
              :custom="true"
              @cancelForm="formShow = null"
            />
          </v-col>
          <v-col v-if="roleId === 0 || roleId === 3 || roleId === 5">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/invoices-summary"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-file-document</v-icon
                >See Invoices
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId === 0 || roleId === 3 || roleId === 5">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/invoices-summary"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
                ><v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon
                >Total Balance
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId !== 8 && roleId !== 4 && roleId !== 2">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/claims"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon>
                Damage Claim
                <p class="complain-counter" v-if="roleId !== 3">
                  {{ damage_claim_count }}
                </p>
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId !== 8 && roleId !== 4 && roleId !== 2">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/storage-claims"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon>
                Storage Claim
                <p class="complain-counter" v-if="roleId !== 3">
                  {{ storage_claim_count }}
                </p>
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId !== 8 && roleId !== 4 && roleId !== 2">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/key-missing-claims"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon>
                Key Missing Claim
                <p class="complain-counter" v-if="roleId !== 3">
                  {{ key_missing_claim_count }}
                </p>
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['containers.index'] == true" class="custom_button">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/containers"
            >
              <v-btn
                small
                elevation="0"
                class="button-short text-capitalize d-block"
                ><v-icon class="ml-1" left color="gray"
                  >mdi-view-module-outline</v-icon
                >All Containers
              </v-btn>
            </router-link>
          </v-col>
          <v-col>
            <manage2 :inventory="true" v-if="roleId !== 2" />
          </v-col>
          <v-col v-if="roleId !== 8 && roleId !== 4 && roleId !== 2">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/feedbacks"
            >
              <v-btn
                small
                elevation="0"
                class="button-short text-capitalize d-block"
                style="position: relative"
              >
                Feedback
                <p class="feedback-counter" v-if="roleId !== 3">
                  {{ feedback_count }}
                </p>
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId !== 8 && roleId !== 4 && roleId !== 2">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/complains"
            >
              <v-btn
                small
                elevation="0"
                class="button-short text-capitalize d-block"
                style="position: relative"
              >
                Complain
                <p class="complain-counter" v-if="roleId !== 3">
                  {{ complain_count }}
                </p>
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="roleId === 3 ">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/reports/customer-title-status"
            >
              <v-btn
                small
                elevation="0"
                class="button-short text-capitalize d-block"
              >
                <v-icon class="ml-1" left color="gray"
                  >mdi mdi-file-document</v-icon
                >
                Title Report
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=2"
            >
              <v-btn
                small
                elevation="0"
                class="button-short text-capitalize d-block"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon>
                Shipping rates
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=1"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
                ><v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon
                >Towing rates
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=4"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon>
                Single Vehicle Rate
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=3"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-bike</v-icon>Bike
                Towing
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=5"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-car</v-icon
                >Destination Rate
              </v-btn>
            </router-link>
          </v-col>
          <v-col v-if="permissions['prices.index'] == true">
            <router-link
              style="font-weight: bold; text-decoration: none"
              to="/prices?pricing_type=6"
            >
              <v-btn
                small
                elevation="0"
                class="text-capitalize d-block button-short"
              >
                <v-icon class="ml-1" left color="gray">mdi-currency-usd</v-icon
                >Full Cont. Rate
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </div>

      <div v-show="showtable" class="mt-5 mb-5">
        <v-simple-table
          class="mt-10 mb-5 seach-bar__table"
          style="overflow: auto"
        >
          <template v-slot:default>
            <thead>
              <tr v-if="vehicles.data">
                <th class="text-left">Customer Name</th>
                <th class="text-left">Company Name</th>
                <th class="text-left">Container Number</th>
                <th class="text-left">VIN</th>
                <th class="text-left">Status</th>
                <th class="text-left">Lot Number</th>
                <th v-if="roleId !== 3" class="text-left">All Vehicles</th>
                <th v-if="roleId !== 3" class="text-left">All Export</th>
                <th class="text-left">Manifest</th>
                <th class="text-left">B/L</th>
                <th class="text-left">View</th>
              </tr>
            </thead>
            <tbody v-if="vehicles.data">
              <tr v-for="item in vehicles.data" :key="item.name">
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="`/vehicles/${item.id}`"
                  >
                    {{ item.customer_name }}
                  </router-link>
                </td>

                <td>{{ item.company_name }}</td>
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="`/vehicles/${item.id}`"
                  >
                    {{ item.container_number }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="`/vehicles/${item.id}`"
                  >
                    {{ item.vin }}
                  </router-link>
                </td>
                <td>{{ item.status_name }}</td>
                <td>
                  <manage-details
                    :lot="true"
                    :text="item.lot_number"
                    :id="item.id"
                  />
                </td>
                <td v-if="roleId !== 3">
                  <v-btn
                    @click="viewHandle(item.customer_user_id)"
                    color="#E0E0E0"
                    elevation="0"
                    small
                    >VIEW</v-btn
                  >
                </td>
                <td v-if="roleId !== 3">
                  <v-btn
                    @click="exportViewHandle(item.customer_user_id)"
                    color="#E0E0E0"
                    elevation="0"
                    small
                    >VIEW</v-btn
                  >
                </td>
                <td>
                  <v-btn
                    color="#E0E0E0"
                    elevation="0"
                    @click="
                      modalDetails({
                        email: `/exports/${item.export_id}/manifest-modal?email=1`,
                        openPdf: `/exports/${item.export_id}/manifest-pdf`,
                        api: `exports/${item.export_id}/manifest-modal`,
                      })
                    "
                    small
                    v-if="item.export_id"
                    ><v-icon>mdi-file-document-multiple-outline</v-icon></v-btn
                  >
                </td>
                <td>
                  <v-btn
                    color="#E0E0E0"
                    elevation="0"
                    small
                    @click="
                      modalDetails({
                        email: `/exports/${item.export_id}/landing-pdf`,
                        openPdf: `/exports/${item.export_id}/landing-pdf`,
                        api: `exports/${item.export_id}/landing-modal`,
                      })
                    "
                    v-if="item.export_id"
                    ><v-icon>mdi-file-document-multiple-outline</v-icon></v-btn
                  >
                </td>
                <td>
                  <router-link
                    target="_blank"
                    style="text-decoration: none"
                    :to="`/vehicles/${item.id}`"
                  >
                    View
                  </router-link>
                </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <details-modal
          :show="updateDetals"
          @clearform="updateDetals = !updateDetals"
          :details="details"
        />
      </div>
      <!-- image modal -->
      <div v-show="showimage && vehicles2" class="mt-5 mb-5">
        <div class="container-fluid mb-5">
          <v-tabs align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab> USA YARD PHOTOS </v-tab>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab> AUCTION PHOTOS </v-tab>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab> PICKUP PHOTOS </v-tab>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab> ARRIVED PHOTOS </v-tab>
            <v-tab-item>
              <div class="vehicle" style="margin-top: 18px">
                <v-container
                  class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
                >
                  <h3 class="primetime text-uppercase">USA YARD PHOTOS</h3>
                </v-container>
                <v-container class="white mt-1 pt-5 px-10">

                  <v-row>
                    <div class="images" v-viewer>
                      <img v-for="{thumbnail, url } in vehicles2.photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                    </div>
                    <!-- component -->
                    <viewer :images="vehicles2.photos">
                      <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                    </viewer>

                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="vehicle" style="margin-top: 20px">
                <v-container
                  class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
                >
                  <h3 class="primetime text-uppercase">Auction Photos</h3>
                </v-container>
                <v-container class="white mt-1 pt-5 px-10">
                  <v-row>
                    <div class="images" v-viewer>
                      <img v-for="{thumbnail, url } in vehicles2.auction_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                    </div>
                    <!-- component -->
                    <viewer :images="vehicles2.auction_photos">
                      <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                    </viewer>

                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="vehicle" style="margin-top: 20px">
                <v-container
                  class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
                >
                  <h3 class="primetime text-uppercase">Pickup Photos</h3>
                </v-container>

                <v-container class="white mt-1 pt-5 px-10">
                  <v-row>
                    <div class="images" v-viewer>
                      <img v-for="{thumbnail, url } in vehicles2.pickup_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                    </div>
                    <!-- component -->
                    <viewer :images="vehicles2.pickup_photos">
                      <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                    </viewer>

                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="vehicle" style="margin-top: 20px">
                <v-container
                  class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
                >
                  <h3 class="primetime text-uppercase">Arrived Photos</h3>
                </v-container>

                <v-container class="white mt-1 pt-5 px-10">
                  <v-row>
                    <div class="images" v-viewer>
                      <img v-for="{thumbnail, url } in vehicles2.arrived_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                    </div>
                    <!-- component -->
                    <viewer :images="vehicles2.arrived_photos">
                      <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                    </viewer>

                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>

      <!-- image modal end -->
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import detailsModal from "@/views/exports/detailsModal.vue";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import Manage from "../components/dashboard/Manage";
import Manage2 from "../components/dashboard/Manage2";
export default {
  components: {
    detailsModal,
    Manage,
    ManageDetails,
    Manage2,
  },
  data() {
    return {
      roleId: null,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      number: "",
      copart_number: "",
      showimage: false,
      showtable: false,
      vehicleArrivedGallar: false,
      vehicleauctionGallar: false,
      vehiclePicupGallar: false,
      details: {},
      vehicles: {},
      vehicles2: {},
      images: [],
      vehicleGallar: false,
      currentUrl: null,
      updateDetals: false,
      formShow: false,
      pagination: {
        current: 1,
        total: 0,
      },
      loading: false,
      filters: {},
      loader: null,
    };
  },
  methods: {
    ImageHandle() {
      this.vehicles2 = [];
      this.showimage = false;
      this.showtable = false;
      Api.get(`vehicles/search-by-vin?q=${this.filters.vehicle_global_search}`)
        .then((res) => {
          this.vehicles2 = res.data.data;
          if (this.vehicles2) {
            this.showimage = true;
          }
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    searchcontainer() {
      window
        .open(
          `https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html?container=${this.number}`,
          "_blank"
        )
        .focus();
    },
    trackContainer() {
      if (!this.number) {
        return false;
      }
      this.loading = true;
      this.loader = "loading";
      let track_model = this.number;
      Api.get(`/exports/tracking-url/${track_model}`)
        .then((res) => {
          this.loader = null;
          this.loading = false;
          if (res.data.url) {
            window.open(`${res.data.url}`, "_blank").focus();
          } else {
            this.$toastr.e(res.data.message);
          }
        })
        .catch((err) => {
          this.loader = null;
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    searchcopart() {
      window
        .open(
          `https://www.copart.com/lotDetail/${this.copart_number}`,
          "_blank"
        )
        .focus();
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        var page = this.pagination.current;
      }
      if (searchQuery) {
        var searchQuery = `&${searchQuery}`;
      }
      Api.get(`/vehicles?limit=10&page=${page}${searchQuery}`)
        .then((res) => {
          this.vehicles = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
    search() {
      // this.filtering = true;
      this.$router.push({
        path: this.$route.path,
        query: { vehicle_global_search: this.filters.vehicle_global_search },
      });

      if (!this.filters.vehicle_global_search) {
        this.vehicles = {};
        return;
      }
      this.getData(1, this.filtersUrl());
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      this.showimage = false;
      this.showtable = true;
      return str;
    },
    modalDetails(e) {
      (this.updateDetals = !this.updateDetals), (this.details = e);
    },
    viewHandle(id) {
      window.open(`/vehicles?customer_user_id=${id}`, "_blank");
    },
    exportViewHandle(id) {
      window.open(`/exports?customer_user_id=${id}`, "_blank");
    },
  },
  mounted() {
    // this.getData();
    this.roleId = parseInt(JSON.parse(localStorage.getItem("user")).role);
    if (this.$route.query.vehicle_global_search) {
      this.filters.vehicle_global_search =
        this.$route.query.vehicle_global_search;
      this.getData(1, this.filtersUrl());
    }
  },
  computed: {
    feedback_count() {
      return this.$store.getters["counter/counter"].feedback_count;
    },
    complain_count() {
      return this.$store.getters["counter/counter"].complain_count;
    },
    damage_claim_count() {
      return this.$store.getters["counter/counter"].damage_claim_count;
    },
    storage_claim_count() {
      return this.$store.getters["counter/counter"].storage_claim_count;
    },
    key_missing_claim_count() {
      return this.$store.getters["counter/counter"].key_missing_claim_count;
    },
  },
};
</script>

<style scoped>
/* .seach-bar__table .v-data-table__wrapper{
    width: 120% !important;
} */
button.text-capitalize.d-block.button-short.v-btn.v-btn--contained.v-btn--is-elevated.v-btn--has-bg.theme--light.elevation-0.v-size--small,
.button-short {
  background-color: white;
  border-color: white;
  width: 100%;
  padding: 5px 15px 15px 2px;
}

.feedback-counter {
  position: absolute;
  right: -20px;
  top: -18px;
  text-align: center;
  color: white;
  min-width: 19px;
  background: #555;
  border-radius: 5px;
  min-height: 16px;
  font-size: 10px;
  padding: 2px;
}

.complain-counter {
  position: absolute;
  right: -20px;
  top: -18px;
  min-width: 19px;
  background: #555;
  border-radius: 5px;
  text-align: center;
  color: white;
  min-height: 16px;
  font-size: 10px;
  padding: 2px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom_button{
  max-width: 200px;
}
</style>
