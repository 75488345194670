<template>
  <div id="app" v-cloak>
    <router-view/>
    <login-popup class="popup-component" />
  </div>
</template>

<script>
import LoginPopup from './components/LoginPopup.vue';
	export default {
    components:{
      LoginPopup
    }
  }
</script>
<style >
.home {
  background-color: #f8f8fb;
  padding: 24px !important;
}
.bg-white{
  background: white !important;
}
.mt-65{
  margin-top: 64px;
}
.popup-component {
  position: fixed !important;
  bottom: 25px;
  right: 25px;
}
.v-picker__body {
  height: 20rem;
}
</style>