<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="45%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!id"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="newModalOpen"
        >
          New User
        </v-btn>
      </template>

      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} User</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
              v-model="form.username"
              label="Username"
              :error-messages="errors.username"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              label="Email"
              :error-messages="errors.email"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              label="Password"
              :error-messages="errors.password"
              :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              outlined
              dense
            ></v-text-field>
            <v-select
              class="mb-6"
              item-text="name"
              item-value="id"
              :items="roleName"
              v-model="form.role"
              label="Role Name"
              outlined
              dense
              hide-details
              :error-messages="errors.role"
            ></v-select>
            <!-- <v-autocomplete
              
              dense 
              outlined
              v-model="form.loaction"
              :items="location"
              item-text="customer_name"
              item-value="user_id"
              :search-input.sync="customerUserId"
              hide-no-data
              hide-selected
              label="Location"
              autocomplete="none"
              :error-messages="errors.loaction"
            ></v-autocomplete> -->

            <v-select
              class="mb-6"
              v-if="adminRole"
              item-text="name"
              item-value="id"
              :items="locations"
              v-model="form.locations"
              label="Location"
              outlined
              dense
              multiple
              hide-details
              :error-messages="errors.locations"
            ></v-select>

            <!-- <v-autocomplete
              v-if="customerRestricted"
              dense
              outlined
              v-model="form.customers"
              :items="customersItem"
              label="Customer Name"
              item-text="legacy_customer_id"
              item-value="legacy_customer_id"
              :search-input.sync="customarUserId"
              :loading="loading2"
              no-filter
              multiple
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="
                        data.item.customer_name +
                        ' (' +
                        data.item.legacy_customer_id +
                        ')'
                      "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.company_name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete> -->

            <v-text-field
              v-if="customerRestricted"
              v-model="form.customers"
              label="Customers"
              :error-messages="errors.customers"
              outlined
              dense
            ></v-text-field>

            <v-select
              class=""
              item-text="title"
              item-value="id"
              :items="statusName"
              v-model="form.status"
              label="Status"
              outlined
              dense
              hide-details
              :error-messages="errors.status"
            ></v-select>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/services/Api";
export default {
  props: ["show", "id"],
  data() {
    return {
      dialog: false,
      editing: false,
      form: { status: 1, customers: [] },
      errors: {},
      loading: false,
      customerUserId: "",
      editId: "",
      roleName: [
        { name: "Select Role", id: "" },
        { name: "Master Admin", id: 0 },
        { name: "Super Admin", id: 1 },
        { name: "Location Admin", id: 2 },
        { name: "Employee", id: 4 },
        { name: "Account", id: 5 },
        { name: "Admin", id: 6 },
        { name: "Location View Admin", id: 7 },
        { name: "Location Restricted Admin", id: 8 },
      ],
      statusName: [
        { title: "Select Status", id: "" },
        { title: "In-active", id: "0" },
        { title: "Active", id: 1 },
      ],
      locations: {},
      show1: false,
      customarUserId: "",
      customarIdLoading: false,
      loading2: false,
      customersItem: [],
    };
  },
  methods: {
    newModalOpen() {
      this.errors = {};
      this.form = { status: 1 };
      this.editing = false;
    },
    cancel() {
      this.dialog = false;
    },
    store() {
      if (this.form.customers && this.form.customers.length > 0) {
        this.form.customers = this.form.customers.split(",");
      }

      Api.post(`/users`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    edit(id) {
      this.errors = {};
      if (!id) false;
      this.editing = true;
      this.dialog = true;
      this.editId = id;
      Api.get(`/users/${id}`)
        .then((res) => {
          this.form = res.data.data;
          if (res.data.data.status === "Active") {
            this.form.status = 1;
          } else {
            this.form.status = "0";
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
      this.cusotmarAutoCompleteHandelar("");
    },
    update() {
      if (this.form.customers && this.form.customers.length > 0) {
        this.form.customers = this.form.customers.split(",");
      }

      Api.put(`/users/${this.editId}`, this.form)
        .then((res) => {
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    getLocation() {
      if (this.adminRole) {
        Api.get(`/settings/locations?status=1`)
          .then((res) => {
            this.locations = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loading = false;
            }
          });
      }
    },
    cusotmarAutoCompleteHandelar(e) {
      this.customarIdLoading = true;
      Api.get(`/customers-item?customer_name=${e}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.customarIdLoading = false;
        })
        .catch((err) => {
          // console.log(err.response.data);
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.customarIdLoading = false;
          this.$toastr.e("Failed to create data! " + err);
        });
    },

    fetchEntriesDebounced(val) {
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    adminRole() {
      if (
        this.form.role === 2 ||
        this.form.role === 7 ||
        this.form.role === 8
      ) {
        return true;
      }
    },

    customerRestricted() {
      if (this.form.role === 8) {
        return true;
      }
      return false;
    },
  },
  created() {},
  watch: {
    editing(newVal) {
      if (newVal) {
        this.roleName.push({ name: "Customer", id: 3 });
      } else {
        this.roleName.splice(
          this.roleName.findIndex((e) => e.id === 3),
          1
        );
      }
    },
    "form.role"() {
      this.getLocation();
    },
    customarUserId: function (newVal) {
      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
  },
};
</script>
