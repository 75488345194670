<template>
  <div>
    <div class=" home ">
      <v-row class="mt-65" style='background:white'>
        <v-col  cols="12" md="3" v-for="(info,index) in overView" :key="index">
          <div style="border:1px solid" class="mx-1 pa-1" elevation="0"> 
              <div>Location: <span style="font-weight:bold">{{info.location}}</span> </div>
              <div>
                  <table class="table-data" >
                      <tr>
                          <td > EXPORTABLE</td>
                          <th>: {{info.title_counts.exportable}}</th>
                      </tr>
                      <tr>
                          <td > PENDING</td>
                          <th>: {{info.title_counts.pending}}</th>
                      </tr>
                      <tr>
                          <td > MV907</td>
                          <th>: {{info.title_counts.mv907}}</th>
                      </tr>
                      <tr>
                          <td > LIEN</td>
                          <th>: {{info.title_counts.lien}}</th>
                      </tr>
                      <tr>
                          <td > BOS</td>
                          <th>: {{info.title_counts.bos}}</th>
                      </tr>
                      <tr>
                          <td > REJECTED</td>
                          <th>: {{info.title_counts.rejected}}</th>
                      </tr>

                      <tr>
                          <td > NO TITLE</td>
                          <th>: {{info.title_counts.no_title}}</th>
                      </tr>

                     
                  </table>
              </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="home">
      <v-container fluid class="bg-white">
        <div class="d-flex pb-4">
          <h1 class="primetime ">Loading Of Vehicles</h1>
          <div class="ml-auto mr-4 d-flex" cols="12" md="2">
            <v-btn class="ml-auto mt-1 mr-5" color="primary" :loading="downloading" @click="exportLoading">export excel</v-btn>
          </div>
          <!--  -->
        </div>
        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-0">
            <div>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.vehicle_global_search"
                    label="Global Search"
                    @keypress.enter="search"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn :loading="filtering" color="primary" @click="search"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="4" class="text-right">
                  <h5>Showing : {{this.showingFrom? this.showingFrom : "0"}} - {{this.showingTo? this.showingTo: "0"}} of {{this.count? this.count : "0"}}</h5>
              </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />
        <vue-content-loading :width="300" :height="100" v-if="loading">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>
        <v-simple-table v-if="vehicles.data && !loading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Customer</th>
                 <th class="text-left">Customer ID</th>
                <th class="text-left">ON THE WAY</th>
                <th class="text-left">ON HAND</th>
                <th class="text-left">NO TITLE</th>
                <th class="text-left">EXPORTABLE</th>
                <th class="text-left">PENDING</th>
                <th class="text-left">BOS</th>

                <th class="text-left">LIEN</th>
                <th class="text-left">REJECTED</th>
                <th class="text-left">MV907</th>

                <th class="text-left">LOADING STATUS</th>
                <th class="text-left">LOADING INSTRUCTION</th>
                <th class="text-left">ALL VEHICLE</th>

                <th class="text-left">ALL CONTAINERS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in vehicles.data" :key="item.name">
                <td >{{ item.customer_name }}</td>
                <td>{{ item.legacy_customer_id }}</td>
                <td @click="statusHandle(3,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.on_the_way }}</td>
                <td @click="statusHandle(1,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.on_hand }}</td>
                <td @click="titleHandle(0,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.no_title }}</td>
                <td @click="titleHandle(1,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.exportable }}</td>
                <td @click="titleHandle(2,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.pending }}</td>
                <td @click="titleHandle(3,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.bos }}</td>
                <td @click="titleHandle(4,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.lien }}</td>
                <td @click="titleHandle(6,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.rejected }}</td>
                <td @click="titleHandle(5,item.customer_user_id)" style="color:blue;cursor:pointer">{{ item.mv907 }}</td>
                <td >{{ item.load_status }}</td>
                <td>{{ item.loading_instruction }}</td>
               <td style="cursor:pointer;color:blue" @click="viewHandle(item.customer_user_id)">
                  <!-- <v-btn
                    @click="viewHandle(item.customer_user_id)"
                    color="#E0E0E0"
                    elevation="0"
                    small
                    ></v-btn
                  > -->
                  View
                </td>
                <td style="cursor:pointer;color:blue" @click="exportViewHandle(item.customer_user_id)">
                  <!-- <v-btn
                    @click="exportViewHandle(item.customer_user_id)"
                    color="#E0E0E0"
                    elevation="0"
                    small
                    >VIEW</v-btn
                  > -->
                  View
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" style="margin: 0;">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <!--<manageedit @dataUpdated="getData()" :id="editId" :show="formShowEdit" :editing="true" @cancelForm="formShow = false"/>-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Api from "@/services/Api";
import Manage from "@/views/vehicles/Manage.vue";
import NotesModal from "@/components/vehicle/NotesModal.vue";
// import Manageedit from "@/views/vehicles/Manageedit.vue";
import Confirmation from "@/components/Confirmation.vue";
import { VueContentLoading } from "vue-content-loading";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/helpers/downloadFile";
export default {
  name: "Vehicles",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
    NotesModal,
    ManageDetails,
  },
  data() {
    return {
      notesShow: false,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      // formShowEdit: false,
      deleteConfirmationShow: false,
      vehicles: {},
      customarUserId: "",
      pagination: {
        current: 1,
        total: 0,
      },
      keysItem: [
        { title: "", id: "" },
        { title: "Yes", id: "1" },
        { title: "No", id: "0" },
      ],
      locationsItem: [
        { title: "", id: "" },
        { title: "LA", id: 1 },
        { title: "LA", id: 1 },
        { title: "GA", id: 2 },
        { title: "NY", id: 3 },
        { title: "TX", id: 4 },
        { title: "BALTIMORE", id: 5 },
        { title: "NEWJ-2", id: 6 },
        { title: "TEXAS", id: 7 },
        { title: "NJ", id: 8 },
      ],
      titlesItem: [
        { title: "", id: "" },
        { title: "NO TITLE", id: 0 },
        { title: "EXPORTABLE", id: 1 },
        { title: "PENDING", id: 2 },
        { title: "BOS", id: 3 },
        { title: "LIEN", id: 4 },
        { title: "MV907", id: 5 },
        { title: "REJECTED", id: 6 },
      ],
      statusItem: [
        { title: "", id: "" },
        { title: "ON HAND", id: 1 },
        { title: "MANIFEST", id: 2 },
        { title: "ON THE WAY", id: 3 },
        { title: "SHIPPED", id: 4 },
        { title: "PICKED UP", id: 5 },
        { title: "ARRIVED", id: 6 },
      ],
      claimStatus: [
        { title: "" },
        { title: "REJECTED", id: "0" },
        { title: "APPROVED", id: 1 },
        { title: "PENDING", id: 10 },
      ],
      damageClaim: [
        { title: "", id: "" },
        { title: "YES", id: 1 },
        { title: "NO", id: "0" },
      ],
      customersItem: [],
      loadingTypesItem: [
        { title: "", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      notesItem: [
        { title: "", id: "" },
        { title: "Open", id: "0" },
        { title: "Closed", id: 1 },
      ],
      vehicleItem: [
        { title: "", id: "" },
        { title: "Sedan", id: "Sedan" },
        { title: "Van", id: "Van" },
        { title: "Pickup", id: "Pickup" },
        { title: "Truck", id: "Truck" },
        { title: "Mortorcycle", id: "Mortorcycle" },
      ],
      loading: false,
      filters: {},
      filtering: false,
      loading2: false,
      showingFrom:0,
          showingTo:0,
          count:0,
      overView:{},
      downloading : false,
    };
  },

  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {
    viewHandle(id) {
      window.open(`/vehicles?status=1&customer_user_id=${id}`, "_blank");
    },
    exportViewHandle(id) {
      window.open(`/exports?loading_of_vehicle=1&customer_user_id=${id}`, "_blank");
    },
    
    titleHandle(title,id){
        window.open(`/vehicles?customer_user_id=${id}&status=1&title=${title}`, "_blank");
    },
    statusHandle(status,id){
        window.open(`/vehicles?customer_user_id=${id}&status=${status}`, "_blank");
    },
    addToCart(item) {
      let items = localStorage.getItem("asl_checkout");
      if (items) {
        items = JSON.parse(items);
      } else {
        items = {};
      }
      //  cart item logic

      if (items.hasOwnProperty(item.location_id)) {
        items[item.location_id].forEach((e) => {
          if (e.id == item.id) {
            return;
          } else {
            items[item.location_id].push(item);
          }
        });
      } else {
        items[item.location_id] = [item];
      }
      localStorage.setItem("asl_checkout", JSON.stringify(items));
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        var page = this.pagination.current;
      }
      if (searchQuery) {
        var searchQuery = `&${searchQuery}`;
      }

      Api.get(`/customers/loading-status?page=${page}${searchQuery}`)
        .then((res) => {
          this.vehicles = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.getApi = false;
          this.showingFrom = res.data.from;
              this.showingTo = res.data.to;
              this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }else{
            this.$toastr.e(err.response.data.status)
          }
        });
    },
    getOverView(){
   Api.get(`/vehicles/location-wise-title`)
        .then((res) => {
          this.overView = res.data;
        
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });
    },
    invoiceUrl(e) {
      window.open(e, "_blank");
    },
    loadingManage (value) {
      this.downloading = value;
    },
    exportLoading() {
      this.loadingManage(true);
      let url = Api.defaults.baseURL + `/customers/loading-status/export`;
      // window.location = url;

      downloadFile(url,
          'loading_vehicle_export',
          '.xls',
          this.loadingManage
      );
    },

    search() {
      this.getData(1, this.filtersUrl());
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    remove() {
      Api.delete(`/vehicles/${this.deletingId}`)
        .then((res) => {
          this.getData();
          // this.$toastr.s(res.message);
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
      // this.formShowEdit = false;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  mounted() {
    // this.getData();
    // this.getCustomerItem();

    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.mode && this.$route.query.mode === "openModal") {
        this.formShow = true;
      }
      this.filters = this.$route.query;
      this.search();
    } else {
      this.getData();
    }
    this.getOverView()
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    customarUserId(newVal) {
      if (newVal) {
        this.loading2 = true;
        Api.get(`/customers-item?status=1&customer_name=${newVal}`)
          .then((res) => {
            this.loading2 = false;
            this.customersItem = res.data.data;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading2 = false;
            }
          });
      }
    },
    "filters.damage_claim"() {
      this.search();
    },
    "filters.claim_status"() {
      this.search();
    },
    "filters.keys"() {
      this.search();
    },
    "filters.title"() {
      this.search();
    },
    "filters.location"() {
      this.search();
    },
    "filters.status"() {
      this.search();
    },
    "filters.loading_type"() {
      this.search();
    },
    "filters.notes_status"() {
      this.search();
    },
    "filters.vehicle_type"() {
      this.search();
    },
    "filters.customer_user_id"() {
      this.search();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  width: 200px !important;
}
.manage-details {
  color: red !important;
  background: white !important;
}
.table-data td{
    height: 22px !important;
    width: 136px !important;
}
</style>
