<template>
  <div class="home">
    <v-container>
      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>
      <div v-else class="bg-white mt-65 pt-5">
        <div class="d-flex" style="justify-content: space-between;">
          <h1 class="primetime  ml-5">Notifications</h1>
          <manage ref="manage" @dataUpdated="getData(1)" class=" mr-5" />
        </div>
        <v-container>
          <v-row>
              <v-col cols="12" md="12" class="text-right mt-2">
                  <h5>Showing : {{this.showingFrom? this.showingFrom : "0"}} - {{this.showingTo? this.showingTo: "0"}} of {{this.count? this.count : "0"}}</h5>
              </v-col>
          </v-row>
        </v-container>

        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Subject</th>
                <th class="text-left">Message</th>
                <th class="text-left">Expire Date</th>
                <th style="min-width: 120px;" class="text-left">Created At</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-left"></th>
                <th class="text-left ">
                  <v-text-field
                    outlined
                    class="my-auto"
                    style="height:40px"
                    dense
                    v-model="filters.subject"
                    @keypress.enter="search"
                  ></v-text-field>
                </th>
                <td></td>
                <th class="text-left ">
                  <v-text-field
                      outlined
                      class="my-auto"
                      style="height:40px"
                      dense
                      v-model="filters.expire_date"
                      @keypress.enter="search"
                  ></v-text-field>
                </th>
                <th></th>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="search"
                  >
                    <v-icon dark> mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="clearFilter"
                    v-if="isFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="(item) in users" :key="item.id">
                <td>{{item.id}}</td>
                <td class="text-left">{{ item.subject }}</td>
                <td class="text-left" v-html="item.message"></td>
                <td class="text-left" v-html="item.expire_date"></td>
                <td class="text-left" v-html="item.created_at"></td>
                <!-- <th class="text-left" dangerouslySetInnerHTML= {{ __html: item.message }}></th> -->
                <td>
                  <v-btn text :to="`/notification/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <v-btn text @click="edit(item.id)" color="blue" v-if="roleId === 0 || roleId === 1">
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="roleId === 0"
                    text
                    @click="(statusConfirm = true), (deletingId = item.id)"
                    color="red"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row justify="center" style="margin: 0;">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div>
      <v-dialog v-model="statusConfirm" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">
            Are you sure want to continue this action?
          </v-card-title>
          <v-card-text
            >If you confirm this action it can't be undo!</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="statusConfirm = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-1" text @click="destroy(statusId)">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
import Manage from "@/views/notifications/Manage";
export default {
  name: "roles",
  components: { VueContentLoading, Manage },
  data() {
    return {
      roleId: parseInt( JSON.parse(localStorage.getItem("user")).role ),
      editId: null,
      deletingId: null,
      formShow: false,
      message: {},
      users: {},
      statusConfirm: false,
      filters: {},
      statusId: null,
      loading: false,
      isActive: true,
      pagination: {
        current: 1,
        total: 0,
      },
      showingFrom: 0,
      showingTo: 0,
      count: 0
    };
  },
  methods: {
    getData(page = 1, searchQuery = "") {
      if (!page) {
        page = this.pagination.current;
      }
      this.loading = true;
      if (searchQuery) {
        var searchQuery = `&${searchQuery}`;
      }
      Api.get(`/notifications?page=${page}${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
              this.showingTo = res.data.to;
              this.count = res.data.total;
          this.users = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.pagination.per_page = res.data.per_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);}else{
            this.$toastr.e(err.response.data.status)
          }
          // this.loading = false;
        });

      this.editId = null;
      this.deletingId = null;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
    },

    destroy() {
      Api.delete(`/notifications/${this.deletingId}`)
        .then((res) => {
          this.getData();
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });
      this.statusConfirm = false;
      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    search() {
      this.getData(1, this.filtersUrl());
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getData(1);
  },
  watch: {
    "filters.subject"() {
      this.search();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
