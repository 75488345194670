<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-btn
            color="primary"
            @click="dialog = true"
            class="ml-8"
            v-bind="attrs"
            v-on="on"
            >import vehicle weight
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom:1px solid #eeee">
          <span class="headline">Upload Vehicle Weight</span>
          <v-btn
            class="ml-auto"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <div style="font-weight:bold;color:green">IMPORT CSV</div>
            <br />
            <input @input="fileHandle" ref="file" type="file" />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="cancel()">
            Close
          </v-btn> -->
          <v-btn color="blue darken-1" text @click="store()">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
export default {
  props: ["show"],
  data: () => ({
    dialog: false,
    modal: false,
    svg: null,
  }),
  methods: {
    cancelsvg() {
      this.dialog = false;
      this.$emit("cancelForm");
    },
    fileHandle(e) {
     
       this.svg = this.$refs.file.files[0];
    },
    store() {
      let formData = new FormData();
      formData.append("file", this.svg);
      Api.post("/vehicle-weights/import ", formData)
        .then((res) => {
          this.svg= null
        })
        .catch((err) => console.log(err));
      
      this.dialog = false
    },
  },
};
</script>
