<template>
  <div>
   
    <div class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex pb-4">
          <h1 class="primetime ">User Profile</h1>
          <div class="ml-auto mr-4" cols="12" md="2">
            <!-- <manage @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/> -->
          </div>
        </div>
        <v-row>
        <v-col sm="12" md="12" lg="12">
          <v-row class="mt-5" style="justify-content: center;">
            <v-col cols="12" md="4">
              <v-card max-width="100%" class="pa-4">
                <img
                  class="d-block mx-auto my-4"
                  style="border-radius:50%;height: 150px;width:150px"
                  :src="userInfo.photo"
                  alt="User"
                />
                <!-- <v-file-input
                          label="Change Profile"
                          outlined
                          dense
                          v-model="profileImg"
                          class="mx-auto"
                          style="width:15rem"
                      ></v-file-input> -->
                <v-file-input
                  outlined
                  dense
                  label="Profile Picture"
                  @change="photoHandle"
                  v-model="pic"
                ></v-file-input>
                <v-btn
                  color="primary"
                  elevation="2"
                  @click="profileUpdate"
                  class="ml-auto mr-8 d-block mb-5"
                  >Save</v-btn
                >
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card max-width="100%" style="height:100%" class="pa-4">
                <table style="border: none !important;">
                  <tr>
                    <th class="text-left" style="width:100px">Username</th>
                    <td class="text-left" style="width:200px">
                      : {{ userInfo.username }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left" style="width:100px">Email</th>
                    <td class="text-left" style="width:300px">
                      : {{ userInfo.email }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left" style="width:100px">Role</th>
                    <td class="text-left" style="width:200px">
                      : {{ userInfo.role }}
                    </td>
                  </tr>
                </table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-container>

      <modal
        name="vehicle"
        :min-width="1100"
        :max-height="1000"
        :adaptive="true"
        class="pb-5"
      >
      <v-card-title>
          
          <v-btn class="ml-auto" color="blue darken-1" text @click="$modal.hide('vehicle')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      <v-container>
        <v-simple-table  v-if="vehicleData">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Image</th>
                <th class="text-left">Year</th>
                <th class="text-left">Make</th>
                <th class="text-left">Model</th>
                <th class="text-left">Color</th>
                <th class="text-left">Vin</th>
                <th class="text-left">Lot Number</th>
                <th class="text-left">Customer Name</th>
                <th class="text-left">View vehicles</th>
                <th class="text-left">Invoices</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in vehicleData" :key="index">
                <td>
                  <v-img
                      :src="item.photo"
                      v-if="item.photo"
                      max-height="35"
                      max-width="50"
                  ></v-img>
                </td>
                <td>{{ item.year }}</td>
                <td>{{ item.make }}</td>
                <td>{{ item.model }}</td>
                <td>{{ item.color }}</td>
                 <td>
                <router-link
                  style="text-decoration:none"
                  :to="`/vehicles/${item.id}`"
                >
                  {{ item.vin }}
                </router-link>
              </td>
              <td>
                <manage-details 
                :lot='true' :text='item.lot_number'  :id="item.id"/>
              </td>
                <td>{{ item.customer_name }}</td>
                <td>
                  <router-link
                    class="dashboard-link"
                    :to="`vehicles/${item.id}`"
                  >
                    vehicle
                  </router-link>
                </td>
                <td>
                  <div v-for="(invoice, i) in item.invoice_photos" :key="i">
                    <v-btn small class="ma-1" @click="invoiceUrl(invoice.url)">{{
                        invoice.label
                      }}</v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-container>
      </modal>
    </div>
  </div>
</template>
<script>
import Api from "@/services/Api";
import localforage from "localforage";
export default {
  data: () => ({
    userInfo: {},
    previewImage: null,
    form: {},
    photo: null,
    pic: null,
  }),
  methods: {
    getData() {
      Api.get("/auth/me")
        .then((res) => {
          this.userInfo = res.data.user;
           localforage.setItem("photo", res.data.user.photo);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          }else{
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    invoiceUrl(e) {
      window.open(e, "_blank");
    },

    profileUpdate() {
      let formData = new FormData();
      formData.append("photo", this.photo);
      Api.post("/settings/profile", formData)
        .then((res) => {
          this.getData();
        })
        .catch((err) => console.log(err));
      this.pic = null;
    },

    photoHandle(e) {
      this.photo = e;
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style scoped>
.profile-label {
  font-weight: bold;
  font-size: 18px;
}
</style>