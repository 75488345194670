<template>
    <v-row class="mt-1 float-right">

        <v-dialog v-model="dialog" scrollable max-width="60%" persistent>
            <template v-slot:activator="{ on, attrs }">
                <div v-if="roleId !== 3"></div>
                <v-btn
                        v-else
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                >
                    New Feedback
                </v-btn>
            </template>

            <v-card>
                <v-card-title style="border-bottom:1px solid #eeee">
          <span class="headline"
          >{{ editing ? "Edit" : "New" }} Feedback</span
          >
                    <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-textarea
                                    dense
                                    outlined
                                    label="Feedback"
                                    class=""
                                    v-model="form.message"
                                    :disabled="editing"
                                    required
                                    :error-messages="errors.message"
                            ></v-textarea>
                        </v-row>
                        <v-row v-if="editing">
                            <v-textarea
                                    dense
                                    outlined
                                    label="Admin Reply"
                                    class=""
                                    v-model="form.note"
                                    required
                                    :error-messages="errors.note"
                            ></v-textarea>
                        </v-row>

                    </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
<!--                    <v-btn
                            color="blue darken-1"
                            text
                            @click="cancel()"
                    >
                        Close
                    </v-btn>-->
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="editing ? update() : store()"
                    >
                        {{ editing ? 'Reply' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import Api from "@/services/Api";

    export default {
        props: ['id', 'show'],
        name: 'ManageFeedback',
        data() {
            return {
                roleId: parseInt(JSON.parse(localStorage.getItem("user")).role),
                permissions: JSON.parse(localStorage.getItem('permissions')),
                dataId: this.id,
                editing: !!this.id,
                errors: {},
                dialog: this.show,
                loading: false,
                form: {
                    message: '',
                    status: true,
                }
            }
        },
        methods: {
            store() {
                this.loading = true;
                this.errors = {};
                Api.post(`/feedbacks`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        message: '',
                        note: '',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to create data! ' + err);
                })
            },
            async update() {
                this.loading = true;
                this.errors = {};
                await Api.put(`/feedbacks/${this.id}`, this.form).then(res => {
                    this.dialog = false;
                    this.loading = false;
                    this.form = {
                        message: '',
                        note: '',
                        status: true,
                    };
                    this.$toastr.s(res.data.message);
                    this.$emit("dataUpdated");
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }

                    this.$toastr.e('Failed to update data! ' + err);
                })

                this.editing = false;
            },
            edit() {
                if (!this.dataId) {
                    return;
                }
                this.form = {};
                this.loading = true;
                this.errors = {};
                Api.get(`/feedbacks/${this.dataId}`).then(res => {
                    this.form = res.data.data;
                    this.dialog = true;
                    this.loading = false;
                }).catch(err => {
                    if (err.response.status === 422) {
                        this.errors = err.response.data.errors;
                    } else {
                        this.dialog = false;
                        this.loading = false;
                    }
                    if (err.response.status !== 401) {
                        this.$toastr.e('Failed to load data! ' + err);
                    }
                })
            },
            cancel() {
                this.dataId = false;
                this.editing = false;
                this.dialog = false;
                this.$emit("cancelForm");
            }
        },
        watch: {
            id: function (newVal, oldVal) {
                if (newVal || oldVal) {
                    this.editing = true;
                    this.dataId = newVal;
                    this.edit();
                } else {
                    this.dataId = false;
                    this.editing = false;
                }
            },
            show: function (newVal, oldVal) {
                this.dialog = newVal;
            }
        }
    }
</script>