<template>
  <div>
    <!--    <div-->
    <!--      v-if="permissions['countries.index'] == false"-->
    <!--      style="height: 50vh"-->
    <!--      class="d-flex align-center justify-center"-->
    <!--    >-->
    <!--      <h1 class="display-2 text-center">-->
    <!--        You are not allowed to view this page-->
    <!--      </h1>-->
    <!--    </div>-->
    <div class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex">
          <h1 class="primetime">Audit Logs</h1>
        </div>
        <v-row>
          <v-col cols="12" md="12" class="text-right mt-2">
            <h5>
              Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
              {{ this.showingTo ? this.showingTo : "0" }} of
              {{ this.count ? this.count : "0" }}
            </h5>
          </v-col>
        </v-row>
        <v-simple-table class="mt-4" v-if="audits">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">User ID</th>
                <th class="text-left">Title</th>
                <th class="text-left">Event</th>
                <th class="text-left">Created At</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <v-text-field
                    @keypress.enter="searchFilter"
                    v-model="filters.user_id"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="searchFilter"
                    v-model="filters.title"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="eventsFilter"
                    v-model="filters.type"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(item, index) in audits" :key="item.id">
                <td>{{ index + 1 + (pagination.current - 1) * 20 }}</td>
                <td>{{ item.user_id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <v-btn text :to="`/audits/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <vue-content-loading :width="300" :height="100" v-else>
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import Api from "@/services/Api";
import ManageFeedback from "@/views/feedback/Manage.vue";
import { VueContentLoading } from "vue-content-loading";

export default {
  name: "audits",
  components: {
    Confirmation,
    ManageFeedback,
    VueContentLoading,
  },
  data() {
    return {
      roleId: null,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      audits: {},
      filters: {
        type: "",
        user_id: "",
        title: "",
      },
      pagination: {
        current: 1,
        total: 0,
      },
      statusFilter: [
        { id: 0, text: "Inactive" },
        { id: 1, text: "Active" },
      ],
      eventsFilter: [
        { id: "", title: "" },
        { id: "create", title: "Create" },
        { id: "update", title: "Update" },
        { id: "delete", title: "Delete" },
      ],
      filtering: false,
      loading: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      let current_page = page;
      if (!current_page) {
        current_page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/activity-logs?${finalSearchQuery}`)
        .then((res) => {
          this.audits = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
            this.loading = false;
          }
        });
    },
    getDataWithFilter(finalSearchQuery) {
      Api.get(`/activity-logs?${finalSearchQuery}`)
        .then((res) => {
          this.audits = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
            this.loading = false;
          }
        });
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    searchFilter() {
      this.getData(1, this.filtersUrl());
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  mounted() {
    this.roleId = parseInt(JSON.parse(localStorage.getItem("user")).role);

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
  watch: {
    "filters.type"() {
      this.searchFilter();
    },
  },
};
</script>

<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>
