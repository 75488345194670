<template>
  <div class="vehicle home">
       <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/feedbacks" color="grey" text>Complain</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
      </v-container>
          <v-container   class="white mt-5 pl-6 d-flex justify-space-between align-center">
          <h3 class="primetime">Complain Details</h3>
      </v-container>
    <vue-content-loading :width="300" :height="100" v-if="loading">
      <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
    </vue-content-loading>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" >
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Customer
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{complain.customer_name}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Subject
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{complain.subject}}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Complain Message
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
               {{complain.message}}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Created At
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
               {{complain.created_at}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Conversation
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                <main>
                  <ul id="chat" v-if="complain.conversations">
                    <li v-for="(conversation, index) in complain.conversations" :key="conversation.id" :class="conversation.class">
                      <div class="entete" v-if="conversation.class === 'you'">
                        <span class="[conversation.class === 'you' ? 'green' : 'blue', status] "></span>
                        <h2>{{ conversation.username }}</h2> &nbsp;
                        <h3>{{ conversation.created_at }}</h3>
                      </div>
                      <div class="entete" v-else >
                        <span class="[conversation.class === 'you' ? 'green' : 'blue', status] "></span>
                        <h3>{{ conversation.created_at }}</h3>&nbsp;
                        <h2>{{ conversation.username }}</h2>
                      </div>
                      <div class="triangle"></div>
                      <div class="message">
                        {{ conversation.message }}
                      </div>
                    </li>
                  </ul>
                  <footer>
                    <textarea v-model="form.message" placeholder="Type your message"></textarea>
                    <button id="sendMessage" :disabled="sending_msg" @click="store_conversation()">Send</button>
                  </footer>
                </main>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/settings/locations/Breadcrumbs.vue";
import Api from "@/services/Api";
import { VueContentLoading } from 'vue-content-loading';

export default {
    name: "Complain",
    components: {
      Breadcrumbs,
      VueContentLoading,
    },
    data() {
        return {
            complain: {},
            loading: false,
            sending_msg: false,
            form: {
              message: '',
            },
            errors:{}
        };
    },
    methods: {
       getData(){
          this.errors = {};
          this.loading = true;
          Api.get(`/complains/${this.$route.params.id}`).then(res => {
              this.complain = res.data.data;
              this.loading = false;

              if(this.complain.total_unread_complain ) {
                localStorage.setItem("complain_count", this.complain.total_unread_complain);
              }

          }).catch(err => {
            this.loading = false;
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data! ' + err);
            }
          })
       },
      store_conversation() {
         if(this.form.message.trim() === '') {
           return;
         }
        this.sending_msg = true;
        Api.post(`/complains/store-conversation`, {
          model_id: this.$route.params.id,
          message: this.form.message.trim()
        }).then(res => {
          this.form.message = '';
          this.getData();
          this.sending_msg = false;
        }).catch(err => {
          this.sending_msg = false;
          this.$toastr.e('Failed to create data! '+ err);
        })
      },
    },
    mounted() {
     this.getData()
    }
};
</script>

<style>
main{
  display:inline-block;
  font-size:15px;
  vertical-align:top;
  background-color:#EFF3F7;
}
.status{
  width:8px;
  height:8px;
  border-radius:50%;
  display:inline-block;
  margin-right:7px;
}
.green{
  background-color:#58b666;
}
.orange{
  background-color:#ff725d;
}
.blue{
  background-color:#6fbced;
  margin-right:0;
  margin-left:7px;
}

#chat{
  padding-left:0;
  margin:0;
  width: 600px;
  list-style-type:none;
  overflow-y:scroll;
  max-height:400px;
  border-top:2px solid #fff;
  border-bottom:2px solid #fff;
}
#chat li{
  padding:10px 30px;
}
#chat h2,#chat h3{
  display:inline-block;
  font-size:13px;
  font-weight:normal;
}
#chat h3{
  color:#bbb;
}
#chat .entete{
  margin-bottom:5px;
}
#chat .message{
  padding:20px;
  color:#fff;
  line-height:25px;
  max-width:90%;
  display:inline-block;
  text-align:left;
  border-radius:5px;
}
#chat .me{
  text-align:right;
}
#chat .you .message{
  background-color:#58b666;
}
#chat .me .message{
  background-color:#6fbced;
}
#chat .triangle{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}
#chat .you .triangle{
  border-color: transparent transparent #58b666 transparent;
  margin-left:15px;
}
#chat .me .triangle{
  border-color: transparent transparent #6fbced transparent;
  margin-left:505px;
}

main footer{
  height:155px;
  padding:20px 30px 10px 20px;
}
main footer textarea{
  resize:none;
  border:none;
  background: white;
  display:block;
  width:100%;
  height:80px;
  border-radius:3px;
  padding:20px;
  font-size:13px;
  margin-bottom:13px;
}
main footer textarea::placeholder{
  color:#ddd;
}
main footer img{
  height:30px;
  cursor:pointer;
}
main footer #sendMessage{
  text-decoration:none;
  text-transform:uppercase;
  font-weight:bold;
  color:#6fbced;
  vertical-align:top;
  float: right;
  margin-top:5px;
  display:inline-block;
}
</style>