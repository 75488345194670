<template>
  <div class="vehicle home">
    <breadcrumbs />
          <v-container   class="white mt-5 pl-6 d-flex justify-space-between align-center">
          <h3 class="primetime">Location Details</h3>
      </v-container>
      <v-container  class="white mt-1 pt-5 px-5">
        <v-row >
          <v-col cols="12" sm="12" md="6" > 
            <v-row
              no-gutters
              
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
               {{vehicle.name}}
              </v-col>
            </v-row><v-row
              no-gutters
              
              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
               Status
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
               <div v-if="vehicle.status===1">active</div>
               <div v-if="vehicle.status===0">Inactive</div>
              </v-col>
            </v-row>
            
          </v-col>
          
         
        </v-row>
      </v-container>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/settings/locations/Breadcrumbs.vue";
 import Api from "@/services/Api";

export default {
    name: "Vehicle",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            vehicle: {},
            loading: false,
            errors:{}
        };
    },
    methods: {
       getData(){
                this.errors = {};
                Api.get(`/settings/locations/${this.$route.params.id}`).then(res => {
                    this.vehicle = res.data.data;
                }).catch(err => {
                  if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data! ' + err);
                  }else{
                    this.$toastr.e(err.response.data.status)
                  }
                })
       }
    },
    mounted() {
     this.getData()
    }
};
</script>