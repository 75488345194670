import Roles from '@/views/acl/roles/Roles';
import Role from '@/views/acl/roles/Role';
import UsersRole from '@/views/acl/UsersRole/users';
import UserRole from '@/views/acl/UsersRole/RoleUser';
import User from '@/views/acl/UsersRole/user';
export default [
    {
        path: '/acl/roles',
        name: 'roles',
        component: Roles,
        meta: {
            title: "Roles",
            requiresAuth: true
        }
    },
    {
        path: "/acl/roles/:id",
        name: "role",
        component: Role,
        meta: {
            title: "Role Details",
            requiresAuth: true
        }
    },
    {
        path: "/acl/users",
        name: "user_roles",
        component: UsersRole,
        meta: {
            title: "Users Details",
            requiresAuth: true
        }
    },
    {
        path: "/users/:id",
        name: "user_role.detail",
        component: User,
        meta: {
            title: "User Details",
            requiresAuth: true
        }
    },
    {
        path: "/acl/users/:id",
        name: "Users Details",
        component: UserRole,
        meta: {
            title: "Users Details",
            requiresAuth: true
        }
    },

]