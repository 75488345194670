<template>
  <div class="vehicle home">
    <v-container>
      <div class="d-flex grey--text">
        <v-btn small to="/" color="grey" text>Home</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small to="/notifications" color="grey" text>Notification</v-btn
        ><v-btn text small disabled class="mx-n7">/</v-btn>
        <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
    </v-container>
    <v-container
      class="white mt-5 pl-6 d-flex justify-space-between align-center"
    >
      <h3 class="primetime">Notification Details</h3>

      <manage
        v-if="roleId === 0 || roleId === 1"
        :detailspage="true"
        ref="manage"
        @dataUpdated="getData()"
        :show="formShow"
        @cancelForm="formShow = null"
        :id="$route.params.id"
      />
      <v-btn
        v-if="roleId === 0 || roleId === 1"
        class="text-capitalize white--text mt-2 ml-6 py-5"
        small
        color="#2AAA79"
        elevation="0"
        @click="edit($route.params.id)"
        >Update
      </v-btn>
    </v-container>
    <v-container class="white mt-1 pt-5 px-5">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="2" class="pa-3 font-weight-bold">
              Subject
            </v-col>
            <v-col cols="12" sm="10" class="pa-3 second-column">
              {{ details.subject }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="2" class="pa-3 font-weight-bold">
              Created At
            </v-col>
            <v-col cols="12" sm="10" class="pa-3 second-column">
              {{ details.created_at }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="2" class="pa-3 font-weight-bold">
              Expire Date
            </v-col>
            <v-col cols="12" sm="10" class="pa-3 second-column">
              {{ details.expire_date }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Message
            </v-col>
          </v-row>
          <v-col
            cols="12"
            sm="12"
            class="message-box pa-3 second-column mb-5"
            v-html="details.message"
          ></v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Api from "@/services/Api";
import Manage from "@/views/notifications/Manage";

export default {
  components: { Manage },
  data: () => ({
    roleId: parseInt(JSON.parse(localStorage.getItem("user")).role),
    details: {},
    editId: null,
    formShow: false,
  }),
  methods: {
    getData() {
      Api.get(`/notifications/${this.$route.params.id}`)
        .then((res) => {
          this.details = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
    },
  },
  created() {
    this.getData();
  },
  watch: {
    $route(to, from) {
      Api.get(`/notifications/${this.$route.params.id}`)
        .then((res) => {
          this.details = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$$toastr.e(err.response.data.status);
          }
        });
    },
  },
};
</script>
<style scoped>
.message-box {
  border: 1px solid lightgray;
  min-height: 30vh;
}
</style>
