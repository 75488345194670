<template>
  <div class="vehicle home">
       <v-container>
      <div class="d-flex grey--text">
          <v-btn small to="/" color="grey" text>Home</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small to="/activity-logs" color="grey" text>Audit Logs</v-btn><v-btn text small disabled class="mx-n7">/</v-btn>
          <v-btn small color="grey" text>{{ $route.params.id }}</v-btn>
      </div>
  </v-container>
      <v-container   class="white mt-5 pl-6 d-flex justify-space-between align-center">
      <h3 class="primetime">Audit Log</h3>
      </v-container>
      <v-container  class="white mt-1 pt-5 px-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" >
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                User ID
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{audit.user_id}}
              </v-col>
            </v-row>
            <v-row
              no-gutters

              align="center"
              class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Title
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
               {{audit.title}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Event
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{audit.type}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Platform
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{audit.platform}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
                Created At
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                {{audit.created_at}}
              </v-col>
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Changed Log
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                <pre>{{ audit.logs }}</pre>
              </v-col>
            </v-row>

            <v-row
                no-gutters
                align="center"
                class="table-wrapper"
            >
              <v-col cols="12" sm="3" class="pa-3 font-weight-bold">
               Request Data
              </v-col>
              <v-col cols="12" sm="9" class="pa-3 second-column">
                <pre>{{ audit.request_data }}</pre>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/settings/locations/Breadcrumbs.vue";
 import Api from "@/services/Api";

export default {
    name: "Audit",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            audit: {},
            loading: false,
            errors:{}
        };
    },
    methods: {
       getData(){
          this.errors = {};
          Api.get(`/activity-logs/${this.$route.params.id}`).then(res => {
              this.audit = res.data.data;
          }).catch(err => {
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data! ' + err);
            }
          })
       }
    },
    mounted() {
     this.getData()
    }
};
</script>

<style>
pre {
  background: #eee;
  font-family: monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
</style>