<template>
  <v-row class="mt-1">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!lot"
          class="text-capitalize white--text mt-1 mr-2 ml-auto py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="getData()"
          v-bind="attrs"
          v-on="on"
          >{{ text }}
        </v-btn>
        <button
          v-else
          class="lot-number"
          @click="getData()"
          v-bind="attrs"
          v-on="on"
        >
          {{ text }}
        </button>
      </template>
      <v-card>
        <div class=" pa-4 d-flex">
          <v-btn color="primary mr-3" @click="generateReport">Print</v-btn>
          <v-btn
            class="mr-3"
            @click="emailConfirm = true"
            v-if="isCustomer"
            color="primary"
            >Email</v-btn
          >
          <v-btn color="primary" @click="pdfLink" :loading="downloading">Open As Pdf</v-btn>
          <v-btn
            class="ml-auto"
            style="float:right"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div>
            <v-dialog v-model="emailConfirm" persistent max-width="500">
              <v-card>
                <v-card-title class="headline">
                  Are you sure want to continue this action?
                </v-card-title>
                <v-card-text
                  >If you confirm this action it can't be undo!</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="emailConfirm = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="green darken-1" text @click="emailLink()">
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-card-title class="py-0" style="border-bottom:1px solid #eeee">
        </v-card-title>

        <v-card-text>
          <div v-html="reports" id="printme"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import downloadPdf from "@/helpers/downloadPdf";


export default {
  props: ["id", "show", "text", "lot"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dialog: this.show,
      editing: false,
      isCustomer: null,
      // reports:{},
      reports: null,
      errors: {},
      baseUrl: "",
      emailConfirm: false,
      downloading: false,
    };
  },
  methods: {
    cancel() {
      this.editing = false;
      this.dialog = false;
    },
    getData() {
      // this.loading = true;
      Api.get(`/vehicles/${this.id}/condition-report-modal`)
        .then((res) => {
          this.reports = res.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);}
        });
    },

    loadingManage (value) {
      this.downloading = value;
    },

    pdfLink() {
      window.open(
          process.env.VUE_APP_API_ENDPOINT +
          "/api/v1/vehicles/" +
          this.id +
          "/condition-report-pdf",
          "_blank"
      );
      // this.loadingManage(true);
      // const url = process.env.VUE_APP_API_ENDPOINT +"/api/v1/vehicles/" + this.id + "/condition-report-pdf";
      // downloadPdf(url, this.loadingManage);

    },
    emailLink() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          "/api/v1/vehicles/" +
          this.id +
          "/condition-report-pdf?email=1",
        "_blank"
      );
      this.emailConfirm = false;
    },
    generateReport() {
      var prtContent = document.getElementById("printme");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      // WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();;
    },
  },
  created() {
    // this.getData()
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    if (roleId === 3) {
      this.isCustomer = false;
    } else {
      this.isCustomer = true;
    }
  },
  watch: {},
  mounted() {
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
};
</script>
<style scoped>
.lot-number,
.lot-number:hover {
  color: #1976d2 !important;
  background-color: transparent !important;
  margin-top: -16px;
  outline: none;
}
</style>
