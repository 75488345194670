<template>
  <v-row class="mt-1">
    <v-dialog scrollable v-model="dialog" max-width="900px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="permissions['consignees.store'] == false"></div>
        <div class="" v-else>
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="emptyForm"
            v-if="!detailspage"
          >
            New Consignee
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} Consignee</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" md="6">
                <!--<v-autocomplete-->
                <!--dense-->
                <!--outlined-->
                <!--v-model="form.customer_user_id"-->
                <!--:items="customersItem"-->
                <!--item-text="customer_name"-->
                <!--item-value="user_id"-->
                <!--hide-no-data-->
                <!--label="Customer Name"-->
                <!--autocomplete="none"-->
                <!--:error-messages="errors.customer_user_id"-->
                <!--:search-input.sync="customarUserId"-->
                <!--:loading="loading2"-->
                <!--@change="changeCustomer"-->
                <!--&gt;</v-autocomplete>-->

                <v-autocomplete
                  dense
                  outlined
                  v-model="form.customer_user_id"
                  :items="customersItem"
                  label="Customer Name"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customarUserId"
                  :loading="loading2"
                  @change="changeCustomer"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- <v-select
                                        :items="customersItem" 
                                        item-text="customer_name"
                                        item-value="user_id"
                                        @change="changeCustomer"
                                        
                                        v-model="form.customer_user_id" 
                                        outlined dense
                                        label="Customer Name" 
                                        :error-messages="errors.customer_user_id">

                                </v-select> -->

                <v-text-field
                  v-model="form.consignee_name"
                  outlined
                  dense
                  label="Consignee Name"
                  :error-messages="errors.consignee_name"
                ></v-text-field>
                <v-text-field
                  v-model="form.consignee_address_1"
                  outlined
                  dense
                  label="Consignee address 1"
                  :error-messages="errors.consignee_address_1"
                ></v-text-field>

                <v-select
                  item-text="name"
                  item-value="id"
                  @change="changeCountry"
                  :items="countryItem"
                  v-model="form.country_id"
                  outlined
                  dense
                  label="Country"
                  :error-messages="errors.country_id"
                ></v-select>

                <v-select
                  item-text="name"
                  item-value="id"
                  :items="cityItem"
                  v-model="form.city_id"
                  outlined
                  dense
                  label="City"
                  :error-messages="errors.city_id"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  item-text="consignee_name"
                  item-value="id"
                  :items="cosigneesItem"
                  v-model="consignee_search"
                  outlined
                  dense
                  label="Search Consignee"
                  :error-messages="errors.consignee_search"
                ></v-select>

                <v-text-field
                  v-model="form.phone"
                  outlined
                  dense
                  label="phone"
                  :error-messages="errors.phone"
                ></v-text-field>

                <v-text-field
                  v-model="form.consignee_address_2"
                  outlined
                  dense
                  label="Consignee address 2"
                  :error-messages="errors.consignee_address_2"
                ></v-text-field>

                <v-select
                  item-text="name"
                  item-value="id"
                  @change="changeState"
                  :items="stateItem"
                  v-model="form.state_id"
                  outlined
                  dense
                  label="State"
                  :error-messages="errors.state_id"
                ></v-select>

                <v-text-field
                  v-model="form.zip_code"
                  outlined
                  dense
                  label="Zip code"
                  :error-messages="errors.zip_code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="white"
            text
            class="primary"
            :disabled="loading"
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";

export default {
  props: ["id", "show", "detailspage"],
  name: "Manage",
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      dataId: this.id,
      editing: false,
      errors: {},
      dialog: this.show,
      loading: false,
      loading2: false,
      form: {},
      customersItem: [],
      countryItem: [],
      stateItem: [],
      cityItem: [],
      cosigneesItem: [],
      consignee_search: "",
      customarUserId: "",
    };
  },
  methods: {
    emptyForm() {
      this.customersItem = [];
      this.cosigneesItem = [];
      this.form = {
        consignee_name: "",
        customer_user_id: "",
        consignee_address_1: "",
        consignee_address_2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
        phone: "",
      };
    },
    store() {
      this.loading = true;
      this.errors = {};
      Api.post(`/consignees`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.$toastr.s(res.data.message);
          this.emptyForm();
          this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
          }

          this.loading = false;
          this.$toastr.e(
            "Failed to create data! One or more required field missing. "
          );
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/consignees/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.$toastr.s(res.data.message);
          this.emptyForm();
          this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e("Failed to update data! One or more required field missing.");
          } else {
            this.$toastr.e( err.response?.data?.message ?? "Failed to update data! One or more required field missing.");
          }
          this.loading = false;
        });

      this.editing = false;
    },
    edit(id) {
      if (!id) {
        return;
      }
      this.editing = true;
      this.emptyForm();
      this.loading = true;
      this.errors = {};

      Api.get(`/consignees/${id}`)
        .then((res) => {
          this.form = res.data.data;

          this.customerName(this.form.customer_user_id);

          if (this.form.country_id) {
            this.changeCountry(this.form.country_id);
          }

          if (this.form.state_id) {
            this.changeState(this.form.state_id);
          }

          this.getConsigneeItem();

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },

    customerName(id) {
      Api.get(`/customers-item?user_id=${id}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },

    getCountryItem() {
      this.loading = true;
      Api.get(`/settings/countries?status=1&limit=-1`)
        .then((res) => {
          this.countryItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    changeCountry(id) {
      this.cityItem = [];
      this.loading = true;
      Api.get(`/settings/states?status=1&limit=-1&country_id=${id}`)
        .then((res) => {
          this.stateItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    changeState(id) {
      this.loading = true;
      Api.get(`/settings/cities?status=1&limit=-1&state_id=${id}`)
        .then((res) => {
          this.cityItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },

    getConsigneeItem() {
      this.loading = true;
      let query = "";
      if (this.form.customer_user_id) {
        query = "?customer_user_id=" + this.form.customer_user_id;
      }
      Api.get(`/consignee-search${query}`)
        .then((res) => {
          this.cosigneesItem = res.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    changeCustomer() {
      this.getConsigneeItem();
    },
    cusotmarAutoCompleteHandelar(newVal) {
      this.loading2 = true;
      Api.get(`/customers-item?customer_name=${newVal}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.loading2 = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  created() {
    this.emptyForm();
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal || oldVal) {
        this.dataId = newVal;

        this.edit(newVal);
      } else {
        this.dataId = false;
        this.editing = false;
      }
    },
    customarUserId: function (newVal) {
      //   if (newVal) {
      //     this.loading2 = true;
      //     Api.get(`/customers-item?customer_name=${newVal}`)
      //         .then((res) => {
      //           this.customersItem = res.data.data;
      //           this.loading2 = false;
      //         })
      //         .catch((err) => {
      //           if (err.response.status !== 401) {
      //             this.$toastr.e("Failed to load data!" + err);
      //             this.loading = false;
      //           }
      //         });
      //   }

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
    show: function (newVal) {
      this.dialog = newVal;
    },
  },
  mounted() {
    // this.getCustomerItem();
    this.getCountryItem();
    this.getConsigneeItem();
  },
};
</script>
