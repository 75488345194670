<template>
  <v-row class="">
    <v-dialog v-model="dialog" scrollable max-width="65%">
      <template v-slot:activator="{ on, attrs }">
        <v-img
          :src="text"
          v-on="on"
          v-bind="attrs"
          @click="getData()"
          max-height="35"
          max-width="50"
        ></v-img>
        
      </template>
      <v-card>
        <div class="pa-4 d-flex">
          <span class="headline">Vehicle Image</span>
          <v-btn
            class="ml-auto"
            style="float: right"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0" style="border-bottom: 1px solid #eeee">
        </v-card-title>

        <v-card-text>
          <div v-if="lot == 0">
            <h2>No Image Available</h2>
          </div>
          <div v-else>
            <v-tabs align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> USA YARD PHOTOS </v-tab>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> AUCTION PHOTOS </v-tab>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> PICKUP PHOTOS </v-tab>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> ARRIVED PHOTOS </v-tab>
              <v-tab-item>
                <div class="vehicle" style="margin-top: 18px">
                  <v-container
                          class="
                      white
                      mt-5
                      pl-6
                      d-flex
                      justify-space-between
                      flex-column flex-sm-row
                    "
                  >
                    <h3 class="primetime text-uppercase">USA YARD PHOTOS</h3>
                  </v-container>
                  <v-container class="white mt-1 pt-5 px-10">
                    <v-row>
                      <div class="images" v-viewer>
                        <img v-for="{thumbnail, url } in vehicles.photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                      </div>
                      <!-- component -->
                      <viewer :images="vehicles.photos">
                        <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                      </viewer>

                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="vehicle" style="margin-top: 20px">
                  <v-container
                    class="
                      white
                      mt-5
                      pl-6
                      d-flex
                      justify-space-between
                      flex-column flex-sm-row
                    "
                  >
                    <h3 class="primetime text-uppercase">Auction Photos</h3>
                  </v-container>
                  <v-container class="white mt-1 pt-5 px-10">

                    <v-row>
                      <div class="images" v-viewer>
                        <img v-for="{thumbnail, url } in vehicles.auction_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                      </div>
                      <!-- component -->
                      <viewer :images="vehicles.auction_photos">
                        <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                      </viewer>

                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="vehicle" style="margin-top: 20px">
                  <v-container
                    class="
                      white
                      mt-5
                      pl-6
                      d-flex
                      justify-space-between
                      flex-column flex-sm-row
                    "
                  >
                    <h3 class="primetime text-uppercase">Pickup Photos</h3>
                  </v-container>

                  <v-container class="white mt-1 pt-5 px-10">

                    <v-row>
                      <div class="images" v-viewer>
                        <img v-for="{thumbnail, url } in vehicles.pickup_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                      </div>
                      <!-- component -->
                      <viewer :images="vehicles.pickup_photos">
                        <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                      </viewer>

                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="vehicle" style="margin-top: 20px">
                  <v-container
                    class="
                      white
                      mt-5
                      pl-6
                      d-flex
                      justify-space-between
                      flex-column flex-sm-row
                    "
                  >
                    <h3 class="primetime text-uppercase">Arrived Photos</h3>
                  </v-container>

                  <v-container class="white mt-1 pt-5 px-10">

                    <v-row>
                      <div class="images" v-viewer>
                        <img v-for="{thumbnail, url } in vehicles.arrived_photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                      </div>
                      <!-- component -->
                      <viewer :images="vehicles.arrived_photos">
                        <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                      </viewer>

                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: ["id", "show", "text", "lot"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dialog: this.show,
      editing: false,
      isCustomer: null,
      // reports:{},
      vehicles: {},
      errors: {},
      baseUrl: "",
      images: [],
      vehicleGallar: false,
      currentUrl: null,
      vehicleauctionGallar:false,
      vehicleArrivedGallar:false,
      emailConfirm: false,
      vehiclePicupGallar:false,
      
    };
  },
  methods: {
    cancel() {
      this.editing = false;
      this.dialog = false;
    },
    getData() {
      // this.loading = true;
      Api.get(`/vehicles/${this.id}`)
        .then((res) => {
          this.vehicles = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    pdfLink() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          "/api/v1/vehicles/" +
          this.id +
          "/condition-report-pdf",
        "_blank"
      );
    },
    emailLink() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          "/api/v1/vehicles/" +
          this.id +
          "/condition-report-pdf?email=1",
        "_blank"
      );
      this.emailConfirm = false;
    },
    generateReport() {
      var prtContent = document.getElementById("printme");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      // WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();;
    },
  },
  created() {
    // this.getData()
    var roleId = JSON.parse(localStorage.getItem("user")).role;
    if (roleId === 3) {
      this.isCustomer = false;
    } else {
      this.isCustomer = true;
    }
  },
  watch: {},
  mounted() {
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
};
</script>
<style scoped>
.lot-number,
.lot-number:hover {
  color: #1976d2 !important;
  background-color: transparent !important;
  margin-top: -16px;
  outline: none;
}
</style>
