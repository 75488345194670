<template>
  <v-container class="mt-10">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="7" class="pl-0">
        <!-- WRAPPER -->
        <v-card color="white px-5 py-5" elevation="0">
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-card-title>Order Overview</v-card-title>
                <v-list>
                  <v-list-item
                    class="my-n7 py-0 ss"
                    style="cursor: pointer"
                    v-if="status.status !== 0 && status.status !== ''"
                    v-for="status in report.status_overview"
                    @click="showVehiclesPage(status.status)"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon :color="status.color">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2">{{
                      status.label
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="chart-container mt-5">
                <Doughnut
                  ref="orderChart"
                  :height="200"
                  :width="200"
                  :chartData="firstChartData"
                  :getTotal="totalOrder"
                ></Doughnut>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- WRAPPER ENDS -->
      <v-col cols="12" sm="12" md="6" lg="5" class="pr-0" v-if="roleId !== 8">
        <!-- WRAPPER -->
        <v-card color="white px-5 py-5" elevation="0">
          <v-row>
            <!-- <div class="d-flex justify-space-between flex-column flex-md-row flex-sm-row"> -->
            <v-col cols="12" sm="6">
              <div>
                <v-card-title style="white-space: nowrap; overflow: hidden"
                  >Invoice Overview</v-card-title
                >
                <v-list>
                  <v-list-item
                    class="my-n5"
                    @click="newTab('/invoice/paid')"
                    style="cursur: pointer"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#34c38f">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2"
                      >Paid</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    class="my-n5"
                    @click="newTab('/invoice/partial_paid')"
                    style="cursur: pointer"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#6087e0">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2"
                      >Partially</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    class="my-n5"
                    @click="newTab('/invoice/unpaid')"
                    style="cursur: pointer"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#f46a69">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2"
                      >Unpaid</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-col cols="12" sm="6" style="height: 17rem">
              <div class="chart-container mt-5">
                <Doughnut
                  ref="invoiceChart"
                  :height="200"
                  :width="200"
                  :chartData="secondChartData"
                  :getTotal="totalInvoice"
                ></Doughnut>
              </div>
            </v-col>
            <!-- </div> -->
          </v-row>
        </v-card>
      </v-col>
      <!-- WRAPPER ENDS -->
    </v-row>
  </v-container>
</template>

<script>
import Doughnut from "@/components/charts/Doughnut.vue";
export default {
  components: { Doughnut },
  props: ["report", "location"],
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      totalOrder: 0,
      totalInvoice: 0,
      firstChartData: {
        hoverBackgroundColor: "red",
        radius: 1,
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: ["Order Overview"],
            backgroundColor: [],
            data: [],
          },
        ],
      },
      secondChartData: {
        hoverBackgroundColor: "red",
        radius: 1,
        hoverBorderWidth: 10,
        labels: ["Paid", "Partially", "Unpaid"],
        datasets: [
          {
            label: ["Invoice Overview"],
            backgroundColor: ["#34c38f", "#6087e0", "#f46a69"],
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    showVehiclesPage(status) {
      let routeData = this.$router.resolve({
        name: "Vehicles",
        query: {
          location: this.location,
          status: status,
        },
      });
      window.open(routeData.href, "_blank");
    },
    newTab(e) {
      window.open(`${e}`, "_blank");
    },
    generateChartData() {
      this.fillOrderChart();
      this.fillInvoiceChart();
    },
    fillOrderChart() {
      this.firstChartData.datasets[0].labels = [];
      this.firstChartData.datasets[0].data = [];
      this.firstChartData.datasets[0].backgroundColor = [];
      this.report.status_overview.map((item) => {
        if (item.status === 0) {
          this.totalOrder = item.total;
          return;
        }
        if (item.status === "") {
          return;
        }
        this.firstChartData.labels.push(item.label);
        this.firstChartData.datasets[0].data.push(item.total);
        this.firstChartData.datasets[0].backgroundColor.push(item.color);
        this.$refs["orderChart"].render();
        this.$refs["orderChart"].update();
      });
    },
    fillInvoiceChart() {
      this.secondChartData.datasets[0].data = [];
      this.secondChartData.datasets[0].data = [
        this.report.invoice_overview.paid && this.roleId !== null
          ? this.report.invoice_overview.paid
          : 0,
        this.report.invoice_overview.partially && this.roleId !== null
          ? this.report.invoice_overview.partially
          : 0,
        this.report.invoice_overview.unpaid && this.roleId !== null
          ? this.report.invoice_overview.unpaid
          : 0,
      ];
      this.totalInvoice =
        this.roleId !== null ? this.report.invoice_overview.total : 0;
      this.$refs["invoiceChart"].render();
      this.$refs["invoiceChart"].update();
    },
  },
  watch: {
    report: function () {
      this.generateChartData();
    },
  },
};
</script>

<style>
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}
.theme--light.v-list-item:focus::before {
  opacity: 0 !important;
}
.theme--light.v-list-item::before {
  opacity: 0 !important;
}
</style>
