<template>
  <v-row class="mt-1 float-right">
    <v-dialog scrollable v-model="dialog" max-width="85%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="newclaim" color="primary" dark v-bind="attrs" v-on="on"> New storage Claim </v-btn>
      </template>
      <v-card>
        <v-card-title style="border-bottom:1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} Claim</span>
            <v-btn class="ml-auto" color="blue darken-1" text @click="cancel(), editing = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  disabled
                  outlined
                  v-model="form.customer_user_id"
                  :items="customer"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customarUserId"
                  :error-messages="errors.customer_user_id"
                  label="CUSTOMER"
                  autocomplete="none"
                  placeholder="Customer"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  disabled
                  v-model="form.customer_user_id"
                  label="CUSTOMER ID"
                  outlined
                  dense
                  :error-messages="errors.customer_user_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                        disabled
                  v-model="form.lot_number"
                  label="LOT NUMBER"
                  outlined
                  dense
                  :error-messages="errors.lot_number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.claim_amount"
                  label="CLAIM AMOUNT"
                  outlined
                  dense
                  :error-messages="errors.claim_amount"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  rows="3"
                  cols="4"
                  name="input-7-4"
                  label="REMARKS / DETAILS"
                  v-model="form.remarks"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  rows="3"
                  cols="4"
                  name="input-7-4"
                  label="OTHER PARTS"
                  v-model="form.other_parts"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="damaged_parts"
                  v-model="form.vehicle_part"
                  outlined
                  dense
                  label="DAMAGED PARTS"
                  :error-messages="errors.vehicle_part"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="3" md="3">CUSTOMER IMAGES </v-col>
                  <v-col cols="12" md="12">
                    <vue-dropzone
                            ref="customer_documents"
                            id="dropzone_cutomer"
                            @vdropzone-removed-file="removeCustomerDocument"
                            @vdropzone-sending="sendingEvent"
                            @vdropzone-success="storeSuccessCustomerDocuments"
                            @vdropzone-error="storeError"
                            :options="dropzoneOptions_customer"></vue-dropzone>
                    <button @click="removeAllCustomerFiles">Remove Files</button>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.approved_amount"
                  label="APPROVED AMOUNT"
                  outlined
                  dense
                  :disabled="roleId === 3"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="statuses"
                  v-model="form.claim_status"
                  outlined
                  dense
                  label="STATUS"
                  :disabled="roleId === 3"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.approved_date"
                      label="APPROVED DATE"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      :disabled="roleId === 3"
                    ></v-text-field>
                  </template>
                  <v-date-picker style="height: 350px;" v-model="form.approved_date" @input="menu = false" type="date" :disabled="roleId === 3" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" v-if="roleId != 3">
                <v-textarea
                  outlined
                  rows="3"
                  cols="4"
                  name="input-7-4"
                  label="ADMIN REMARKS"
                  v-model="form.admin_remarks"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" v-if="roleId != 3">
                <v-row>
                  <v-col cols="3" md="3">ADMIN IMAGES </v-col>
                  <v-col cols="12" md="12">
                    <vue-dropzone
                            ref="admin_documents"
                            id="dropzone_admin"
                            @vdropzone-removed-file="removeAdminDocument"
                            @vdropzone-sending="sendingEvent"
                            @vdropzone-success="storeSuccessAdminDocuments"
                            @vdropzone-error="storeError"
                            :options="dropzoneOptions_admin"></vue-dropzone>
                    <button @click="removeAllAdminFiles">Remove Files</button>

                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()"> Close </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="loading"
            text
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const _token = "Bearer " + localStorage.getItem("authtoken");
export default {
  props: ["id", "show", "vehicleId"],
  name: "Manage",
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      items: [],
      date: "",
      menu: false,
      dataId: this.id,
      editing: false,
      errors: {},
      dialog: this.show,
      loading : false,
      form: {
        vehicle_id:0,
        upload_file: "",
        customer_user_id:"",
        lot_number:"",
        claim_amount:"",
        vehicle_part:"",
        remarks:"",
        approved_amount:"",
        approved_date:"",
        claim_status:"",
        location:"",
        other_parts:"",
        vin:"",
        admin_photos : [],
        customer_photos : [],

      },
      customer:[],

      statuses: [
        { title: "Approved", id: 1 },
        { title: "Rejected", id: 0 },
        { title: "Pending", id: 10 },
      ],
      pricing_types: [
        { title: "Select status", id: "" },
        { title: "Towing Price", id: 1 },
        { title: "Ocean Freight Price", id: 2 },
        { title: "Bike towing", id: 3 },
      ],
      damaged_parts:[
        {id:"0", title:" "},
        {id:"1",title:'Hood'},
        {id:"2",title:'Head Lights'},
        {id:"3",title:'Front Bumper'},
        {id:"4",title:'Rear Bumper'},
        {id:"5",title:'Fender'},
        {id:"6",title:'Roof'},
        {id:"7",title:'Doors'},
        {id:"8",title:'Door handle'},
        {id:"9",title:'Bonnet'},
        {id:"10",title:'Trunk'},
        {id:"11",title:'Gas tank '},
        {id:"12",title:'Windshield'},
        {id:"13",title:'Rim'},
        {id:"14",title:'Tyre'},
        {id:"15",title:'Exhaust'},
        {id:"16",title:'Indicator'},
        {id:"17",title:'Tail light'},
        {id:"18",title:'Rear window'},
        {id:"19",title:'Grill'},
        {id:"20",title:'Keys'},
      ],
      dropzoneOptions_customer: {
        url: Api.defaults.baseURL + '/claims/photos-upload',
        thumbnailWidth: 150,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: '.jpg,.jpeg,.png',
        maxFiles: 10,
        headers: { "Authorization": _token },
      },
      dropzoneOptions_admin: {
        url: Api.defaults.baseURL + '/claims/photos-upload',
        thumbnailWidth: 150,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: '.jpg,.jpeg,.png',
        maxFiles: 10,
        headers: { "Authorization": _token },
      },
      customarUserId: "",
      roleId : JSON.parse(localStorage.getItem("user")).role,
      vehicleData : {},
    };
  },
  mounted() {
    if(this.vehicleId) {
      Api.get(`/vehicles/${this.vehicleId}`)
              .then((res) => {
                this.form.customer_user_id = res.data.data.customer_user_id;
                this.form.lot_number = res.data.data.lot_number;
                this.form.vehicle_id = this.vehicleId;
                this.customerName(res.data.data.customer_user_id);
                this.loading = false;
              })
              .catch((err) => {
                this.$toastr.e("Failed to load data!" + err);
                this.loading = false;
              });
    }
  },
  methods: {
    checkId(){
       if(this.$route.params.id){
         Api.get(`/vehicles/${this.$route.params.id}`)
        .then((res) => {
          this.form = res.data.data;
          this.form.vehicle_id = res.data.data.id;
           Api.get(`customers-item?user_id=${this.form.customer_user_id}`)
           .then((res) => {
             this.customer = res.data.data;
          });
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
      }
    },
    removeAllFiles() {
      this.form.upload_file = "";
      this.$refs.vueDropzone ? this.$refs.vueDropzone.removeAllFiles() : false;
    },

    removeAllAdminFiles() {
      if( this.$refs.admin_documents ) {
        this.$refs.admin_documents.removeAllFiles();
      }
    },
    removeAllCustomerFiles() {
      if( this.$refs.customer_documents ) {
        this.$refs.customer_documents.removeAllFiles();
      }
    },

    removeAdminDocument(file, error, xhr) {
      let index = this.form.admin_photos ? this.form.admin_photos.findIndex(
              (x) => x.id === file.id
      ) : -1;
      this.form.admin_photos.splice(index, 1);
    },

    removeCustomerDocument(file, error, xhr) {
      let index = this.form.customer_photos ? this.form.customer_photos.findIndex(
              (x) => x.id === file.id
      ) : -1;
      this.form.customer_photos.splice(index, 1);
    },

    sendingEvent(file, xhr, formData) {
        this.loading = true;
     },

    storeSuccess(file, response) {
      this.loading = false;
      this.form.upload_file = response.data;
    },

    storeSuccessAdminDocuments(file, response) {
      this.loading = false;
      this.form.admin_photos.push(response.data);
    },

    storeSuccessCustomerDocuments(file, response) {
      this.loading = false;
      this.form.customer_photos.push(response.data);
    },

    storeError(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    emptyForm() {
      this.form = {
        vehicle_id:0,
        upload_file: "",
        customer_user_id:"",
        lot_number:"",
        claim_amount:"",
        vehicle_part:"",
        remarks:"",
        approved_amount:"",
        approved_date:"",
        claim_status:"",
        location:"",
        other_parts:"",
        vin:"",
        admin_photos : [],
        customer_photos : [],
      };
    },
    store() {
      this.loading = true;
      this.errors = {};

      Api.post(`/storage-claims`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.$toastr.e(
            "Failed to create data! One or more required field missing. "
          );
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/storage-claims/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }

          this.$toastr.e(
            "Failed to update data! One or more required field missing. "
          );
        });

      this.editing = false;
    },
    edit() {
      this.removeAllAdminFiles();
      this.removeAllCustomerFiles();
      this.emptyForm();
      if (!this.dataId) {
        return;
      }
      this.loading = true;
      this.editing = true;
      this.errors = {};
      Api.get(`/storage-claims/${this.dataId}`)
        .then((res) => {
          this.form = res.data.data;
          this.form.customer_user_id = res.data.data.vehicle.customer_user_id;
          this.form.lot_number = res.data.data.vehicle.lot_number;
          this.customerName(res.data.data.vehicle.customer_user_id);

          if(this.roleId != 3) {
            this.form.admin_photos.map((value, index, array) => {
              let file = {  id: value.id, size: value.size, name: value.name, type: value.type };
              this.$refs.admin_documents.manuallyAddFile(file, value.url);
            });
          }

          this.form.customer_photos.map((value, index, array) => {
            let file = { id: value.id, size: value.size, name: value.name, type: value.type };
            this.$refs.customer_documents.manuallyAddFile(file, value.url);
          });

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response ? err.response.status === 422 : false) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
        if (err.response ? err.response.status !== 401 : true) {
          this.$toastr.e("Failed to load data! " + err);}
        });
    },
    customerName(id) {
      Api.get(`/customers-item?user_id=${id}`)
              .then((res) => {
                this.customer = res.data.data;
                this.loading = false;
              })
              .catch((err) => {
                if (err.response.status !== 401) {
                  this.$toastr.e("Failed to load data!" + err);
                  this.loading = false;
                }
              });
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },
    saveMonth(el) {
    },
    cusotmarAutoCompleteHandelar(e) {
      Api.get(`/customers-item?customer_name=${e}`)
              .then((res) => {
                this.items = res.data.data;
                this.customer = res.data.data;
              })
              .catch((err) => {
                if (err.response.status === 422) {
                  this.errors = err.response.data.errors;
                } else {
                  this.dialog = false;
                  this.loading = false;
                }
                if (err.response.status !== 401) {
                  this.$toastr.e("Failed to create data! " + err);
                }
              });
    },
  },
  created() {
    this.emptyForm();
    this.checkId();

    if(this.id) {
      this.dataId = this.id;
      this.edit();
    }
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal || oldVal) {
        this.dataId = newVal;
        this.edit();
        this.editing = true;
      } else {
        this.dataId = false;
        this.editing = false;
      }
    },
    show: function (newVal, oldVal) {
      this.dialog = newVal;
    },

    customarUserId: function (newVal) {
      if (newVal) {
        this.cusotmarAutoCompleteHandelar(newVal);
      }
    },
  },
};
</script>
<style >
.w div input {
  border: 1px solid black;
  height: 37px;
  border-radius: 3px;
  padding: 0 16px;
}
.month-pickers {
  position: relative;
}
.month-picker__text {
  position: absolute;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  top: 6px;
  left: 19px;
}
</style>