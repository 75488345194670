<template>
  <div class="vehicle mt-5">
    <v-container
      class="white mt-5 pl-6 d-flex justify-space-between align-center"
    >
      <h3 class="primetime">Short Export Information</h3>
    </v-container>
    <v-container class="white mt-1 pt-5 px-5">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Status
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.status_name }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Export Date
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.export_date }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> ETA </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.eta }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Booking Number
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.booking_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Loading Date
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.loading_date }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Seal Number
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.seal_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Container Number
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.container_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Destination
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.destination }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              AR number
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.ar_number }}
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="table-wrapper">
            <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
              Manifest Date
            </v-col>
            <v-col cols="12" sm="6" class="pa-3 second-column">
              {{ details.manifest_date }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["details"],
  data() {
    return {
      exportInfo: [
        {
          name: "Status",
          value: "Arrived",
        },
        {
          name: "Export Date",
          value: "2020-12-02",
        },
        {
          name: "ETA",
          value: "2020-12-12",
        },
        {
          name: "Booking Number",
          value: "YHU682039",
        },
      ],
      exportInfo2: [
        {
          name: "Seal Number",
          value: "7699473",
        },
        {
          name: "Container Number",
          value: "YMLU9541988",
        },
        {
          name: "Destination",
          value: "Jabel Ali",
        },
        {
          name: "AR number",
          value: "G111020NEJOUM531",
        },
      ],
    };
  },
};
</script>

<style scoped>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
</style>
